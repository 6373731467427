import React from 'react';
import PropTypes from 'prop-types';
import { Builders } from 'q3-ui-forms';
import { getSafelyForAutoCompleteWithProjection } from 'q3-ui-rest';
import AddNewForm from 'q3-admin/lib/components/AddNewForm';
import useRole from 'q3-admin/lib/hooks/useRole';
import { COACH } from '../useUserRoleOptions/useUserRoleOptions';

const CoursesAdd = (props) => {
  const isCoach = useRole().is(COACH);

  return (
    <AddNewForm
      {...props}
      initialValues={
        isCoach
          ? {
              template: '000000000000000000000000',
            }
          : {}
      }
      marshal={{
        template: 'template',
        title: 'title',
      }}
    >
      <Builders.Field
        name="title"
        type="text"
        required
        xl={12}
        lg={12}
      />
      {!isCoach && (
        <Builders.Field
          name="template"
          type="select"
          loadOptions={getSafelyForAutoCompleteWithProjection(
            '/templates?__t=course&limit=500&sort=name',
            'templates',
            'name',
          )}
          preload
          required
          xl={12}
          lg={12}
        />
      )}
    </AddNewForm>
  );
};

CoursesAdd.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CoursesAdd;
