import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { object } from 'q3-ui-helpers';
import { IconButton, Tooltip } from '@material-ui/core';
import { useTranslation } from 'q3-ui-locale';
import RedoIcon from '@material-ui/icons/Redo';
import LinkIcon from '@material-ui/icons/Link';

const CoursesUsersReinvite = ({
  course,
  email,
  id,
  parent,
}) => {
  const { t } = useTranslation('labels');

  return (
    <>
      <Tooltip title={t('reinvite')}>
        <IconButton
          color="inherit"
          onClick={() =>
            object.noop(
              axios.post('/reinvite', {
                course,
                id,
                parent,
              }),
            )
          }
        >
          <RedoIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('link')}>
        <IconButton
          component="a"
          color="inherit"
          href={`/open-registration?course=${course}&email=${email}`}
          target="_blank"
        >
          <LinkIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

CoursesUsersReinvite.propTypes = {
  course: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default CoursesUsersReinvite;
