import React from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse, Grid } from '@material-ui/core';
import { Builders } from 'q3-ui-forms';
import { filter, get, reduce, size } from 'lodash';
import useJournalCourse from '../useJournalCourse';
import useJournalCourseOptions from '../useJournalCourseOptions';
import useStyle from '../ArticlesReadOnly/styles';

const JournalsCourseSelect = ({
  showPreview,
  selectDefaultPrompt,
}) => {
  const { load: loadCourses } = useJournalCourse();
  const {
    body,
    group,
    groups = [],
    load,
  } = useJournalCourseOptions(selectDefaultPrompt);

  const cls = useStyle();

  return (
    <>
      <Builders.Field
        name="course"
        preload
        type="select"
        loadOptions={loadCourses}
        chooseOptionLabel="chooseYourCourse"
        xl={12}
        lg={12}
      />
      {groups?.length > 0 && (
        <Builders.Field
          required
          name="promptCategory"
          type="select"
          options={groups}
          chooseOptionLabel="chooseYourCategory"
          xl={12}
          lg={12}
        />
      )}
      <Builders.Field
        loadOptions={load}
        name="promptId"
        preload
        runOnChange={['course', 'promptCategory']}
        chooseOptionLabel="chooseYourPrompt"
        type="select"
        xl={12}
        lg={12}
      />
      {showPreview && (
        <Grid item xs={12}>
          <Collapse in={size(body)}>
            <Box
              px={1}
              className={cls.root}
              width="100%"
              dangerouslySetInnerHTML={{
                __html: body,
              }}
            />
          </Collapse>
        </Grid>
      )}
    </>
  );
};

JournalsCourseSelect.defaultProps = {
  showPreview: true,
  selectDefaultPrompt: true,
};

JournalsCourseSelect.propTypes = {
  showPreview: PropTypes.bool,
  selectDefaultPrompt: PropTypes.bool,
};

export default JournalsCourseSelect;
