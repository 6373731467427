import React from 'react';
import { Box, Hidden } from '@material-ui/core';
// eslint-disable-next-line
import Filters from 'q3-ui-filters';
import { size } from 'lodash';
import ButtonWithIconDialog from 'q3-admin/lib/components/ButtonWithIconDialog';
import FilterListIcon from '@material-ui/icons/FilterList';
import ToolbarPortal from 'q3-admin/lib/components/ToolbarPortal';
import useActiveSearchQuery from 'q3-admin/lib/hooks/useActiveSearchQuery';

const ReportsFilter = () => {
  const len = size(Object.keys(useActiveSearchQuery()));
  const id = 'report-filters';

  const Content = (
    <Filters
      collectionName="journals"
      data={{
        'course.title': {
          type: 'String',
          ref: 'journals',
        },
        'createdBy': {
          alias: 'users',
          field: 'name',
          projection: 'firstName,lastName,name',
          type: 'ObjectId',
          ref: 'users',
          label: 'user',
        },
        'publishedOn': {
          type: 'Date',
          useHasParam: true,
        },
      }}
    />
  );

  const Dialog = (
    <ButtonWithIconDialog
      DialogProps={{
        closeOnSearchChange: true,
      }}
      autoOpen
      count={len}
      id={id}
      icon={FilterListIcon}
      label="filter"
      renderContent={() => Content}
      transparent
    />
  );

  return (
    <Box ml={1}>
      <ToolbarPortal id="appbar-filter">
        {Dialog}
      </ToolbarPortal>
      <Hidden mdDown>{Dialog}</Hidden>
    </Box>
  );
};

export default ReportsFilter;
