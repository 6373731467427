import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'q3-ui-dialog';
import { Button } from '@material-ui/core';
import { useTranslation } from 'q3-ui-locale';
import { Builders } from 'q3-ui-forms';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import axios from 'axios';
import { chunk, join, size, trim, uniq } from 'lodash';

const CourseInviteBulk = ({ course, parent, poll }) => {
  const { t } = useTranslation('helpers');

  const read = (attachments) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (!attachments || !attachments.import) {
        resolve([]);
        return;
      }

      reader.onload = (e) => {
        try {
          resolve(e.target.result.split('\n').map(trim));
        } catch (err) {
          err.message = t('helpers:cannotParseCsv');
          reject(err);
        }
      };

      reader.readAsText(attachments.import);
    });

  const upload = async (data) =>
    axios
      .post('/invite', {
        course,
        email: join(data, ','),
        parent,
      })
      .then(
        () =>
          // give them API a break
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, 500);
          }),
      );

  return (
    <Builders.Form
      onSubmit={async (values, attachments) => {
        const emails = uniq(
          []
            .concat(values.email)
            .concat(await read(attachments))
            .filter(size),
        );

        if (size(emails) > 250) {
          const e = new Error();
          e.message = t('helpers:cannotInviteMoreThan');
          return Promise.reject(e);
        }

        await chunk(emails, 49).reduce(
          async (previous, current) => {
            await previous;
            return upload(current);
          },
          Promise.resolve(),
        );

        await poll();

        return {
          message: t('descriptions:invitationsSent'),
        };
      }}
      showSuccessMessage
      restart
    >
      <Builders.Field
        helper={t('pressEnter')}
        name="email"
        type="multitext"
        of="email"
        xl={12}
        lg={12}
      />
      <Builders.Field
        accept="text/csv"
        helper={t('bulkInvite')}
        name="import"
        type="file"
        xl={12}
        lg={12}
      />
    </Builders.Form>
  );
};

CourseInviteBulk.defaultProps = {};

CourseInviteBulk.propTypes = {
  course: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
  poll: PropTypes.func.isRequired,
};

const CoursesParticipantsInvite = (props) => {
  const { t } = useTranslation('labels');

  return (
    <Dialog
      renderContent={() => <CourseInviteBulk {...props} />}
      renderTrigger={(onClick) => (
        <Button
          onClick={onClick}
          startIcon={<AlternateEmailIcon />}
        >
          {t('invite')}
        </Button>
      )}
      title="invite"
      variant="drawer"
    />
  );
};

export default CoursesParticipantsInvite;
