import React from 'react';
import { useMatch } from '@reach/router';
import { Button, Hidden } from '@material-ui/core';
import { useTranslation } from 'q3-ui-locale';
import AddIcon from '@material-ui/icons/Add';
import Dialog from 'q3-ui-dialog';
import { isNil } from 'lodash';
import JournalsAdd from '../JournalsAdd';
import useStyle from './styles';
import { createNewJournal } from '../utils';

const ForcedJournalButton = () => {
  const { t } = useTranslation('labels');
  const matched = useMatch('/app/notifications');
  const cls = useStyle();

  return isNil(matched) ? null : (
    <Dialog
      renderContent={() => (
        <JournalsAdd onSubmit={createNewJournal} />
      )}
      renderTrigger={(onClick) => (
        <Hidden mdDown>
          <Button
            className={cls.button}
            color="secondary"
            onClick={onClick}
            startIcon={<AddIcon />}
            variant="contained"
          >
            {t('newJournal')}
          </Button>
        </Hidden>
      )}
      variant="drawer"
      title="journal"
    />
  );
};

export default ForcedJournalButton;
