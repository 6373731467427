import React from 'react';
import PropTypes from 'prop-types';
import { Builders } from 'q3-ui-forms';
import { connect } from 'q3-admin/lib/containers';
import {
  castToUTC,
  castToLocalDate,
} from 'q3-ui-forms/lib/helpers';
import { useTranslation } from 'q3-ui-locale';
import ProfileCountryOfResidenceDropdown from '../ProfileCountryOfResidenceDropdown';
import useUserRoleOptions from '../useUserRoleOptions';

const UsersGeneral = ({ data, onSubmit, ...props }) => {
  const { t } = useTranslation('labels');
  const getOriginalRoleOptions = useUserRoleOptions();

  return (
    <Builders.Form
      {...props}
      initialValues={data}
      keep={[
        'firstName',
        'lastName',
        'email',
        'countryOfResidence',
        'birthday',
        'tel',
        'occupation',
        'originalRole',
        'allocation',
        'region',
      ]}
      onSubmit={onSubmit}
      marshalSelectively
      marshal={{
        birthday: [castToUTC],
        role: [
          // keeps them in lockstep
          (_, { originalRole }) => originalRole,
        ],
      }}
      modify={{
        birthday: [castToLocalDate],
      }}
    >
      <Builders.Field
        name="firstName"
        type="text"
        required
      />
      <Builders.Field
        name="lastName"
        type="text"
        required
      />
      <Builders.Field name="email" type="email" required />
      <Builders.Field name="tel" type="tel" />
      <Builders.Field name="birthday" type="date" />
      <ProfileCountryOfResidenceDropdown />
      <Builders.Field name="occupation" type="text" />
      <Builders.Field
        name="originalRole"
        type="select"
        options={getOriginalRoleOptions()}
        required
        label={t('role')}
      />
      <Builders.Field
        name="allocation"
        type="number"
        conditional={['originalRole=Coach']}
        min="0"
      />
    </Builders.Form>
  );
};

UsersGeneral.propTypes = {
  data: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default connect(UsersGeneral);
