import React from 'react';
import { Builders } from 'q3-ui-forms';
import AddNewForm from 'q3-admin/lib/components/AddNewForm';

const ArticlesAdd = (props) => (
  <AddNewForm {...props}>
    <Builders.Field
      name="title"
      type="text"
      required
      xl={12}
      lg={12}
    />
  </AddNewForm>
);

ArticlesAdd.propTypes = {};

export default ArticlesAdd;
