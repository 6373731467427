import React from 'react';
import { Context } from 'q3-ui-forms';
import { compact, find, size, isObject } from 'lodash';
import { getSafelyForAutoCompleteWithProjection } from 'q3-ui-rest';

const useJournalCourse = () => {
  const ref = React.useRef();
  const [options, setOptions] = React.useState();
  const { values } = React.useContext(Context.BuilderState);
  const currentCourse = values?.course;

  const dispatch = React.useContext(
    Context.DispatcherState,
  );

  const setFields = (args) =>
    Object.entries(args).forEach(([key, value]) => {
      dispatch.setFieldValue(key, value);
    });

  const clearFields = () =>
    setFields({
      course: null,
      courseTitle: null,
    });

  React.useLayoutEffect(() => {
    const len = size(options);

    if (!len) return;

    if (
      // must only do this once
      !ref.current &&
      !currentCourse &&
      len === 1 &&
      isObject(options[0])
    ) {
      const { label, value } = options[0];

      setFields({
        course: value,
        courseTitle: label,
      });
    } else if (currentCourse) {
      const match = find(
        options,
        ({ value }) => value === currentCourse,
      );

      if (match)
        setFields({
          course: match.value,
          courseTitle: match.label,
        });
      else clearFields();
    } else clearFields();

    // set init as done
    ref.current = true;
  }, [currentCourse, options]);

  return {
    load() {
      return getSafelyForAutoCompleteWithProjection(
        '/courses?published=exists(true)&func=string(present)&trashedOn=has(false)&suspended=exists(false)&fields=title',
        'courses',
        'title',
      )().then((resp) => {
        setOptions(resp);
        return compact(
          [
            {
              label: 'No course',
              value: '',
            },
          ].concat(resp),
        );
      });
    },
  };
};

export default useJournalCourse;
