import React from 'react';
import { Builders } from 'q3-ui-forms';
import AddNewForm from 'q3-admin/lib/components/AddNewForm';

const AttributesAdd = (props) => (
  <AddNewForm
    {...props}
    initialValues={{
      name: '',
      sort: 0,
    }}
  >
    <Builders.Field
      name="name"
      type="text"
      required
      xl={12}
      lg={12}
    />
    <Builders.Field
      name="sort"
      type="number"
      xl={12}
      lg={12}
    />
  </AddNewForm>
);

AttributesAdd.propTypes = {};

export default AttributesAdd;
