import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';

export const ReadOnlyTemplateAccordionNested = ({
  children,
  left,
  right,
}) => (
  <Accordion elevation={0} style={{ marginTop: 0 }}>
    <AccordionSummary>
      <Grid container justifyContent="space-between">
        <Grid item>
          <strong>{left}</strong>
        </Grid>
        <Grid item style={{ fontSize: 'small' }}>
          {right}
        </Grid>
      </Grid>
    </AccordionSummary>
    <AccordionDetails style={{ margin: 0, padding: 0 }}>
      <Box bgcolor="background.default" p={2} width="100%">
        {children}
      </Box>
    </AccordionDetails>
  </Accordion>
);

ReadOnlyTemplateAccordionNested.defaultProps = {
  children: null,
  left: null,
  right: null,
};

ReadOnlyTemplateAccordionNested.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.array,
  ]),
  left: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.array,
  ]),
  right: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.array,
  ]),
};

export default ReadOnlyTemplateAccordionNested;
