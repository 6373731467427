import React from 'react';
import SystemPageSub from 'q3-admin/lib/components/SystemPageSub';
import { Builders } from 'q3-ui-forms';
import { useTranslation } from 'q3-ui-locale';
import useProfileChange from '../useProfileChange';

const ProfileChangeRoles = () => {
  const { t } = useTranslation('helpers');
  const { change, roles } = useProfileChange();

  return (
    <SystemPageSub title="accountRoleType">
      <Builders.Form onSubmit={({ role }) => change(role)}>
        <Builders.Field
          name="role"
          type="select"
          options={roles}
          helperText={t('changeRoleType')}
          xl={12}
          lg={12}
        />
      </Builders.Form>
    </SystemPageSub>
  );
};

export default ProfileChangeRoles;
