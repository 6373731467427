import React from 'react';
import Filter from 'q3-admin/lib/components/Filter';

const JournalsFilter = () => (
  <Filter
    data={{
      'course.title': {
        type: 'String',
        ref: 'journals',
      },
      'prompt': {
        type: 'String',
        ref: 'journals',
      },
      'updatedAt': {
        type: 'Date',
        ref: 'journals',
      },
      'createdAt': {
        type: 'Date',
        ref: 'journals',
      },
      'publishedOn': {
        type: 'Boolean',
        useHasParam: true,
      },
      'publishedState': {
        type: 'String',
        enum: ['All Members', 'Coaches Only', 'Private'],
      },
      func: {
        label: 'Segments',
        type: 'String',
        enum: ['me', 'unread'],
      },
      trashedOn: {
        type: 'Boolean',
        useHasParam: true,
      },
    }}
  />
);

export default JournalsFilter;
