import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  bg: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    top: '-50%',
    left: '-50%',
    transform: 'scale(3) translate(-50%,-50%)',
    filter: 'blur(3px)',
  },
  header: {
    lineHeight: 1.3,
    color: 'inherit',
    paddingBottom: '1rem',

    '& small': {
      paddingLeft: theme.spacing(1),
    },
  },
}));
