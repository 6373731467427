import React from 'react';
import { Link } from '@reach/router';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import { Store } from 'q3-admin/lib/containers/state';
import { map, size } from 'lodash';
import { string } from 'q3-ui-helpers';
import Pagination from 'q3-admin/lib/containers/Pagination';
import withPageLoading from 'q3-admin/lib/helpers/withPageLoading';
import { useTranslation } from 'q3-ui-locale';
import useStyle from './styles';

export const ArticlesCollection = () => {
  const d = React.useContext(Store);
  const { t } = useTranslation('descriptions');
  const cls = useStyle();

  return (
    <Container
      className={cls.root}
      disableGutters
      maxWidth="xl"
    >
      <Grid container spacing={1}>
        {size(d?.data) ? (
          map(d.data, (item) => (
            <Fade key={item.id} in>
              <Grid item lg={3} md={4} xs={6}>
                <Card>
                  <CardActionArea
                    component={Link}
                    to={`/app/articles/${item.id}`}
                  >
                    <CardMedia
                      image={item.photo}
                      style={{
                        height: 150,
                      }}
                    />
                    <CardContent>
                      <Typography
                        variant="body2"
                        component="h3"
                        className={cls.title}
                      >
                        {item.title}
                      </Typography>
                      <Box component="small">
                        {string.toDate(item.updatedAt)}
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Fade>
          ))
        ) : (
          <Grid item>{t('descriptions:noArticles')}</Grid>
        )}
      </Grid>
      <Box mt={2}>
        <Pagination />
      </Box>
    </Container>
  );
};

export default withPageLoading(ArticlesCollection);
