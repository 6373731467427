import AbstractCollectionBuilder from 'q3-admin/lib/builders';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import Add from '../AttributesAdd';
import General from '../AttributesGeneral';
import Options from '../AttributesOptions';

export const resolvers = (xs) => ({
  ...xs,
  updatedAt: {
    base: xs.updatedAt,
    toDate: true,
  },
});

export default new AbstractCollectionBuilder({
  collectionName: 'attributes',
  resourceNameSingular: 'attribute',
  resourceName: 'attributes',
  parent: 'tools',
  icon: EmojiEmotionsIcon,
  resolvers,
})
  .genCreate(Add)
  .genHeader({
    titleProp: 'name',
  })
  .genViews({
    General,
    Options,
  })
  .genList({
    disableAvatar: true,
  })
  .genDetail({
    autoOpenSummary: false,
  })
  .genListSettings({
    defaultColumns: ['sort', 'updatedAt'],
    defaultSortPreference: 'sort',
    select: 'name,sort',
  })
  .build();
