import React from 'react';
import DashboardCourseTable from '../DashboardCourseTable';

const DashboardJournalStats = () => (
  <DashboardCourseTable
    report="averageJournalStats"
    title="journals"
  />
);

export default DashboardJournalStats;
