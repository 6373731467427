import React from 'react';
import { useTranslation } from 'q3-ui-locale';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import useJournalAutosave from '../useJournalAutosave';

const JournalActionsMessage = () => {
  const updatedTimeStamp = useJournalAutosave();
  const { t } = useTranslation('labels');

  return (
    <Typography component="small" variant="caption">
      {t('descriptions:journalAutosaveTimestamp', {
        date: updatedTimeStamp
          ? moment
              .parseZone(updatedTimeStamp)
              .format('MMMM Do YYYY, h:mm:ss a')
          : '--',
      })}
    </Typography>
  );
};

export default JournalActionsMessage;
