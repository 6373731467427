import React from 'react';
import PropTypes from 'prop-types';
import { sortBy, map, size, isNil } from 'lodash';
import { IconButton } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

export const withHomeworkSeqButton = (
  Icon,
  onSubmit,
  incremingValue,
) => {
  const SeqButton = ({
    onSubmit: update,
    row: initialValues,
  }) => {
    const fn = onSubmit
      ? onSubmit(initialValues.id)
      : update;

    return (
      <IconButton
        color="inherit"
        disabled={Boolean(
          (initialValues.noPrev && incremingValue < 0) ||
            (initialValues.noNext && incremingValue > 0),
        )}
        onClick={() =>
          fn({
            // name is required for patch
            // eslint-disable-next-line
            name: initialValues.name,
            seq: (initialValues.seq || 0) + incremingValue,
          }).catch(() => {
            // noop
          })
        }
      >
        <Icon />
      </IconButton>
    );
  };

  SeqButton.defaultProps = {
    onSubmit: null,
  };

  SeqButton.propTypes = {
    onSubmit: PropTypes.func,
    row: PropTypes.shape({
      id: PropTypes.string,
      noNext: PropTypes.bool,
      noPrev: PropTypes.bool,
      seq: PropTypes.number,
    }).isRequired,
  };

  return SeqButton;
};

export const makeHomeworkSeqButtonIncrementers = (fn) => [
  {
    component: withHomeworkSeqButton(
      ArrowUpwardIcon,
      fn,
      -1,
    ),
    disableDialog: true,
    label: 'up',
  },
  {
    component: withHomeworkSeqButton(
      ArrowDownwardIcon,
      fn,
      1,
    ),
    disableDialog: true,
    label: 'down',
  },
];

export const sort = (xs) =>
  sortBy(
    map(xs, (item, idx, array) => {
      const copy = { ...item };
      if (isNil(copy.seq)) copy.seq = idx + 1;
      copy.noNext = copy.seq === size(array);
      copy.noPrev = copy.seq === 1;
      return copy;
    }),
    'seq',
  );

export default withHomeworkSeqButton;
