import React from 'react';
import Dialog from 'q3-ui-dialog';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTranslation } from 'q3-ui-locale';
import { useAuth } from 'q3-ui-permissions';
import SecondarySearchList from '../SecondarySearchList';

const ImportHomeworkDialog = ({ onImport }) => {
  const { t } = useTranslation('labels');
  const { Hide } = useAuth('templates');

  return (
    <Hide op="Read">
      <Dialog
        renderContent={() => (
          <SecondarySearchList
            key="template"
            pluralized="templates"
            url="templates"
            query="?__t=homework"
            onClick={(_, item) => onImport(item)}
            disableAvatar
          />
        )}
        renderTrigger={(onClick) => (
          <Button
            onClick={onClick}
            startIcon={<GetAppIcon />}
          >
            {t('import')}
          </Button>
        )}
        title="import"
        variant="drawer"
      />
    </Hide>
  );
};

ImportHomeworkDialog.defaultProps = {};
ImportHomeworkDialog.propTypes = {
  onImport: PropTypes.func.isRequired,
};

export default ImportHomeworkDialog;
