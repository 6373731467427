import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'q3-admin';
import axios from 'axios';
import { Typography, Box } from '@material-ui/core';
import { Builders } from 'q3-ui-forms';
import { useTranslation } from 'q3-ui-locale';

const DomainsAnnouncement = ({ data }) => {
  const { t } = useTranslation('labels');

  return (
    <Box>
      <Typography>
        {t('descriptions:domainAnnouncements')}
      </Typography>
      <Builders.Form
        restart
        submitLabel="send"
        onSubmit={(values) =>
          axios.post('/announcement', {
            ...values,
            // eslint-disable-next-line
            tenant: data?.subdomain,
          })
        }
      >
        <Builders.Field
          name="announcement"
          type="text"
          multiline
          rows={3}
          xl={12}
          lg={12}
        />
      </Builders.Form>
    </Box>
  );
};

DomainsAnnouncement.defaultProps = {
  data: {},
};

DomainsAnnouncement.propTypes = {
  data: PropTypes.shape({
    tenant: PropTypes.string,
  }),
};

export default connect(DomainsAnnouncement);
