import React from 'react';
import { Builders } from 'q3-ui-forms';
import { castToBoolean } from 'q3-ui-forms/lib/helpers';
import { connect } from 'q3-admin/lib/containers';

const UsersPermissionSettings = ({
  data: initialValues,
  onSubmit,
  ...props
}) => (
  <Builders.Form
    {...props}
    initialValues={initialValues}
    onSubmit={onSubmit}
    keep={[
      'enableArticles',
      'enableAttributes',
      'enableCategories',
      'enableReports',
      'enableTemplates',
    ]}
    marshal={{
      enableArticles: [castToBoolean],
      enableAttributes: [castToBoolean],
      enableCategories: [castToBoolean],
      enableReports: [castToBoolean],
      enableTemplates: [castToBoolean],
    }}
  >
    <Builders.Field
      name="enableTemplates"
      type="checkbox"
      variant="switch"
    />
    <Builders.Field
      name="enableArticles"
      type="checkbox"
      variant="switch"
    />
    <Builders.Field
      name="enableAttributes"
      type="checkbox"
      variant="switch"
    />
    <Builders.Field
      name="enableCategories"
      type="checkbox"
      variant="switch"
    />
    <Builders.Field
      name="enableReports"
      type="checkbox"
      variant="switch"
    />
  </Builders.Form>
);

export default connect(UsersPermissionSettings);
