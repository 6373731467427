import React from 'react';
import { map, includes, isFunction, size } from 'lodash';
import PropTypes from 'prop-types';
import { object } from 'q3-ui-helpers';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box,
  Typography,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import useRest from 'q3-ui-rest';
import { useTranslation } from 'q3-ui-locale';
import withSearchInput from '../withSearchInput';

export const SecondarySearchList = ({
  initialValues,
  query,
  onClick,
  pluralized,
  search,
  searchElement,
  disableAvatar,
  disableSearch,
  makeListItemTextProps,
  ...rest
}) => {
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const { t } = useTranslation('descriptions');

  let defaultQuery = `page=${page}&limit=25`;
  if (search) defaultQuery += `&search=${search}`;

  const r = useRest({
    ...rest,
    key: pluralized,
    pluralized,
    runOnInit: true,
    location: {
      search: query
        ? `${query}&${defaultQuery}`
        : `?${defaultQuery}`,
    },
  });

  return (
    <Box>
      {disableSearch ? null : searchElement}
      <List>
        {map(r[pluralized], (item) => {
          const selected = includes(
            initialValues?.ids,
            item.id,
          );

          const handleClick = () => {
            setLoading(true);

            // regardless of the result
            object
              .noop(Promise.resolve(onClick(item.id, item)))
              .then(() => {
                setLoading(false);
              });
          };

          return (
            <ListItem
              button
              disabled={selected || loading}
              key={item.id}
              onClick={handleClick}
              component="li"
            >
              {!disableAvatar && (
                <ListItemAvatar>
                  <Avatar src={item.photo} />
                </ListItemAvatar>
              )}
              <ListItemText
                primary={item?.name}
                {...(isFunction(makeListItemTextProps)
                  ? makeListItemTextProps(item)
                  : {})}
              />
            </ListItem>
          );
        })}
      </List>
      {!r.fetching && (
        <Box>
          {size(r[pluralized]) ? (
            <Box textAlign="center" my={1}>
              <Pagination
                count={Math.ceil(r.total / 25)}
                onChange={(_, newvalue) =>
                  setPage(newvalue - 1)
                }
                page={page + 1}
              />
            </Box>
          ) : (
            <Typography>{t('nothingToDisplay')}</Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

SecondarySearchList.defaultProps = {
  initialValues: PropTypes.shape({}),
  makeListItemTextProps: null,
  search: '',
  searchElement: null,
  disableAvatar: false,
  disableSearch: false,
};

SecondarySearchList.propTypes = {
  initialValues: PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.string),
  }),
  query: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  pluralized: PropTypes.string.isRequired,
  search: PropTypes.string,
  searchElement: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]),
  disableAvatar: PropTypes.bool,
  disableSearch: PropTypes.bool,
  makeListItemTextProps: PropTypes.func,
};

export default withSearchInput(SecondarySearchList);
