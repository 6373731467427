import React from 'react';
import { Box, Typography } from '@material-ui/core';

export const TemplatesCourseHomeworkReadOnly = ({
  // eslint-disable-next-line
  initialValues: { name, body },
}) => (
  <Box>
    <Typography variant="h5" component="h3">
      {name}
    </Typography>
    <Box
      width="100%"
      dangerouslySetInnerHTML={{
        __html: body,
      }}
    />
  </Box>
);

export default TemplatesCourseHomeworkReadOnly;
