import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    '& h2': {
      fontSize: theme.typography.h5.fontSize,
      marginBottom: theme.spacing(0.5),
    },
    '& >  p:first-of-type, & >  h2:first-of-type': {
      marginTop: 0,
    },
    '& video, & img, & iframe': {
      display: 'block',
      width: 450,
      maxWidth: '100%',
    },

    '& iframe': {
      maxWidth: '100%',
      width: 650,
      height: 550,
      margin: '2rem 0 !important',
      display: 'inline-block',

      [theme.breakpoints.down('md')]: {
        height: 335,
      },
    },

    '& blockquote': {
      padding: '1rem 0 1rem 2rem',
      borderLeft: `1px solid ${theme.palette.primary.main}`,
    },

    '& hr': {
      borderColor: 'var(--background-muted)',
    },

    '& .blockmedia': {
      whiteSpace: 'normal !important',
    },

    '& video': {
      maxHeight: 550,
      // padding seems to get in the way
      maxWidth: 'calc(100% - 1.5rem)',
      display: 'inline-block',
    },

    '& audio': {
      display: 'inline-block',
    },

    '& img': {
      cursor: 'pointer',
      display: 'inline-block',
      padding: '.15rem',
      width: '50%',
      maxWidth: '100%',
      maxHeight: 550,
      objectFit: 'cover',

      [theme.breakpoints.up('md')]: {
        userSelect: 'none',
        '&::selection': {
          backgroundColor: 'transparent',
        },
      },
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
      },

      '&.ql-full': {
        width: '100%',
      },
      '&.ql-half': {},
      '&.ql-float': {
        float: 'left',
        margin: '0',
        padding: '1rem',
        width: '33%',
      },
    },
  },
}));
