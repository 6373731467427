import React from 'react';
import { Box, TextField } from '@material-ui/core';
import Title from 'q3-admin/lib/components/Title';
import {
  Store,
  Dispatcher,
} from 'q3-admin/lib/containers/state';
import { object } from 'q3-ui-helpers';
import { debounce } from 'lodash';
import useStyle from './styles';

const EditableTitle = (props) => {
  const { data } = React.useContext(Store);
  const { patch } = React.useContext(Dispatcher);
  const [isEditing, setIsEditing] = React.useState(false);

  const cls = useStyle();

  const placeholder = 'Untitled';
  const title = data?.title || placeholder;
  const initialValue = title !== placeholder ? title : '';

  const captureTextChange = debounce((e) => {
    object
      .noop(
        patch()({
          title: e.target.value || '',
        }),
      )
      .then(() => {
        setIsEditing(false);
      });
  }, 350);

  return (
    <Title {...props}>
      {isEditing ? (
        <TextField
          autoFocus
          defaultValue={initialValue}
          fullWidth
          inputProps={{
            className: cls.input,
          }}
          onBlur={captureTextChange}
          name="inline-title-editor"
          placeholder={placeholder}
        />
      ) : (
        <Box
          className={cls.text}
          component="span"
          display="inline-block"
          onClick={() => {
            setIsEditing(true);
          }}
          role="button"
        >
          {title}
        </Box>
      )}
    </Title>
  );
};

export default EditableTitle;
