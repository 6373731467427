import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
// eslint-disable-next-line
import axios from 'axios';
import { filter, last } from 'lodash';
import useStyle from '../ArticlesReadOnly/styles';

const JournalCourseInstructions = ({ course, prompt }) => {
  const [instructions, setInstructions] = React.useState();
  const cls = useStyle();
  const id = course?.ref;

  React.useEffect(
    () =>
      id && prompt
        ? axios
            .get(`/courses/${course?.ref}/homework`)
            .then(({ data: { homework = [] } }) => {
              setInstructions(
                last(
                  filter(
                    homework,
                    (h) => h.name === prompt,
                  ),
                )?.body,
              );
            })
            .catch(() => {
              // noop
            })
        : undefined,
    [id, prompt],
  );

  return (
    <Fade in={Boolean(instructions)}>
      <Box
        width="100%"
        className={cls.root}
        dangerouslySetInnerHTML={{
          __html: instructions,
        }}
      />
    </Fade>
  );
};

JournalCourseInstructions.defaultProps = {
  course: {},
  prompt: undefined,
};

JournalCourseInstructions.propTypes = {
  course: PropTypes.shape({
    ref: PropTypes.string,
  }),
  prompt: PropTypes.string,
};

export default JournalCourseInstructions;
