import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import JournalActionsPagination from '../JournalActionsPagination';

const JournalActionsConfirmation = () => {
  const ref = React.useRef();

  React.useLayoutEffect(() => {
    let timer;

    try {
      timer = setTimeout(() => {
        ref.current
          .querySelector('#publish-button')
          .click();
      }, 250);
    } catch (e) {
      // noop
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  return (
    <Box alignItems="center" display="flex" mb={2}>
      <CircularProgress />
      <div ref={ref} style={{ display: 'none' }}>
        <JournalActionsPagination
          backState={{}}
          nextState={{}}
        />
      </div>
    </Box>
  );
};

export default JournalActionsConfirmation;
