import React from 'react';
import Search from 'q3-ui-repeater/lib/components/Search';

const withSearch = (Component) => (props) => {
  const [search, setSearch] = React.useState('');

  return (
    <Component
      search={search}
      searchElement={<Search handleInput={setSearch} />}
      {...props}
    />
  );
};

export default withSearch;
