import React from 'react';
import { Builders } from 'q3-ui-forms';
import {
  getSafelyForAutoCompleteWithProjection,
  getSafelyForAutoComplete,
} from 'q3-ui-rest';
import AddNewForm from 'q3-admin/lib/components/AddNewForm';

const ProductsAdd = (props) => (
  <AddNewForm
    {...props}
    marshal={{
      course: 'course.value',
      template: 'template.value',
    }}
    marshalSelectively
  >
    <Builders.Field
      name="title"
      type="text"
      required
      xl={12}
      lg={12}
    />
    <Builders.Field
      name="currency"
      type="select"
      options={['CAD', 'EUR', 'USD']}
      required
      xl={12}
      lg={12}
    />
    <Builders.Field
      name="type"
      type="select"
      options={['Course', 'Course Purchase', 'Other']}
      required
      xl={12}
      lg={12}
    />
    <Builders.Field
      name="course"
      type="autocomplete"
      conditional={['type=Course']}
      loadOptions={getSafelyForAutoComplete(
        '/courses',
        'courses',
        'title',
      )}
      xl={12}
      lg={12}
    />
    <Builders.Field
      name="template"
      type="autocomplete"
      conditional={['type=Course Template']}
      loadOptions={getSafelyForAutoCompleteWithProjection(
        '/templates?__t=course',
        'templates',
        'name',
      )}
      xl={12}
      lg={12}
    />
    <Builders.Field
      name="description"
      type="text"
      multiline
      rows={5}
      xl={12}
      lg={12}
    />
  </AddNewForm>
);

ProductsAdd.propTypes = {};

export default ProductsAdd;
