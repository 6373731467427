import React from 'react';
import {
  Avatar,
  Table,
  TableCell,
  TableRow,
  Grid,
  Box,
} from '@material-ui/core';
import { isNumber, size, sortBy } from 'lodash';
import WidgetTitle from 'q3-admin/lib/components/WidgetTitle';
import { useTranslation } from 'q3-ui-locale';
import DashboardIconAdmin from '../DashboardIconAdmin';
import DashboardIconMember from '../DashboardIconMember';
import DashboardIconReading from '../DashboardIconReading';
import useReport from '../useReport';
import useStyle from './styles';

const toNumber = (num) => (isNumber(num) ? num : 0);

// eslint-disable-next-line
const Icon = ({ role }) => {
  let Output = () => null;

  // eslint-disable-next-line
  if (role && role.includes('Administrator'))
    Output = DashboardIconAdmin;

  if (role === 'Coach') Output = DashboardIconReading;
  if (role === 'Member') Output = DashboardIconMember;

  return <Output />;
};

const DashboardUserStats = () => {
  const { data } = useReport('averageUserStats');
  const { t } = useTranslation('labels');
  const cls = useStyle();

  return size(data) ? (
    <Grid item md={6} sm={12}>
      <Grid container spacing={1}>
        {sortBy(data, 'role').map(
          ({
            numberOfComments = 0,
            numberOfJournals = 0,
            role,
          }) => (
            <Grid item sm={6} xs={12} key={role}>
              <Box className={cls.stat}>
                <Avatar className={cls.icon}>
                  <Icon role={role} />
                </Avatar>
                <WidgetTitle
                  text={role.replace(
                    'Administrator',
                    'Admin',
                  )}
                />
                <Table className={cls.table}>
                  <TableRow>
                    <TableCell component="th">
                      {t('numberOfJournals')}
                    </TableCell>
                    <TableCell>
                      {toNumber(numberOfJournals)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th">
                      {t('numberOfComments')}
                    </TableCell>
                    <TableCell>
                      {toNumber(numberOfComments)}
                    </TableCell>
                  </TableRow>
                </Table>
              </Box>
            </Grid>
          ),
        )}
      </Grid>
    </Grid>
  ) : null;
};

export default DashboardUserStats;
