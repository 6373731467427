import React from 'react';
import { Builders, helpers } from 'q3-ui-forms';
import Dialog from 'q3-ui-dialog';
import { string } from 'q3-ui-helpers';
import { Button, Tooltip } from '@material-ui/core';
import FlareIcon from '@material-ui/icons/Flare';
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';
import { useTranslation } from 'q3-ui-locale';
import PropTypes from 'prop-types';
import { hasObjectValue } from '../utils';

const TemplatesCourseHomeworkDateModal = ({
  date,
  disabled,
  distanceFromStartDate,
  name,
  newStartPoint,
  singleException,
  showDateField,
  update,
}) => {
  const { t } = useTranslation('labels');

  const dateString = React.useMemo(() => {
    let str = `Day ${distanceFromStartDate}`;

    if (date && showDateField)
      str += ` (${string.toSimpleDate(date)})`;

    return str;
  }, [distanceFromStartDate, date, showDateField]);

  return (
    <Dialog
      renderContent={(close) => {
        const handleSubmit = (formData) => {
          // required fields
          const obj = { name };
          const check = hasObjectValue(formData);

          if (check('method', 'date'))
            obj.date = helpers.castToUTC(formData.date);
          else
            obj.distanceFromStartDate = Number(
              formData.distanceFromStartDate,
            );

          obj.singleException = check(
            'behaviour',
            'single',
          );
          obj.newStartPoint = check(
            'behaviour',
            'recurring',
          );

          return update(obj).then(close);
        };

        const handleReset = () =>
          update({
            name,
            newStartPoint: false,
            singleException: false,
          }).then(close);

        return (
          <Builders.Form
            initialValues={{
              date,
              distanceFromStartDate,
              method: 'distance',
              behaviour: singleException
                ? 'single'
                : 'recurring',
            }}
            modify={{
              date: [helpers.castToLocalDateTime],
            }}
            enableReset
            resetLabel="revert"
            onSubmit={handleSubmit}
            onReset={handleReset}
          >
            <Builders.Field
              label={t('dateBehaviour')}
              name="behaviour"
              type="radio"
              options={[
                {
                  label: t('singleDateChange'),
                  value: 'single',
                },
                {
                  label: t('recurringDateChange'),
                  value: 'recurring',
                },
              ]}
            />
            <Builders.Field
              disabled={!showDateField}
              label={t('dateMethod')}
              name="method"
              type="radio"
              options={[
                {
                  label: t('byDate'),
                  value: 'date',
                },
                {
                  label: t('byDistance'),
                  value: 'distance',
                },
              ]}
            />
            <Builders.Field
              conditional={['method=date']}
              name="date"
              type="date"
              required
              xl={12}
              lg={12}
              md={12}
            />
            <Builders.Field
              conditional={['method=distance']}
              name="distanceFromStartDate"
              type="number"
              min="0"
              required
              xl={12}
              lg={12}
              md={12}
            />
          </Builders.Form>
        );
      }}
      renderTrigger={(onClick) => (
        <Button
          disabled={disabled}
          onClick={onClick}
          style={{ padding: 0 }}
          endIcon={
            <>
              {singleException && (
                <Tooltip title={t('singleException')}>
                  <FlareIcon />
                </Tooltip>
              )}
              {newStartPoint && (
                <Tooltip title={t('newStartPoint')}>
                  <AssistantPhotoIcon />
                </Tooltip>
              )}
            </>
          }
        >
          <span
            style={{
              textDecoration: 'underline',
              textDecorationStyle: 'dotted',
            }}
          >
            {dateString}
          </span>
        </Button>
      )}
      title="Scheduler"
      variant="drawer"
    />
  );
};

TemplatesCourseHomeworkDateModal.propTypes = {
  date: PropTypes.string,
  disabled: PropTypes.bool,
  distanceFromStartDate: PropTypes.number,
  name: PropTypes.string.isRequired,
  newStartPoint: PropTypes.bool,
  singleException: PropTypes.bool,
  showDateField: PropTypes.bool,
  update: PropTypes.func.isRequired,
};

TemplatesCourseHomeworkDateModal.defaultProps = {
  date: undefined,
  disabled: false,
  distanceFromStartDate: 0,
  newStartPoint: false,
  singleException: false,
  showDateField: false,
};

export default TemplatesCourseHomeworkDateModal;
