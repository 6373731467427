import React from 'react';
import Filter from 'q3-admin/lib/components/Filter';
import { AuthContext } from 'q3-ui-permissions';

const CoursesFilter = () => {
  if (
    !['Master Administrator', 'Administrator'].includes(
      React.useContext(AuthContext)?.state?.profile?.role,
    )
  )
    return null;

  return (
    <Filter
      data={{
        'category.name': {
          type: 'String',
          ref: 'courses',
        },
        'type': {
          type: 'String',
          ref: 'courses',
        },
        func: {
          label: 'Date projection',
          type: 'String',
          enum: ['present', 'future', 'past'],
        },
        trashedOn: {
          type: 'Boolean',
          useHasParam: true,
        },
        published: {
          type: 'Boolean',
        },
        suspended: {
          type: 'Boolean',
        },
      }}
    />
  );
};

export default CoursesFilter;
