import React from 'react';
import axios from 'axios';
import Confirm from 'q3-ui-confirm';
import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { get, isObject } from 'lodash';
import DomainLoading from 'q3-admin/lib/components/DomainLoading';
import SystemPageSub from 'q3-admin/lib/components/SystemPageSub';
import TableVertical from 'q3-admin/lib/components/TableVertical';
import {
  Dispatcher,
  Store,
} from 'q3-admin/lib/containers/state';
import { useTranslation } from 'q3-ui-locale';
import useRole from 'q3-admin/lib/hooks/useRole';
import moment from 'moment';
import { string } from 'q3-ui-helpers';
import ProfileCourseAllocation from '../ProfileCourseAllocation';

export const withCountdown = (xs) => {
  const nextDate = get(xs, 'nextDate');
  const d = moment(nextDate);

  const getDaysUntil = () =>
    Math.max(0, Math.abs(d.diff(new Date(), 'days')));

  if (nextDate && d.isValid())
    Object.assign(xs, {
      nextDate: `${string.toSimpleDate(
        d,
      )} (in ${getDaysUntil()} days)`,
    });

  return xs;
};

const ProfileCancelSubscription = () => {
  const [subscription, setSubcription] = React.useState();
  const { t } = useTranslation();
  const r = useRole();

  // dummy funcs
  const dispatcherStateValue = React.useMemo(
    () => ({
      remove: () => null,
      patch: () => null,
    }),
    [],
  );

  const refresh = () =>
    axios
      .get('/subscription')
      .then((res) => {
        setSubcription(get(res, 'data.subscription', null));
      })
      .catch(() => {
        setSubcription(null);
      });

  React.useEffect(() => {
    refresh();
  }, []);

  if (subscription === undefined) return <DomainLoading />;
  if (subscription === null || !isObject(subscription))
    return (
      <Alert severity="warning">
        {t('descriptions:noSubscriptionFound')}
      </Alert>
    );

  /**
   * @NOTE
   * Refactor once TableVertical has been exposed without connect().
   */
  return (
    <>
      <Box mb={1}>
        <Alert severity="info">
          {t('descriptions:downgradesAndCancellations')}
        </Alert>
      </Box>
      {r.is('Coach') && <ProfileCourseAllocation />}
      <Dispatcher.Provider value={dispatcherStateValue}>
        <Store.Provider
          // eslint-disable-next-line
          value={{
            data: withCountdown(subscription),
          }}
        >
          <TableVertical
            columns={[
              {
                field: 'status',
                formatter: 'string',
              },
              {
                field: 'product',
                formatter: 'string',
              },
              {
                field: 'id',
                formatter: 'string',
                label: 'subscriptionId',
              },
              {
                field: 'plan_id',
                formatter: 'string',
                label: 'plan',
              },
              {
                field: 'price',
                formatter: 'string',
                label: 'subscriptionCost',
              },
              {
                field: 'nextDate',
                formatter: 'string',
                label:
                  subscription?.status === 'ACTIVE'
                    ? 'nextBillingCycle'
                    : 'serviceEndDate',
              },
              {
                field: 'start_time',
                formatter: 'date',
                label: 'createdAt',
              },
            ]}
          />
          {subscription?.status !== 'CANCELLED' && (
            <Box mt={1}>
              <Confirm
                service={() =>
                  axios
                    .delete('/subscription')
                    .then(refresh)
                }
                label="cancel"
              />
            </Box>
          )}
        </Store.Provider>
      </Dispatcher.Provider>
    </>
  );
};

export default () => (
  <SystemPageSub title="accountSubscription">
    <ProfileCancelSubscription />
  </SystemPageSub>
);
