import AbstractCollectionBuilder from 'q3-admin/lib/builders';
import CategoryIcon from '@material-ui/icons/Category';
import Add from '../CategoriesAdd';
import General from '../CategoriesGeneral';

export default new AbstractCollectionBuilder({
  collectionName: 'categories',
  resourceName: 'categories',
  resourceNameSingular: 'category',
  icon: CategoryIcon,
  parent: 'tools',
})
  .genCreate(Add)
  .genHeader({
    titleProp: 'name',
  })
  .genViews({
    General,
  })
  .genList({})
  .genDetail({
    autoOpenSummary: false,
  })
  .genListSettings({
    disableAvatar: true,
    defaultSortPreference: 'name',
  })
  .build();
