import React from 'react';
import { Builders } from 'q3-ui-forms';
import { connect } from 'q3-admin/lib/containers';
import SubDetail from 'q3-admin/lib/containers/subDetail';
import {
  makeHomeworkSeqButtonIncrementers,
  sort,
} from '../withHomeworkSeqButton';

const AttributesOptions = () => (
  <SubDetail
    root="options"
    th="option"
    name="options"
    cardProps={{
      title: ({ emoji, label }) => [emoji, label].join(' '),
      actions: makeHomeworkSeqButtonIncrementers(),
    }}
    initialValues={{}}
    marshal={sort}
  >
    <Builders.Form>
      <Builders.Field
        name="emoji"
        type="text"
        required
        xl={12}
        lg={12}
      />
      <Builders.Field
        name="label"
        type="text"
        required
        xl={12}
        lg={12}
      />
    </Builders.Form>
  </SubDetail>
);

AttributesOptions.propTypes = {};

export default connect(AttributesOptions);
