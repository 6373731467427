import React from 'react';
import {
  Dispatcher,
  Store,
} from 'q3-admin/lib/containers/state';
import { object } from 'q3-ui-helpers';
import { useTranslation } from 'q3-ui-locale';
import { Button } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';

const PublishButton = () => {
  const { patch } = React.useContext(Dispatcher);
  const { data } = React.useContext(Store);
  const { t } = useTranslation('labels');
  const fn = patch();

  const handleClick = () =>
    object.noop(
      fn({
        published: true,
        suspended: false,
      }),
    );

  return data.published ? null : (
    <Button
      color="inherit"
      onClick={handleClick}
      size="small"
      startIcon={<PublishIcon />}
    >
      {t('publish')}
    </Button>
  );
};

export default PublishButton;
