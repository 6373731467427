import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  item: {
    width: 'auto',
  },
  stat: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 16,
    height: 250,
    padding: theme.spacing(2),
    // width: 295,

    '& th, & td': {
      borderBottom: 'none !important',
    },

    '& td': {
      fontWeight: 'bold',
    },
  },
  icon: {
    backgroundColor: theme.palette.background.default,
    height: 85,
    marginBottom: theme.spacing(2),
    width: 85,

    '& svg': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
  },
  table: {
    marginTop: theme.spacing(1),
  },
}));
