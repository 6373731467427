import {
  LinearProgress,
  withStyles,
  makeStyles,
} from '@material-ui/core';

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[
        theme.palette.type === 'light' ? 200 : 700
      ],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  },
}))(LinearProgress);

export default makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
  },
}));
