import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Article from 'q3-admin/lib/components/Article';
import Header from 'q3-admin/lib/components/Header';
import Title from 'q3-admin/lib/components/Title';
import {
  PatternDataGrid,
  PatternContainer,
} from 'q3-admin/lib/components';
import { useTranslation } from 'q3-ui-locale';
import useActiveSearchQuery from 'q3-admin/lib/hooks/useActiveSearchQuery';
import ReportsFilter from '../ReportsFilter';
import ReportsFilterGate from '../ReportsFilterGate';

const Reports = () => {
  const { t } = useTranslation('titles');
  const queryParams = useActiveSearchQuery();

  return (
    <ReportsFilterGate>
      <Article>
        <Box py={2} mb={4}>
          <Header>
            <Box alignItems="center" display="flex">
              <Title>{t('reports')}</Title>
              <ReportsFilter />
            </Box>
          </Header>
          <PatternContainer>
            <PatternDataGrid
              formatters={{
                lastJournaled: 'datetime',
                lastCommented: 'datetime',
                lastLoggedIn: 'datetime',
                percWithAttr: 'number',
                avgComments: 'number',
                numberOfComments: 'number',
                numberOfJournals: 'number',
                avgWordCount: 'number',
              }}
              queryParams={queryParams}
              report="usage"
              width={{
                name: 210,
                avgWordCount: 155,
                avgComments: 155,
                percWithAttr: 155,
                numberOfJournals: 155,
                numberOfComments: 155,
                lastJournaled: 185,
                lastCommented: 185,
                lastLoggedIn: 185,
              }}
            />
            <PatternDataGrid
              width={{
                name: 210,
                answer: 155,
                frequency: 155,
              }}
              queryParams={queryParams}
              report="usageAttributes"
            />
            <PatternDataGrid
              queryParams={queryParams}
              report="usageKeywords"
              width={{
                name: 210,
              }}
            />
            <PatternDataGrid
              report="usageAllocation"
              width={{
                name: 210,
              }}
            />
            <Typography
              style={{ margin: '0 1rem' }}
              textAlign="right"
            >
              <small>
                {t('descriptions:usageAllocation')}
              </small>
            </Typography>
          </PatternContainer>
        </Box>
      </Article>
    </ReportsFilterGate>
  );
};

export default Reports;
