import React from 'react';
import { isEqual } from 'lodash';
import { object } from 'q3-ui-helpers';

const useStateWithCallback = (initialState, callback) => {
  const [state, setState] = React.useState(initialState);
  const ref = React.useRef();

  React.useEffect(() => {
    if (!isEqual(initialState, state))
      ref.current = setTimeout(
        () => object.noop(callback(state)),
        500,
      );

    return () =>
      ref.current ? clearTimeout(ref.current) : undefined;
  }, [state]);

  return [state, setState];
};

export default useStateWithCallback;
