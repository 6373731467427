import React from 'react';
import PropTypes from 'prop-types';
import {
  Dispatcher,
  Definitions,
  Store,
} from 'q3-admin/lib/containers/state';
import Repeater from 'q3-ui-repeater';
import { Builders } from 'q3-ui-forms';
import { Grid, CircularProgress } from '@material-ui/core';
import useRest from 'q3-ui-rest';
import { isFunction } from 'lodash';
import FrequencyDialog from '../FrequencyDialog';
import ImportHomeworkDialog from '../ImportHomeworkDialog';
import {
  makeHomeworkSeqButtonIncrementers,
  sort,
} from '../withHomeworkSeqButton';
import useStyles from './styles';
import TemplatesCourseHomeworkReadOnly from '../TemplatesCourseHomeworkReadOnly';
import TemplatesCourseHomeworkSave from '../TemplatesCourseHomeworkSave';
import useHomeworkRepeaterSettings from '../useHomeworkRepeaterSettings';
import { getSafelyForAutoComplete } from 'q3-ui-rest';

// force refresh RTE
// eslint-disable-next-line
const HomeworkEditor = ({ upload, ...props }) => {
  const [reload, setReload] = React.useState(false);
  // eslint-disable-next-line
  const id = props?.initialValues?.id;

  React.useEffect(() => {
    try {
      setReload(true);
      setTimeout(() => {
        setReload(false);
      }, 100);
    } catch (e) {
      // noop
    }
  }, [id]);

  return reload ? (
    <CircularProgress />
  ) : (
    <Builders.Form
      {...props}
      keep={['body', 'category', 'name', 'type']}
      translate={{
        'category.label': 'category.name',
        'category.value': 'category.ref',
      }}
      marshal={{
        'body': 'body',
        'category.ref': 'category.value',
        'name': 'name',
        'type': 'type',
      }}
    >
      <Builders.Field
        under="homework"
        name="name"
        type="text"
        xl={12}
        lg={12}
      />
      <Builders.Field
        name="type"
        type="select"
        xl={12}
        lg={12}
        options={['Assignment', 'Prompt']}
      />
      <Builders.Field
        name="category"
        type="autocomplete"
        loadOptions={getSafelyForAutoComplete(
          '/categories',
          'categories',
          'name',
        )}
        xl={12}
        lg={12}
      />
      <Builders.Field
        name="body"
        type="editor"
        upload={upload}
        under="homework"
        xl={12}
        lg={12}
      />
    </Builders.Form>
  );
};

const TemplatesCourseHomework = (props) => {
  const cls = useStyles();
  const { data } = React.useContext(Store);
  const { patch, replace } = React.useContext(Dispatcher);
  const { collectionName, id, resourceNameSingular } =
    React.useContext(Definitions);

  const frequency = data?.frequency;

  const subdocumentState = useRest({
    key: 'homework',
    pluralized: 'homework',
    runOnInit: false,
    url: `/${collectionName}/${id}/homework`,
    sendUpdateAsFullReceipt: true,

    poll: ({ full }) =>
      Promise.resolve(
        replace({
          [resourceNameSingular]: full,
        }),
      ),
  });

  const {
    attributes,
    disableAdd,
    disableRemove,
    editable,
    showEditor,
    showFrequency,
    upload,
  } = useHomeworkRepeaterSettings(data);

  const items = data?.homework;

  const renderAddDialog = React.useMemo(
    () =>
      !disableAdd ? (
        <Grid item>
          <ImportHomeworkDialog
            onImport={subdocumentState.post}
          />
        </Grid>
      ) : null,
    [disableAdd],
  );

  const renderFrequencyDialog = React.useMemo(
    () =>
      showFrequency ? (
        <Grid item>
          <FrequencyDialog
            collectionName={collectionName}
            initialValues={frequency}
            onSubmit={
              isFunction(patch) ? patch() : undefined
            }
          />
        </Grid>
      ) : null,
    [frequency, showFrequency],
  );

  const saveHomeworkAction = React.useCallback(
    (row) => <TemplatesCourseHomeworkSave {...row} />,
    [],
  );

  const incrementers = makeHomeworkSeqButtonIncrementers(
    subdocumentState.patch,
  );

  return React.useMemo(
    () => (
      <div className={cls.root}>
        <Repeater
          {...subdocumentState}
          disableAdd={disableAdd}
          disableRemove={disableRemove}
          collectionName={collectionName}
          data={sort(items)}
          edit={subdocumentState.patch}
          editBulk={subdocumentState.patchBulk}
          create={subdocumentState.post}
          // @NOTE change this later
          remove={
            !disableRemove ? subdocumentState.remove : null
          }
          removeBulk={subdocumentState.removeBulk}
          renderOther={() => (
            <>
              {renderFrequencyDialog}
              {renderAddDialog}
            </>
          )}
          th="name"
          root="homework"
          name="homework"
          cardProps={{
            actions: editable
              ? incrementers.concat({
                  disableDialog: true,
                  component: saveHomeworkAction,
                })
              : [
                  {
                    disableDialog: true,
                    component: saveHomeworkAction,
                  },
                ],
            attributes,
            editable,
            title: 'name',
          }}
          initialValues={{
            title: '',
            body: '',
          }}
        >
          {showEditor ? (
            <HomeworkEditor {...props} upload={upload} />
          ) : (
            <TemplatesCourseHomeworkReadOnly />
          )}
        </Repeater>
      </div>
    ),
    [attributes, editable, items],
  );
};

TemplatesCourseHomework.defaultProps = {
  data: {},
};

TemplatesCourseHomework.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line
    frequency: PropTypes.object,
    type: PropTypes.string,
  }),
};

export default TemplatesCourseHomework;
