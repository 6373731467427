import React from 'react';
import { connect } from 'q3-admin/lib/containers';
import { AuthContext } from 'q3-ui-permissions';
import CoursesUsers from '../CoursesUsers';
import { isAdmin } from '../utils';

// eslint-disable-next-line
export const CoursesParticipants = ({ data }) => {
  const auth = React.useContext(AuthContext);
  const role = auth?.state?.profile?.role;

  const disabledProps =
    // eslint-disable-next-line
    data?.enableParticipants || isAdmin(role)
      ? {}
      : {
          disableAdd: true,
          disableRemove: true,
        };

  return (
    <CoursesUsers
      includeStartColumn
      plural="participants"
      // eslint-disable-next-line
      role="Member"
      singular="participant"
      {...disabledProps}
    />
  );
};

export default connect(CoursesParticipants);
