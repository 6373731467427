import React from 'react';
import { useNavigate } from '@reach/router';
import moment from 'moment';
import useActiveSearchQuery from 'q3-admin/lib/hooks/useActiveSearchQuery';

// eslint-disable-next-line
const ReportsFilterGate = ({ children }) => {
  const [init, setInit] = React.useState(false);
  const queryParams = useActiveSearchQuery();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!Object.keys(queryParams).length) {
      navigate(
        `?publishedOn>=${moment()
          .startOf('month')
          .toISOString()}`,
      ).then(() => {
        setInit(true);
      });
    } else {
      setInit(true);
    }
  }, []);

  return init ? children : null;
};

export default ReportsFilterGate;
