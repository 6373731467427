import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'q3-admin';
import { Builders } from 'q3-ui-forms';

export const CategoriesGeneral = ({ data, ...props }) => (
  <Builders.Form
    {...props}
    initialValues={data}
    keep={['description', 'name']}
  >
    <Builders.Field
      name="name"
      type="text"
      required
      xl={12}
      lg={12}
    />
    <Builders.Field
      name="description"
      type="text"
      multiline
      rows={5}
      xl={12}
      lg={12}
    />
  </Builders.Form>
);

CategoriesGeneral.defaultProps = {
  data: {},
};

CategoriesGeneral.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default connect(CategoriesGeneral);
