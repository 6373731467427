import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'q3-admin';
import { Box } from '@material-ui/core';
import { useAuth } from 'q3-ui-permissions';
import useStyle from './styles';
import ReadOnlyTemplate from '../ReadOnlyTemplate';

export const ArticlesReadOnly = ({ data }) => {
  const { canEdit } = useAuth('articles');
  const cls = useStyle();

  return canEdit ? null : (
    <ReadOnlyTemplate photo={data.photo}>
      <Box
        className={cls.root}
        dangerouslySetInnerHTML={{
          __html: data?.body,
        }}
      />
    </ReadOnlyTemplate>
  );
};

ArticlesReadOnly.defaultProps = {
  data: {},
};

ArticlesReadOnly.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    body: PropTypes.string,
    photo: PropTypes.string,
  }),
};

export default connect(ArticlesReadOnly);
