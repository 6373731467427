import useProfileForm from 'q3-admin/lib/hooks/useProfileForm';
import { includes, isObject } from 'lodash';
import { COACH } from '../useUserRoleOptions/useUserRoleOptions';

const useCoachUnreadRead = () => {
  const { initialValues: profile } = useProfileForm();
  const { listens, role } = isObject(profile)
    ? profile
    : {};

  return (
    role === COACH &&
    (includes(listens, 'onNewJournal') ||
      includes(listens, 'onNewJournal__native'))
  );
};

export default useCoachUnreadRead;
