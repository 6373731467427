import React from 'react';
import Filter from 'q3-admin/lib/components/Filter';
import useUserRoleOptions from '../useUserRoleOptions';

const UsersFilter = () => {
  const getOriginalRoleOptions = useUserRoleOptions();

  return (
    <Filter
      data={{
        role: {
          type: 'String',
          ref: 'users',
          enum: getOriginalRoleOptions(),
        },
        lastLoggedIn: {
          type: 'Date',
        },
        countryOfResidence: {
          type: 'String',
          ref: 'users',
        },
        region: {
          type: 'String',
          ref: 'users',
        },
      }}
    />
  );
};

export default UsersFilter;
