import React from 'react';
import { map, filter, sortBy } from 'lodash';
import {
  Definitions,
  Dispatcher,
  Store,
} from 'q3-admin/lib/containers';
import useRest from 'q3-ui-rest';
import { string } from 'q3-ui-helpers';
import Repeater from 'q3-ui-repeater';
import SecondarySearchList from '../SecondarySearchList';
import useSecondaryListStateHelpers from '../useSecondaryListStateHelpers';

const TemplatesCoaches = () => {
  const { data } = React.useContext(Store);
  const { replace } = React.useContext(Dispatcher);
  const { collectionName, id, resourceNameSingular } =
    React.useContext(Definitions);

  const subdocumentState = useRest({
    key: 'coaches',
    pluralized: 'coaches',
    runOnInit: false,
    url: `/${collectionName}/${id}/coaches`,
    sendUpdateAsFullReceipt: true,

    poll: ({ full }) =>
      Promise.resolve(
        replace({
          [resourceNameSingular]: full,
        }),
      ),
  });

  const { add, remove } = useSecondaryListStateHelpers(
    subdocumentState,
    'coach',
    data?.coaches,
  );

  return (
    <Repeater
      id={id}
      disableEditor
      collectionName={collectionName}
      edit={subdocumentState.patch}
      editBulk={subdocumentState.patchBulk}
      create={subdocumentState.post}
      remove={subdocumentState.remove}
      removeBulk={subdocumentState.removeBulk}
      data={sortBy(filter(data?.coaches, 'coach.id'), [
        'coach.firstName',
        'coach.lastName',
      ])}
      th="name"
      root="coaches"
      name="coaches"
      initialValues={{
        ids: map(data?.coaches, 'coach.id'),
      }}
      cardProps={{
        title: (v) => string.makeName(v.coach),
      }}
    >
      <SecondarySearchList
        key="user"
        pluralized="users"
        onClick={add}
        onRemove={remove}
        query="?sort=name&role=in(Administrator,Coach,Master Administrator)"
        url="users"
        makeListItemTextProps={(item) => ({
          primary: item.name,
          secondary: item.role,
        })}
      />
    </Repeater>
  );
};

export default TemplatesCoaches;
