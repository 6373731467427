import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'q3-ui-locale';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'q3-admin/lib/containers';
import { useIo } from 'q3-admin/lib/hooks';
import EventIcon from '@material-ui/icons/Event';

const CoursesCallsExport = ({ data: { id } }) => {
  const { t } = useTranslation('labels');
  const [disabled, setDisabled] = React.useState(false);
  const fn = useIo([id]).exportCollection('exportCalendar');

  const handleClick = () => {
    setDisabled(true);

    return Promise.resolve(fn()).finally(() => {
      setDisabled(false);
    });
  };

  return (
    <div>
      <Tooltip arrow title={t('exportCalendar')}>
        <IconButton
          color="inherit"
          disabled={disabled}
          onClick={handleClick}
          style={{
            display: 'block',
          }}
        >
          <EventIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

CoursesCallsExport.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default connect(CoursesCallsExport);
