import React from 'react';
import DashboardCourseTable from '../DashboardCourseTable';

const DashboardCourseStats = () => (
  <DashboardCourseTable
    report="averageCourseStats"
    title="averages"
  />
);

export default DashboardCourseStats;
