export const getTitle = ({ name, frequency }) =>
  name || frequency;

export const isRecurring = ({ type }) =>
  type === 'Recurring';

export const isSingle = ({ type }) => type === 'Single';

export const pad = (str) =>
  Array.from({
    length: 2 - String(str).length,
  })
    .map(() => '0')
    .join('') + str;

export const retrieve = (key) => (args) => args[key];
