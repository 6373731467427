import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  isFunction,
  first,
  isObject,
  round,
  size,
} from 'lodash';
import { useTranslation } from 'q3-ui-locale';
import useReport from '../useReport';
import useStyle from './styles';

const DashboardCourseTable = ({
  marshal,
  report,
  title,
}) => {
  const { data } = useReport(report);
  const { t } = useTranslation('labels');
  const cls = useStyle();

  const stats = isFunction(marshal)
    ? marshal(data)
    : first(data);

  return isObject(stats) &&
    size(Object.entries(stats)) > 0 ? (
    <Box>
      <Typography
        className={cls.title}
        color="secondary"
        component="h3"
        variant="overline"
      >
        {t(title)}
      </Typography>
      <Table className={cls.table}>
        {Object.entries(stats).map(([key, value]) =>
          key !== '_id' ? (
            <TableRow key={key}>
              <TableCell component="th">{t(key)}</TableCell>
              <TableCell>{round(value, 2)}</TableCell>
            </TableRow>
          ) : null,
        )}
      </Table>
    </Box>
  ) : null;
};

DashboardCourseTable.defaultProps = {
  marshal: null,
};

DashboardCourseTable.propTypes = {
  marshal: PropTypes.func,
  report: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default DashboardCourseTable;
