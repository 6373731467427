import React from 'react';
import PropTypes from 'prop-types';
import { Link, Typography } from '@material-ui/core';
import { connect } from 'q3-admin';
import { browser } from 'q3-ui-helpers';
import useDomainContext from 'q3-admin/lib/hooks/useDomainContext';

const getUrl = (xs) =>
  browser.isBrowserReady()
    ? `${window.location.origin}/open-registration?course=${xs.id}`
    : '';

const CoursesGeneral = ({ data }) => {
  const { domain } = useDomainContext();
  const url = getUrl(data);

  return domain?.publicRegistration ? (
    <Link
      color="inherit"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      {url}
    </Link>
  ) : (
    <Typography color="inherit">
      Public registration disabled for this domain.
    </Typography>
  );
};

CoursesGeneral.defaultProps = {
  data: {},
};

CoursesGeneral.propTypes = {
  data: PropTypes.shape({}),
};

export default connect(CoursesGeneral);
