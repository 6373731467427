import React from 'react';
import { useLocation } from '@reach/router';
import { useTheme } from '@material-ui/core';
import useRole from 'q3-admin/lib/hooks/useRole';
import useProfileForm from 'q3-admin/lib/hooks/useProfileForm';
import { get } from 'lodash';
import useCoachUnreadRead from '../useCoachUnreadRead';
import {
  ADMINISTRATOR_ASSISTANT,
  ADMINISTRATOR,
  MASTER_ADMINISTRATOR,
  COACH,
} from '../useUserRoleOptions/useUserRoleOptions';

const Style = () => {
  const location = useLocation();
  const theme = useTheme();

  const { initialValues: profile } = useProfileForm();
  const role = useRole();
  const isAdministrator = role.isOneOf([
    MASTER_ADMINISTRATOR,
    ADMINISTRATOR_ASSISTANT,
    ADMINISTRATOR,
  ]);

  const canSeeUnreadSegment = useCoachUnreadRead();

  const renderDisplayValue = (bool) =>
    get(profile, bool, false) === true ? 'inherit' : 'none';

  const courseAddButton =
    role.is(COACH) &&
    !profile?.allocation &&
    location.pathname.includes('courses')
      ? ' #appbar-create { display: none; }  '
      : '';

  const unreadSegment = !canSeeUnreadSegment
    ? `
    [href="/app/journals?func=string(unread)"] { 
      display: none;
    }`
    : '';

  const conditionalStyles = role.is(ADMINISTRATOR_ASSISTANT)
    ? `
      [data-list-item="articles"] {
        display: ${renderDisplayValue('enableArticles')};
      }
      [data-list-item="attributes"] {
        display: ${renderDisplayValue('enableAttributes')};
      }
      [data-list-item="categories"] {
        display: ${renderDisplayValue('enableCategories')};
      }
      [data-list-item="reports"] {
        display: ${renderDisplayValue('enableReports')};
      }
      [data-list-item="templates"] {
        display: ${renderDisplayValue('enableTemplates')};
      }
      nav > div > div > div:nth-child(2) {
        display: ${
          [
            profile.enableArticles,
            profile.enableAttributes,
            profile.enableCategories,
            profile.enableTemplates,
            profile.enableReports,
          ].some(Boolean)
            ? 'block'
            : 'none'
        };
      }
    `
    : '';

  return (
    <style>
      {`
        a[href="/app/account/locale"], a[href="/app/account/locale"] + hr, #q3-filter-utils { 
          display: none;
        }   
        
        #comments-rte form > div > div:last-of-type {
          align-items: center;
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
        }

        #comments-rte form > div > div:last-of-type > div {
          margin-top: 0;
        }

        td#coaches, td#journals {
          min-width: 210px;
        }

        td#subscriptionId {
          min-width: 180px;
        }
      `}
      {`#appbar-search-trigger { background-color: ${theme.palette.secondary.light}; color: ${theme.palette.secondary.main}; border: 1px solid ${theme.palette.secondary.main}; }`.concat(
        isAdministrator
          ? ''
          : ' #filter-button { display: none; }',
      )}
      {unreadSegment}
      {courseAddButton}
      {conditionalStyles}
    </style>
  );
};

export default Style;
