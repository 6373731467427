import useRest from 'q3-ui-rest';
import { find } from 'lodash';

const useAttributesRest = () => {
  const r = useRest({
    key: 'attributes',
    pluralized: 'attributes',
    runOnInit: true,
    url: '/attributes?sort=sort&limit=250&options.label=has(true)',
  });

  return {
    ...r,
    findByName: (xs, name) =>
      find(xs, (item) => item.name === name),
  };
};

export default useAttributesRest;
