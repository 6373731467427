import { find } from 'lodash';

const useSecondaryListStateHelpers = (
  state,
  key,
  data = [],
) => {
  const add = (xs) =>
    state.post({
      [key]: xs,
    });

  const remove = (userId) => {
    const subDocId = find(
      data,
      (item) => item[key]?.id === userId,
    )?.id;

    return subDocId
      ? state.remove(subDocId)()
      : Promise.resolve();
  };

  return {
    add,
    remove,
  };
};

export default useSecondaryListStateHelpers;
