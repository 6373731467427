import React from 'react';
// eslint-disable-next-line
import moment from 'moment';
import { connect } from 'q3-admin';
import { Builders } from 'q3-ui-forms';
import { castToBoolean } from 'q3-ui-forms/lib/helpers';
import {
  ALL_MEMBERS,
  COACHES_ONLY,
  PRIVATE,
} from '../Journals/constants';

// eslint-disable-next-line
const TemplatesSettings = ({ data, ...props }) => (
  <Builders.Form
    {...props}
    initialValues={data}
    keep={[
      'privacy',
      'enableChanges',
      'enableFileManagement',
      'enableCallFileManagement',
      'enableHomeworkFileManagement',
      'enableHomework',
      'enableCall',
      'enableFrequency',
      'enableParticipants',
      'enableState',
      'groupHomework',
      'type',
    ]}
    marshalSelectively
    marshal={{
      enableChanges: [castToBoolean],
      enableCallFileManagement: [castToBoolean],
      enableFileManagement: [castToBoolean],
      enableHomeworkFileManagement: [castToBoolean],
      enableHomework: [castToBoolean],
      enableFrequency: [castToBoolean],
      enableCall: [castToBoolean],
      groupHomework: [castToBoolean],
    }}
  >
    <Builders.Field
      options={[ALL_MEMBERS, COACHES_ONLY, PRIVATE]}
      name="privacy"
      type="select"
      xl={12}
      lg={12}
    />
    <Builders.Field
      name="enableChanges"
      type="checkbox"
      variant="switch"
    />
    <Builders.Field
      name="enableHomework"
      type="checkbox"
      variant="switch"
    />
    <Builders.Field
      name="enableCall"
      type="checkbox"
      variant="switch"
    />
    <Builders.Field
      conditional={['type!=Open']}
      name="enableFrequency"
      type="checkbox"
      variant="switch"
    />
    <Builders.Field
      name="enableFileManagement"
      type="checkbox"
      variant="switch"
    />
    {/* <Builders.Field
      name="enableCallFileManagement"
      type="checkbox"
      variant="switch"
    /> */}
    <Builders.Field
      name="enableHomeworkFileManagement"
      type="checkbox"
      variant="switch"
    />
    <Builders.Field
      name="enableParticipants"
      type="checkbox"
      variant="switch"
    />
    <Builders.Field
      name="enableState"
      type="checkbox"
      variant="switch"
    />
    <Builders.Field
      name="groupHomework"
      type="checkbox"
      variant="switch"
    />
  </Builders.Form>
);

export default connect(TemplatesSettings);
