import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import Avatar from 'q3-ui/lib/avatar';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { get } from 'lodash';
import Chip from '@material-ui/core/Chip';
import Fade from '@material-ui/core/Fade';
import { string } from 'q3-ui-helpers';
import SchoolIcon from '@material-ui/icons/School';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import useDomainContext from 'q3-admin/lib/hooks/useDomainContext';
import { useTranslation } from 'q3-ui-locale';
import { SelectOne } from 'q3-ui-exports';
import useStyle from './styles';

const JournalsCollectionCard = ({
  createdBy,
  course,
  title,
  publishedOn,
  id,
  variant,
  prompt,
  publishedState,
  updatedAt,
  hasBeenTrashed,
  trashedOn,
  read,
}) => {
  const { t } = useTranslation('descriptions');
  const { domain } = useDomainContext();
  const cls = useStyle();

  const name = string.makeName(createdBy);

  const makeChipProps = (label) => ({
    className: cls.chip,
    label,
    size: 'small',
    title: label,
  });

  const getAvatarPhotoUrl = () =>
    get(
      createdBy,
      'photo',
      name ? null : get(domain, 'favicon'),
    );

  const getSubheaderText = () => {
    if (hasBeenTrashed)
      return t('journalTrashedOn', {
        date: string.toDate(trashedOn),
      });

    if (publishedOn)
      return t('journalPublishedOn', {
        date: string.toDate(publishedOn),
      });

    return t('journalDraftUpdatedOn', {
      date: string.toDate(updatedAt),
    });
  };

  return (
    <Fade in>
      <Box height="100%" mb={0.5}>
        <Card className={cls.cardWrapper} variant={variant}>
          <CardHeader
            action={<SelectOne id={id} />}
            className={cls.dense}
            title={getSubheaderText()}
            titleTypographyProps={{
              component: 'h3',
              variant: 'body2',
              color: 'inherit',
              className: cls.small,
            }}
          />

          <CardActionArea
            className={cls.card}
            component={Link}
            to={`/app/journals/${id}`}
          >
            <CardContent>
              <Grid container spacing={1} wrap="nowrap">
                <Grid item>
                  <Badge
                    badgeContent={read ? 0 : 1}
                    className={cls.badge}
                    variant="dot"
                  >
                    <Avatar
                      imgSrc={getAvatarPhotoUrl()}
                      word={name}
                    />
                  </Badge>
                </Grid>
                <Grid item>
                  <Box className={cls.title}>{title}</Box>
                  <small>
                    {t('labels:writtenBy', {
                      name,
                    })}
                    <Box className={cls.underline}>
                      {t('labels:readJournal')}
                    </Box>
                  </small>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
          <CardActions className={cls.wrap}>
            <Grid container>
              {prompt && (
                <Grid item xs={12}>
                  <Chip
                    title={prompt}
                    icon={<AssignmentIcon />}
                    {...makeChipProps(prompt)}
                  />
                </Grid>
              )}
              {course?.title && (
                <Grid item xs={12} sm={6}>
                  <Chip
                    to={`/app/journals?course.title=string(${encodeURIComponent(
                      course.title,
                    )})`}
                    icon={<SchoolIcon />}
                    component={Link}
                    onClick={() => null}
                    {...makeChipProps(course.title)}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Chip
                  icon={<VisibilityIcon />}
                  {...makeChipProps(
                    hasBeenTrashed
                      ? 'Trashed'
                      : publishedState,
                  )}
                />
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Box>
    </Fade>
  );
};

JournalsCollectionCard.defaultProps = {
  createdBy: undefined,
  prompt: undefined,
  publishedOn: undefined,
  publishedState: 'Draft',
  title: 'Untitled',
  variant: undefined,
  updatedAt: undefined,
  hasBeenTrashed: false,
  trashedOn: null,
  read: true,
};

JournalsCollectionCard.propTypes = {
  course: PropTypes.shape({
    ref: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  createdBy: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  prompt: PropTypes.string,
  publishedOn: PropTypes.string,
  publishedState: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  updatedAt: PropTypes.string,
  hasBeenTrashed: PropTypes.bool,
  trashedOn: PropTypes.string,
  read: PropTypes.bool,
};

export default JournalsCollectionCard;
