import React from 'react';
import Article from 'q3-admin/lib/components/Article';
import { Box, Grid, Typography } from '@material-ui/core';
import { Redirect } from 'gatsby-theme-q3/src/components';
import useRole from 'q3-admin/lib/hooks/useRole';
import useProfileForm from 'q3-admin/lib/hooks/useProfileForm';
import { get } from 'lodash';
import DashboardCourseStats from '../DashboardCourseStats';
import DashboardIconCourse from '../DashboardIconCourse';
import DashboardJournalTrends from '../DashboardJournalTrends';
import DashboardSegments from '../DashboardSegments';
import DashboardTopCategories from '../DashboardTopCategories';
import DashboardUserStats from '../DashboardUserStats';
import DashboardWidget from '../DashboardWidget';
import DashboardJournalStats from '../DashboardJournalStats';
import {
  ADMINISTRATOR_ASSISTANT,
  COACH,
  MEMBER,
} from '../useUserRoleOptions/useUserRoleOptions';

const Dashboard = () => {
  const { initialValues: profile } = useProfileForm();
  const { is } = useRole();

  if (is(MEMBER)) return <Redirect to="/app/journals" />;

  if (is(COACH))
    return <Redirect to="/app/notifications" />;

  if (
    is(ADMINISTRATOR_ASSISTANT) &&
    !get(profile, 'enableReports', false)
  )
    return <Redirect to="/app/courses" />;

  return (
    <Article>
      <Box py={2} mb={4}>
        <Box mt={2} mb={1}>
          <Typography variant="h1">Dashboard</Typography>
        </Box>
        <Box mb={1}>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <Box>
                <DashboardWidget title="numberOfPublished">
                  <DashboardJournalTrends />
                </DashboardWidget>
              </Box>
            </Grid>
            <DashboardUserStats />
          </Grid>
        </Box>
        <DashboardWidget title="courseStats">
          <Box py={2}>
            <Grid alignItems="center" container spacing={5}>
              <Grid item>
                <DashboardIconCourse />
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <DashboardCourseStats />
                  </Grid>
                  <Grid item>
                    <DashboardJournalStats />
                  </Grid>
                  <Grid item>
                    <DashboardSegments />
                  </Grid>
                  <Grid item>
                    <DashboardTopCategories />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DashboardWidget>
      </Box>
    </Article>
  );
};

export default Dashboard;
