import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'q3-admin/lib/containers';
import axios from 'axios';
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'q3-ui-locale';

const CoursesReadOnlyCallFile = ({
  data: { id },
  name,
}) => {
  const { t } = useTranslation('labels');

  const handleFileDownload = (e) => {
    e.preventDefault();
    e.stopPropagation();

    return axios
      .get(`/call-file?id=${id}&filename=${name}`)
      .then(({ data }) => {
        window.open(data.url);
      })
      .catch(() => {
        // eslint-disable-next-line
        alert(t('descriptions:useFileManager'));
      });
  };

  return name ? (
    <Tooltip title={t('downloadFile')}>
      <IconButton
        color="secondary"
        onClick={handleFileDownload}
      >
        <GetAppIcon />
      </IconButton>
    </Tooltip>
  ) : null;
};

CoursesReadOnlyCallFile.defaultProps = {
  name: undefined,
};

CoursesReadOnlyCallFile.propTypes = {
  name: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default connect(CoursesReadOnlyCallFile);
