import { object } from 'q3-ui-helpers';
import axios from 'axios';
import { useTranslation } from 'q3-ui-locale';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';

const useUserCancel = (
  { id, hasCancelled, subscriptionId },
  { poll },
) => {
  const { t } = useTranslation('labels');

  return subscriptionId && !hasCancelled
    ? {
        label: t('cancelSubscription'),
        icon: SyncDisabledIcon,
        onClick: () =>
          object.noop(
            axios
              .post(`/users/${id}/cancel`)
              .then(() => poll()),
          ),
      }
    : null;
};

export default useUserCancel;
