import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  map,
  sortBy,
  compact,
  orderBy,
  filter,
  size,
} from 'lodash';
import { string } from 'q3-ui-helpers';
import { useTranslation } from 'q3-ui-locale';
import { connect } from 'q3-admin/lib/containers';
import moment from 'moment';
import withNotificationsAnalytics from 'q3-admin/lib/helpers/withNotificationsAnalytics';
import Dialog from 'q3-ui-dialog';
import { FileManager } from 'q3-ui-filemanager';
import { Definitions } from 'q3-admin/lib/containers/state';
import ReadOnlyTemplateAccordionNested from '../ReadOnlyTemplateAccordionNested';
import ReadOnlyTemplateAccordion from '../ReadOnlyTemplateAccordion';
import CoursesReadOnlyCallFile from '../CoursesReadOnlyCallFile';
import ReadOnlyTemplate from '../ReadOnlyTemplate';
import useArticleStyle from '../ArticlesReadOnly/styles';
import JournalsAdd from '../JournalsAdd';
import { createNewJournal } from '../utils';
import useStyle from './styles';

export const CoursesReadOnly = ({
  data: {
    id,
    calls,
    callInformation,
    coaches,
    description,
    homework,
    participants,
    photo,
    type,
    userStartDate,
    userEndDate,
  },
}) => {
  const defs = React.useContext(Definitions);
  const cls = useArticleStyle();
  const cls2 = useStyle();
  const { t } = useTranslation('labels');
  const isOpen = type === 'Open';

  const handleNewJournal = (h) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    return createNewJournal({
      course: {
        ref: id,
      },
      prompt: h.name,
      promptId: h.id,
      title: 'Untitled',
    });
  };

  const hasNotPassed = ({ userStartDate: d }) =>
    moment().isSameOrAfter(d);

  const makePeople = () =>
    sortBy(
      map(
        compact([coaches, participants].flat()),
        (user) => ({
          id: user.id,
          role: user?.coach ? 'Coach' : 'Member',
          name: string.makeName(
            user?.coach || user?.participant,
          ),
          ...user?.coach,
          ...user?.participant,
        }),
        // hide invited users
      ).filter(({ name }) => name !== 'Anonymous'),
      'name',
    );

  const homeworkToDisplay = orderBy(
    filter(homework, hasNotPassed),
    ['userStartDate', 'name'],
    ['desc', 'asc'],
  );

  return (
    <ReadOnlyTemplate photo={photo} noGutters>
      <Dialog
        renderContent={() => (
          <JournalsAdd
            onSubmit={createNewJournal}
            initialValues={{
              'course': id,
            }}
          />
        )}
        renderTrigger={(onClick) => (
          <Box className={cls2.cta} mb={2}>
            <Hidden mdDown>
              <style>
                {
                  '#page-title { width: calc(100% - 115px) }'
                }
              </style>
            </Hidden>
            <Button
              color="secondary"
              onClick={onClick}
              variant="contained"
            >
              {t('createJournals')}
            </Button>
          </Box>
        )}
        variant="drawer"
        title="journal"
      />
      <ReadOnlyTemplateAccordion title="description">
        <Box mb={1} px={2}>
          <Typography
            style={{
              whiteSpace: 'break-spaces',
            }}
          >
            {userStartDate && userEndDate ? (
              <Typography
                component="strong"
                className="course-dates"
              >
                {'From '}
                {string.toSimpleDate(userStartDate)}
                {' to '}
                {string.toSimpleDate(userEndDate)}
                {' — '}
              </Typography>
            ) : null}
            {description}
          </Typography>
        </Box>
      </ReadOnlyTemplateAccordion>
      <ReadOnlyTemplateAccordion title="homework">
        {size(homeworkToDisplay) ? (
          map(homeworkToDisplay, (h) => (
            <ReadOnlyTemplateAccordionNested
              left={h.name}
              key={h.id}
              right={
                isOpen
                  ? null
                  : string.toSimpleDate(
                      userStartDate > h.userStartDate
                        ? userStartDate
                        : h.userStartDate,
                    )
              }
            >
              <Box
                className={cls.root}
                dangerouslySetInnerHTML={{
                  __html: h.body,
                }}
              />
              <Button
                color="secondary"
                onClick={handleNewJournal(h)}
                variant="contained"
              >
                {t('startWriting')}
              </Button>
            </ReadOnlyTemplateAccordionNested>
          ))
        ) : (
          <Box mb={1} px={2}>
            <Typography>
              {t('descriptions:noHomeworkAvailable')}
            </Typography>
          </Box>
        )}
      </ReadOnlyTemplateAccordion>
      <ReadOnlyTemplateAccordion title="uploads">
        <FileManager
          canEdit={false}
          canDelete={false}
          canCreate={false}
          {...defs}
          initialView="list"
        />
      </ReadOnlyTemplateAccordion>
      {size(calls) > 0 && (
        <ReadOnlyTemplateAccordion title="calls">
          {callInformation && (
            <Box mb={1} px={2}>
              <Typography
                style={{
                  whiteSpace: 'break-spaces',
                }}
              >
                {callInformation}
              </Typography>
            </Box>
          )}
          {map(orderBy(calls, ['start'], ['desc']), (c) =>
            c.type === 'Recurring' ? null : (
              <ReadOnlyTemplateAccordionNested
                left={
                  <>
                    <CoursesReadOnlyCallFile
                      name={c.callFilePath}
                    />
                    {c.name}
                  </>
                }
                key={c.id}
                right={string.toDate(c.start)}
              >
                <Typography>
                  {c.description ||
                    t('descriptions:noDescription')}{' '}
                </Typography>
              </ReadOnlyTemplateAccordionNested>
            ),
          )}
        </ReadOnlyTemplateAccordion>
      )}
      <ReadOnlyTemplateAccordion title="people">
        <List
          component="div"
          style={{
            margin: 0,
            maxHeight: 320,
            overflow: 'scroll',
            padding: 0,
            paddingLeft: '.5rem',
          }}
        >
          {map(makePeople(), (c) => (
            <ListItem
              key={c.id}
              button
              component={Link}
              to={`/app/journals?createdBy=string(${c.id})`}
            >
              <ListItemText
                primary={c.name}
                secondary={c.role}
              />
            </ListItem>
          ))}
        </List>
      </ReadOnlyTemplateAccordion>
    </ReadOnlyTemplate>
  );
};

CoursesReadOnly.defaultProps = {
  data: {
    calls: [],
    callInformation: undefined,
    coaches: [],
    description: undefined,
    userEndDate: null,
    homework: [],
    participants: [],
    userStartDate: null,
    type: 'Open',
  },
};

CoursesReadOnly.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    calls: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        dayOfWeek: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
    callInformation: PropTypes.string,
    coaches: PropTypes.arrayOf(
      PropTypes.shape({
        coach: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      }),
    ),
    description: PropTypes.string,
    homework: PropTypes.arrayOf(
      PropTypes.shape({
        userStartDate: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    participants: PropTypes.arrayOf(
      PropTypes.shape({
        participant: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        }),
      }),
    ),
    photo: PropTypes.string,
    type: PropTypes.string,
    userEndDate: PropTypes.string,
    userStartDate: PropTypes.string,
  }),
};

export default connect(
  // intentionally wrapped twice
  withNotificationsAnalytics(
    withNotificationsAnalytics(CoursesReadOnly, (data) => [
      ...map(data?.calls, 'id'),
      ...map(data?.coaches, 'id'),
      ...map(data?.homework, 'id'),
      ...map(data?.participants, 'id'),
    ]),
  ),
);
