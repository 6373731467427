import StorefrontIcon from '@material-ui/icons/Storefront';
import AbstractCollectionBuilder from 'q3-admin/lib/builders';
import { string } from 'q3-ui-helpers';
import Add from '../ProductsAdd';
import General from '../ProductsGeneral';
import PriceIncentives from '../ProductsPriceIncentives';
import Discounts from '../ProductsDiscounts';
import Subscription from '../ProductsSubscription';
import Links from '../ProductsLinks';
import Trial from '../ProductsTrial';

const resolvers = (xs) => ({
  ...xs,
  name: xs.title,
  setup: string.toPrice(xs?.setupFee),
  subscription: string.toPrice(xs?.subscription?.value),
});

export const protectView = (l, data) =>
  !(
    data?.type !== 'Course Purchase' &&
    l === 'priceincentives'
  );

export default new AbstractCollectionBuilder({
  collectionName: 'products',
  resourceName: 'products',
  resourceNameSingular: 'product',
  icon: StorefrontIcon,
  disableSegmentAll: true,
  parent: 'tools',
  resolvers,
})
  .genCreate(Add)
  .genHeader({
    titleProp: 'title',
  })
  .genViews({
    General,
    Trial,
    Subscription,
    Discounts,
    PriceIncentives,
    Links,
  })
  .genList({
    disableAvatar: true,
    defaultColumns: [
      'currency',
      'setup',
      'subscription',
      'type',
      'course.title',
    ],
    virtuals: ['setup', 'subscription'],
  })
  .genDetail({
    autoOpenSummary: false,
    protectView,
  })
  .genListSettings({
    aliasForName: 'title',
    defaultSortPreference: 'title',
  })
  .build();
