import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'q3-admin';
import { Builders } from 'q3-ui-forms';
import { castToBoolean } from 'q3-ui-forms/lib/helpers';
// eslint-disable-next-line
import { Adapters } from 'q3-ui-rte';

export const ArticlesGeneral = ({ data, ...props }) => (
  <Builders.Form
    {...props}
    initialValues={data}
    keep={['title', 'body', 'showCoaches', 'showMembers']}
    marshalSelectively
    marshal={{
      showCoaches: [castToBoolean],
      showMembers: [castToBoolean],
    }}
  >
    <Builders.Field
      name="title"
      type="text"
      required
      xl={12}
      lg={12}
    />
    <Builders.Field
      name="body"
      type="editor"
      upload={Adapters.toQ3Api(`/articles/${data?.id}`)}
      rows={5}
      xl={12}
      lg={12}
    />
    <Builders.Field
      name="showCoaches"
      type="checkbox"
      variant="switch"
      xl={12}
      lg={12}
    />
    <Builders.Field
      name="showMembers"
      type="checkbox"
      variant="switch"
      xl={12}
      lg={12}
    />
  </Builders.Form>
);

ArticlesGeneral.defaultProps = {
  data: {},
};

ArticlesGeneral.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    body: PropTypes.string,
  }),
};

export default connect(ArticlesGeneral);
