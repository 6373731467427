import React from 'react';
import { browser, object } from 'q3-ui-helpers';
import axios from 'axios';
import { Store } from 'q3-admin/lib/containers';

export const getLatestContentFromEditor = (
  defaultValue = '',
) => {
  if (browser.isBrowserReady()) {
    const editor = document.getElementById('q3-editor');

    if (editor) {
      const html = editor.querySelector('.ql-editor');

      if (html) {
        return html.innerHTML;
      }
    }
  }

  return defaultValue;
};

const useJournalAutosave = () => {
  const { data = {} } = React.useContext(Store);
  const { body, id, publishedOn, updatedAt } = data;
  const [state, setState] = React.useState();

  const postToApi = () =>
    object.noop(
      axios
        .post('/autosave', {
          journal: id,
          body: getLatestContentFromEditor(body),
        })
        .then((res) => {
          const d = res?.data?.updatedAt;
          if (d) {
            setState(d);
          }
        }),
    );

  React.useEffect(() => {
    setState(updatedAt);
  }, [updatedAt]);

  React.useLayoutEffect(() => {
    let timer;

    const delay = (fn) => {
      timer = setTimeout(fn, 60000);
    };

    const recurse = () =>
      !publishedOn
        ? // allows longer requests to finish
          // instead of piling up
          postToApi().finally(() => {
            delay(recurse);
          })
        : Promise.resolve();

    delay(recurse);

    return () => {
      if (timer) {
        window.clearTimeout(timer);
      }
    };
  }, [publishedOn]);

  return state;
};

export default useJournalAutosave;
