import React from 'react';
import axios from 'axios';
import { navigate } from '@reach/router';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AbstractCollectionBuilder from 'q3-admin/lib/builders';
import SchoolIcon from '@material-ui/icons/School';
import { AuthContext } from 'q3-ui-permissions';
import { object, string } from 'q3-ui-helpers';
import EjectIcon from '@material-ui/icons/Eject';
import BlockIcon from '@material-ui/icons/Block';
import PublishIcon from '@material-ui/icons/Publish';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import moment from 'moment';
import { get, map } from 'lodash';
import withNotificationsAnalytics from 'q3-admin/lib/helpers/withNotificationsAnalytics';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Add from '../CoursesAdd';
import General from '../CoursesGeneral';
import Participants from '../CoursesParticipants';
import Calls from '../CoursesCalls';
import Filter from '../CoursesFilter';
import ReadOnly from '../CoursesReadOnly';
import RegistrationLink from '../CoursesRegistrationLink';
import Homework from '../TemplatesCourseHomework';
import Coaches from '../CoursesCoaches';
import Settings from '../TemplatesSettings';
import useCourseContent from '../useCourseContent';
import { isAdmin } from '../utils';
import PublishButton from '../PublishButton';
import JournalsLink from '../JournalsLink';
import CoursesFiles from '../CoursesFiles';

import {
  ACTIVE,
  COMPLETED,
  DRAFT,
  RECENTLY_DELETED,
  SCHEDULED,
  SUSPENDED,
} from './constants';

export const defineActionProps = (state, user) => ({
  uploads:
    !state?.enableFileManagement && !isAdmin(user?.role)
      ? {
          canEdit: false,
          canDelete: false,
          canCreate: false,
        }
      : {},
});

export const registerActions = (
  {
    id,
    title,
    enableState = false,
    published = false,
    suspended = false,
    trashedOn = null,
  },
  { patch },
  t,
) => {
  const profile =
    React.useContext(AuthContext)?.state?.profile;
  const allocation = get(profile, 'allocation', 0);
  const role = get(profile, 'role', 'Member');
  const output = [];

  const handleClone = () =>
    axios
      .post(`/courses/${id}/clone`)
      .then((res) => {
        navigate(`/app/courses/${res?.data?.course?.id}`);
      })
      .catch(() => {
        // noop
      });

  // needs to always been in the last position
  const getOutput = () =>
    output.concat({
      label: 'viewAllJournals',
      icon: AssignmentIcon,
      onClick: () =>
        navigate(
          `/app/journals?course.title=string(${encodeURIComponent(
            title,
          )})`,
        ),
    });

  const update = (args = {}) => object.noop(patch()(args));

  if (!isAdmin(role) && !(role === 'Coach' && enableState))
    return getOutput();

  // not available to coaches yet
  if (isAdmin(role) || allocation > 0)
    output.push({
      label: t('labels:clone'),
      icon: FileCopyIcon,
      onClick: handleClone,
    });

  if (published) {
    output.push({
      label: t('labels:unpublish'),
      icon: EjectIcon,
      onClick: () =>
        update({
          suspended: false,
          published: false,
        }),
    });

    if (suspended) {
      output.push({
        label: t('labels:unsuspend'),
        icon: PlayArrowIcon,
        onClick: () =>
          update({
            suspended: false,
          }),
      });
    } else {
      output.push({
        label: t('labels:suspend'),
        icon: BlockIcon,
        onClick: () =>
          update({
            suspended: true,
          }),
      });
    }
  } else {
    output.push({
      label: t('labels:publish'),
      icon: PublishIcon,
      onClick: () =>
        update({
          published: true,
          suspended: false,
        }),
    });
  }

  if (trashedOn)
    output.push({
      label: 'untrash',
      icon: SettingsBackupRestoreIcon,
      onClick: () =>
        update({
          trashedOn: null,
        }),
    });

  return getOutput();
};

export const registerAlerts = (data, user, t) => {
  const out = [];

  if (user?.role === 'Member') return out;

  if (data.suspended)
    out.push({
      id: 'suspended',
      severity: 'warning',
      title: t('titles:suspended'),
      description: t('descriptions:suspendedCourse'),
    });
  else if (data.published) {
    out.push({
      id: 'published',
      severity: 'info',
      title: t('titles:published'),
      description: t('descriptions:publishedCourse'),
    });

    out.push({
      id: 'journals',
      severity: 'info',
      title: t('titles:journals'),
      description: t('descriptions:journalsInCourse'),
      action: (
        <JournalsLink
          param="course.title"
          value={`string(${data.title})`}
        />
      ),
    });
  }

  if (data.trashedOn)
    out.push({
      id: 'trashed',
      severity: 'error',
      title: t('titles:hasBeenTrashed'),
      description: t('descriptions:hasBeenTrashed'),
    });
  else if (!data.published)
    out.push({
      id: 'draft',
      severity: 'warning',
      title: t('titles:isDraft'),
      description: t('descriptions:isDraft'),
      action:
        data.enableState ||
        String(user?.role).includes('Admin') ? (
          <PublishButton />
        ) : null,
    });

  return out;
};

export const protectView = (l, data, user = {}) => {
  const r = user?.role;
  const t = data?.type;

  const shouldHideAllTabs = () =>
    l !== 'general' && r === 'Member';

  const shouldHideRegistrationLink = () =>
    l === 'registrationlink' &&
    (!data.published ||
      (!isAdmin(r) && !data.enableParticipants));

  const shouldHideCallManager = () =>
    (t === 'Self-Guided' ||
      (!isAdmin(r) && !data.enableCall)) &&
    l === 'calls';

  const shouldHideAdminTabs = () =>
    !isAdmin(r) && ['coaches', 'settings'].includes(l);

  return !(
    shouldHideAllTabs() ||
    shouldHideRegistrationLink() ||
    shouldHideCallManager() ||
    shouldHideAdminTabs()
  );
};

export const getEndDate = (xs) => xs.end || xs.userEndDate;

export const getSegmentName = (xs) => {
  if (xs.trashedOn) return RECENTLY_DELETED;
  if (xs.suspended) return SUSPENDED;
  if (!xs.published) return DRAFT;
  if (
    xs.published &&
    moment(getEndDate(xs)).isBefore(new Date())
  )
    return COMPLETED;

  if (
    xs.published &&
    moment(xs.userStartDate).isAfter(new Date())
  )
    return SCHEDULED;

  if (xs.published) return ACTIVE;
  return null;
};

export const resolvers = (xs) => ({
  ...xs,
  name: xs.title,
  description: '',
  segment: getSegmentName(xs),
  journals: (
    <JournalsLink
      param="course.title"
      value={`string(${xs.title})`}
    />
  ),
  coaches: map(xs.coaches, 'coach')
    .map(string.makeName)
    .sort()
    .join(', '),
  start: {
    base: xs.start,
    toDate: true,
  },
  end: {
    base: xs.end,
    toDate: true,
  },
  createdAt: {
    base: xs.createdAt,
    toDate: true,
  },
});

export const registerOptions = (data) => [
  {
    title: 'type',
    description: data.type,
    href: `/app/courses?type=string(${encodeURIComponent(
      data.type,
    )})`,
  },
  {
    title: 'category',
    ...(data?.category?.name
      ? {
          description: data.category.name,
          href: `/app/courses?category.name=string(${encodeURIComponent(
            data.category.name,
          )})`,
        }
      : {
          description: 'Unassigned',
        }),
  },
];

export const renderContent = (El, _, forwardedProps) =>
  useCourseContent(forwardedProps) ? El : <ReadOnly />;

export const withSubDocumentIdMap = (Component, subdoc) =>
  withNotificationsAnalytics(Component, (data) =>
    map(get(data, subdoc, []), 'id'),
  );

export default new AbstractCollectionBuilder({
  collectionName: 'courses',
  resourceName: 'courses',
  resourceNameSingular: 'course',
  icon: SchoolIcon,
  disableSegmentAll: true,
  resolvers,
})
  .genFilter(Filter)
  .genCreate(Add)
  .genHeader({
    titleProp: 'title',
  })
  .genViews({
    General: withNotificationsAnalytics(General),
    Homework: withSubDocumentIdMap(Homework, 'homework'),
    Coaches: withSubDocumentIdMap(Coaches, 'coaches'),
    Participants: withSubDocumentIdMap(
      Participants,
      'participants',
    ),
    Calls: withSubDocumentIdMap(Calls, 'calls'),
    Files: CoursesFiles,
    RegistrationLink,
    Settings,
  })
  .genList({
    defaultColumns: [
      'journals',
      'numberOfJournals',
      'category.name',
      'segment',
      'type',
      'start',
      'end',
      'daysLeft',
      'numberOfCoaches',
      'coaches',
      'numberOfHomework',
      'numberOfDays',
      'numberOfParticipants',
      'createdAt',
    ],
    virtuals: ['segment', 'journals'],
  })
  .genDetail({
    protectView,
    defineActionProps,
    registerActions,
    registerAlerts,
    renderContent,
    registerOptions,
  })
  .genListSettings({
    defaultSortPreference: 'title',
  })
  .build();
