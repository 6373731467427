import AbstractCollectionBuilder from 'q3-admin/lib/builders';
import WidgetsIcon from '@material-ui/icons/Widgets';
import { every, size } from 'lodash';
import PersonIcon from '@material-ui/icons/Person';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {
  blue,
  green,
  orange,
  purple,
} from '@material-ui/core/colors';
import { string } from 'q3-ui-helpers';
import Add from '../TemplatesAdd';
import General from '../TemplatesGeneral';
import CourseHomework from '../TemplatesCourseHomework';
import Coaches from '../TemplatesCoaches';
import Settings from '../TemplatesSettings';

// eslint-disable-next-line
export const getTemplateIcon = ({ type }) => {
  let icon = AssignmentIcon;
  let color = blue;

  if (type === 'Open') {
    icon = GroupWorkIcon;
    color = purple;
  }
  if (type === 'Scheduled') {
    icon = PermContactCalendarIcon;
    color = green;
  }
  if (type === 'Self-Guided') {
    icon = PersonIcon;
    color = orange;
  }

  return {
    iconBg: color[900],
    icon,
  };
};

export const blacklistColumns = (xs) => {
  if (every(xs, (item) => item.__t === 'homework'))
    return [
      'category.name',
      'numberOfCoaches',
      'numberOfHomework',
      'numberOfDays',
    ];

  return [];
};

const resolvers = (xs) => ({
  ...xs,
  ...getTemplateIcon(xs),
  createdByName: string.makeName(xs.createdBy),
  updatedAt: {
    base: xs.updatedAt,
    toDate: true,
  },
});

export const protectView = (l, { __t: type }) =>
  !(
    ['coaches', 'coursehomework', 'settings'].includes(l) &&
    type !== 'course'
  );

export default new AbstractCollectionBuilder({
  collectionName: 'templates',
  resourceName: 'templates',
  resourceNameSingular: 'template',
  icon: WidgetsIcon,
  disableSegmentAll: true,
  parent: 'tools',
  resolvers,
})
  .genCreate(Add)
  .genHeader({
    titleProp: 'name',
  })
  .genViews({
    General,
    CourseHomework,
    Coaches,
    Settings,
  })
  .genList({
    blacklistColumns,
    defaultColumns: [
      'type',
      'category.name',
      'numberOfCoaches',
      'numberOfHomework',
      'numberOfDays',
      'createdByName',
      'updatedAt',
    ],
    virtuals: ['createdByName'],
  })
  .genDetail({
    protectView,
    registerOptions: ({
      coaches = [],
      numberOfHomework = 0,
      numberOfDays = 0,
      type,
      __t,
    }) =>
      __t === 'homework'
        ? [
            {
              title: 'templateType',
              description: __t,
            },
          ]
        : [
            {
              title: 'templateType',
              description: __t,
            },
            {
              title: 'type',
              description: type,
              href: `/app/templates?type=string(${encodeURIComponent(
                type,
              )})`,
            },
            {
              title: 'numberOfCoaches',
              description: String(size(coaches)),
            },
            {
              title: 'numberOfDays',
              description: String(numberOfDays),
            },
            {
              title: 'numberOfHomework',
              description: String(numberOfHomework),
            },
          ],
  })
  .genListSettings({
    defaultSortPreference: 'name',
    select:
      'title,category,type,numberOfParticipants,numberOfCoaches,numberOfDays,numberOfHomework,__t,name,createdBy,updatedAt',
  })
  .build();
