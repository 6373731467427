import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'q3-admin';
import { Builders } from 'q3-ui-forms';
import { Adapters } from 'q3-ui-rte';
import { getSafelyForAutoComplete } from 'q3-ui-rest';

export const TemplatesGeneral = ({ data, ...props }) =>
  data.__t === 'homework' ? (
    <Builders.Form
      {...props}
      initialValues={data}
      keep={['name', 'category', 'body', 'type', '__t']}
      translate={{
        'category.label': 'category.name',
        'category.value': 'category.ref',
      }}
      marshal={{
        '__t': '__t',
        'body': 'body',
        'category.ref': 'category.value',
        'name': 'name',
        'type': 'type',
      }}
    >
      <Builders.Field
        name="name"
        type="text"
        required
        xl={12}
        lg={12}
      />
      <Builders.Field
        name="type"
        type="select"
        xl={12}
        lg={12}
        conditional={['__t=homework']}
        options={['Assignment', 'Prompt']}
      />
      <Builders.Field
        name="category"
        type="autocomplete"
        loadOptions={getSafelyForAutoComplete(
          '/categories',
          'categories',
          'name',
        )}
        xl={12}
        lg={12}
      />
      <Builders.Field
        name="body"
        type="editor"
        upload={
          data
            ? Adapters.toQ3Api(`/templates/${data.id}`)
            : undefined
        }
        xl={12}
        lg={12}
      />
    </Builders.Form>
  ) : (
    <Builders.Form
      {...props}
      initialValues={data}
      keep={['name', 'category', 'description', '__t']}
      translate={{
        'category.label': 'category.name',
        'category.value': 'category.ref',
      }}
      marshal={{
        '__t': '__t',
        'description': 'description',
        'category.ref': 'category.value',
        'name': 'name',
      }}
    >
      <Builders.Field
        name="name"
        type="text"
        required
        xl={12}
        lg={12}
      />
      <Builders.Field
        name="category"
        type="autocomplete"
        loadOptions={getSafelyForAutoComplete(
          '/categories',
          'categories',
          'name',
        )}
        xl={12}
        lg={12}
      />
      <Builders.Field
        name="description"
        type="text"
        multiline
        rows={5}
        xl={12}
        lg={12}
      />
    </Builders.Form>
  );

TemplatesGeneral.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    __t: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(TemplatesGeneral);
