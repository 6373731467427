import React from 'react';
import {
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'q3-ui-locale';
import { map } from 'lodash';
import PublishIcon from '@material-ui/icons/Publish';
import useJournalPublishOptions from '../useJournalPublishOptions';
import JournalActionsPagination from '../JournalActionsPagination';

const JournalActionsPrivacySelect = () => {
  const { loading, options, selected, setSelected } =
    useJournalPublishOptions();
  const { t } = useTranslation('labels');

  const changeSelected = (v) => {
    if (v && v !== 'null') setSelected(v);
  };

  const changeSelectedIndex = (_, nextValue) =>
    changeSelected(nextValue);

  return (
    <>
      <Typography>
        {t('descriptions:pickPublishVisibility')}
      </Typography>
      <Box mb={2}>
        {!selected || loading ? (
          <CircularProgress />
        ) : (
          <RadioGroup
            aria-label="visibility"
            onChange={changeSelectedIndex}
            value={selected}
          >
            {map(options, (item) => (
              <FormControlLabel
                control={<Radio />}
                key={item.label}
                label={t(item.label)}
                value={item.value}
              />
            ))}
          </RadioGroup>
        )}
      </Box>
      <JournalActionsPagination
        disableNext={!selected}
        backState={{
          publishedStateLabel: null,
          publishedState: null,
        }}
        nextIcon={<PublishIcon />}
        nextLabel="publish"
        nextState={{
          publishedState: selected,
        }}
      />
    </>
  );
};

export default JournalActionsPrivacySelect;
