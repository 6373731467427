import React from 'react';
import { Builders } from 'q3-ui-forms';
import { connect } from 'q3-admin/lib/containers';
import SubDetail from 'q3-admin/lib/containers/subDetail';
import { useTranslation } from 'q3-ui-locale';
import { Box, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const ProductsDiscounts = () => {
  const { t } = useTranslation();
  return (
    <SubDetail
      root="discounts"
      th="code"
      name="discounts"
      cardProps={{
        title: 'code',
        describe({ value, unit }) {
          return unit === 'Percent'
            ? `${value}%`
            : `$${value}`;
        },
      }}
      initialValues={{
        unit: 'Dollar',
        value: 1,
      }}
    >
      <Builders.Form>
        <Grid item xs={12}>
          <Box mb={1}>
            <Alert severity="info">
              {t('descriptions:howDiscountsWork')}
            </Alert>
          </Box>
        </Grid>
        <Builders.Field
          name="code"
          type="text"
          required
          xl={12}
          lg={12}
        />
        <Builders.Field
          name="unit"
          type="select"
          options={['Dollar', 'Percent']}
          required
          xl={12}
          lg={12}
        />
        <Builders.Field
          name="value"
          type="number"
          min="1"
          xl={12}
          lg={12}
        />
      </Builders.Form>
    </SubDetail>
  );
};

ProductsDiscounts.propTypes = {};

export default connect(ProductsDiscounts);
