import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
  },
  selectAll: {
    position: 'relative',
    marginTop: '-5.5rem',
    marginBottom: '4rem',
    justifyContent: 'flex-end',

    [theme.breakpoints.down('md')]: {
      marginTop: '-5rem',
      paddingRight: '0.5rem',
    },
  },
}));
