import React from 'react';
import { Builders } from 'q3-ui-forms';
import AddNewForm from 'q3-admin/lib/components/AddNewForm';

const DomainAdd = (props) => (
  <AddNewForm
    initialValues={{
      supportedLngs: ['en'],
    }}
    {...props}
  >
    <Builders.Field
      name="tenant"
      type="text"
      required
      xl={12}
      lg={12}
    />
    <Builders.Field
      name="email"
      type="email"
      helperText="Invites the first administrator to the app"
      required
      xl={12}
      lg={12}
    />
  </AddNewForm>
);

DomainAdd.propTypes = {};

export default DomainAdd;
