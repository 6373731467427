import React from 'react';
import { Builders } from 'q3-ui-forms';
import { AuthContext } from 'q3-ui-permissions';
import AddNewForm from 'q3-admin/lib/components/AddNewForm';
import useCourseTemplateOptions from '../useCourseTemplateOptions';

const TemplatesAdd = (props) => {
  const isCoach =
    React.useContext(AuthContext)?.state?.profile?.role ===
    'Coach';

  const options = useCourseTemplateOptions();

  return (
    <AddNewForm
      initialValues={{
        __t: isCoach ? 'homework' : undefined,
      }}
      marshal={(values) => {
        values.type = cleanTemplateTypes(values);
        return values;
      }}
      marshalSelectively
      {...props}
    >
      <Builders.Field
        name="name"
        type="text"
        required
        xl={12}
        lg={12}
      />
      {!isCoach && (
        <Builders.Field
          name="__t"
          type="select"
          options={['course', 'homework']}
          xl={12}
          lg={12}
        />
      )}
      <Builders.Field
        name="type"
        type="select"
        label="Subtype"
        xl={12}
        lg={12}
        runOnChange={['__t']}
        conditional={['__t=course,homework']}
        loadOptions={(_, context) => {
          return Promise.resolve(
            context.__t === 'course'
              ? options
              : ['Assignment', 'Prompt'],
          );
        }}
      />
    </AddNewForm>
  );

  function cleanTemplateTypes({ __t, type }) {
    if (
      (__t === 'course' &&
        ['Open', 'Scheduled', 'Self-Guided'].includes(
          type,
        )) ||
      (__t === 'homework' &&
        ['Assignment', 'Prompt'].includes(type))
    ) {
      return type;
    }

    return undefined;
  }
};

TemplatesAdd.propTypes = {};

export default TemplatesAdd;
