import React from 'react';
import moment from 'moment';
import { Context } from 'q3-ui-forms';
import { getSafelyForAutoCompleteWithProjection } from 'q3-ui-rest';
import {
  get,
  find,
  filter,
  size,
  reduce,
  first,
  map,
  uniq,
  orderBy,
} from 'lodash';
import axios from 'axios';

const useJournalCourseOptions = (
  selectDefaultPrompt = true,
) => {
  const cache = React.useRef();
  const [groups, setGroups] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const { values } = React.useContext(Context.BuilderState);
  const dispatch = React.useContext(
    Context.DispatcherState,
  );

  const today = moment().endOf('day');
  const defaultPrompt = {
    body: '',
    label: 'No prompt',
    value: '',
  };

  const getDate = (xs) => xs.userStartDate || xs.date;

  const getOptionProp = (prop) =>
    get(
      find(
        options,
        (option) => option.value === values?.promptId,
      ),
      prop,
    );

  const getMostRecentStartDate = () => {
    // otherwise no prompt wins
    const filteredOptions = filter(options, 'value');

    const numberOfStartDates = size(filteredOptions);
    const numberOfUniqueStartDates = size(
      uniq(map(filteredOptions, 'userStartDate')),
    );

    if (
      // typically indicates open course
      numberOfUniqueStartDates === 1 &&
      numberOfStartDates > numberOfUniqueStartDates
    )
      return null;

    if (numberOfUniqueStartDates === 1)
      return first(filteredOptions);

    return reduce(
      orderBy(
        filteredOptions,
        ['userStartDate', 'date'],
        ['asc', 'asc'],
      ),
      (acc, curr) =>
        moment(getDate(curr)).isSameOrBefore(today)
          ? curr
          : acc,
      null,
    );
  };

  const extractGroupsFromPrompts = (prompts = []) => {
    const nextGroups = reduce(
      prompts,
      (acc, curr) => {
        const cat = get(curr, 'category.name');
        if (cat && !acc.includes(cat)) {
          acc.push(cat);
        }

        return acc;
      },
      [],
    ).sort();

    setGroups(nextGroups);
    return nextGroups;
  };

  const getCourse = (courseId) => {
    const cached = get(cache, `current.${courseId}`);

    if (cached) {
      return Promise.resolve(cached);
    }

    return axios
      .get(`/courses/${courseId}`)
      .then((apiResposne) => {
        const course = get(apiResposne, 'data.course');

        if (!course) {
          throw new Error(`No course found`);
        }

        if (!cache.current) {
          cache.current = {};
        }

        cache.current[courseId] = course;
        return course;
      });
  };

  React.useEffect(() => {
    const match = getOptionProp('name');
    if (match) dispatch.setFieldValue('prompt', match);
  }, [values?.promptId]);

  React.useEffect(() => {
    if (!values?.course) {
      dispatch.setFieldValue('promptId', '');
      dispatch.setFieldValue('prompt', '');
    }

    // note:
    // always reset dropdown between courses
    setGroups([]);
  }, [values?.course]);

  React.useEffect(() => {
    if (size(options) && selectDefaultPrompt) {
      const defaultOption = getMostRecentStartDate();

      if (defaultOption)
        dispatch.setFieldValue(
          'promptId',
          defaultOption.value,
        );
    }
  }, [options]);

  return {
    // most commonly referenced
    // function only exposed for testing really
    body: getOptionProp('body'),
    group: get(values, 'promptCategory'),
    groups,
    getOptionProp,

    load: (e, { course: id, promptCategory, promptId }) => {
      const hasCourseSelected =
        id &&
        !['undefined', 'null', ''].includes(String(id));

      if (!hasCourseSelected) {
        return Promise.resolve([defaultPrompt]);
      }

      return getCourse(id)
        .then((course) => {
          const filtered = filter(course.homework, (item) =>
            today.isSameOrAfter(getDate(item)),
          ).map((item) => ({
            ...item,
            category: item?.category || {
              name: 'Other',
            },
            value: item.id,
            label: item.name,
          }));

          filtered.unshift(defaultPrompt);
          setOptions(filtered);

          if (!course.groupHomework) {
            return filtered;
          }

          const availableGroups =
            extractGroupsFromPrompts(filtered);

          const availableGroup = availableGroups.includes(
            promptCategory,
          )
            ? promptCategory
            : get(
                find(
                  filtered,
                  (item) => item.value === promptId,
                ),
                'category.name',
                availableGroups[0],
              );

          if (availableGroup !== promptCategory) {
            dispatch.setFieldValue(
              'promptCategory',
              availableGroup,
            );

            return [];
          }

          return filter(filtered, (item) => {
            const cat = get(item, 'category.name');
            return !cat || cat === availableGroup;
          });
        })
        .catch((e) => {
          return [];
        });
    },
  };
};

export default useJournalCourseOptions;
