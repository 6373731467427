import React from 'react';
import AbstractCollectionBuilder from 'q3-admin/lib/builders';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ArticlesCollection from '../ArticlesCollection';
import ArticlesAdd from '../ArticlesAdd';
import ArticlesGeneral from '../ArticlesGeneral';
import ArticlesReadOnly from '../ArticlesReadOnly';
import useArticleContent from '../useArticleContent';

export const resolvers = (xs) => ({
  ...xs,
  name: xs.title,
});

export const ui = () => 'div';
export const renderContent = (El, _, forwardedProps) =>
  useArticleContent() ? (
    El
  ) : (
    <ArticlesReadOnly {...forwardedProps} />
  );

export default new AbstractCollectionBuilder({
  collectionName: 'articles',
  resourceNameSingular: 'article',
  resourceName: 'articles',
  parent: 'tools',
  icon: LiveHelpIcon,
  resolvers,
})
  .genCreate(ArticlesAdd)
  .genHeader({
    titleProp: 'title',
  })
  .genViews({
    General: ArticlesGeneral,
  })
  .genList({
    ui: { label: 'gallery', component: ArticlesCollection },
    runOnInit: true,
  })
  .genDetail({
    renderContent,
  })
  .genListSettings({
    defaultSortPreference: 'title',
    select: 'title,photo,updatedAt,featuredUpload',
  })
  .build();
