import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Widget from 'q3-admin/lib/components/Widget';
import useStyle from './styles';

const DashboardWidget = ({ children, title }) => {
  const cls = useStyle();

  return (
    <Box
      bgcolor="background.paper"
      className={cls.paper}
      p={1}
    >
      <Widget expandable={false} title={title}>
        {children}
      </Widget>
    </Box>
  );
};

DashboardWidget.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

export default DashboardWidget;
