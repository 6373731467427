import React from 'react';
import { Builders } from 'q3-ui-forms';
import { connect } from 'q3-admin/lib/containers';
import { castToBoolean } from 'q3-ui-forms/lib/helpers';
import { get } from 'lodash';
import { INTERVALS } from '../ProductsSubscription/ProductsSubscription';

// eslint-disable-next-line
const ProductsTrial = ({ data, ...props }) => (
  <Builders.Form
    {...props}
    keep={['trial']}
    initialValues={data}
    marshalSelectively
    marshal={{
      'trial.enable': [
        (v, values) =>
          castToBoolean(get(values, 'trial.enable')),
      ],
    }}
  >
    <Builders.Field
      name="trial.enable"
      type="checkbox"
      variant="switch"
      required
      xl={12}
      lg={12}
    />
    <Builders.Field
      conditional={['trial.enable=true']}
      min="1"
      name="trial.interval"
      type="number"
      required
      xl={6}
      lg={6}
    />
    <Builders.Field
      conditional={['trial.enable=true']}
      name="trial.intervalUnit"
      type="select"
      options={INTERVALS}
      required
      xl={6}
      lg={6}
    />
  </Builders.Form>
);

ProductsTrial.propTypes = {};

export default connect(ProductsTrial);
