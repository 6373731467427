import React from 'react';
import { useAuth } from 'q3-ui-permissions';
import { Store } from 'q3-admin/lib/containers';

const useCourseContent = () => {
  const auth = useAuth('courses');
  const { data } = React.useContext(Store);
  const isDisabled =
    auth?.state?.profile?.role === 'Coach' &&
    !data?.enableChanges &&
    !data?.enableHomework &&
    !data?.enableCall &&
    !data?.enableParticipants;

  return auth.canEdit && !isDisabled;
};

export default useCourseContent;
