import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  cta: {
    position: 'relative',
    zIndex: 1,
    marginTop: '-3.5rem',
    textAlign: 'right',

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      textAlign: 'left',

      '& button': {
        width: '100%',
      },
    },
  },
}));
