import React from 'react';
import PropTypes from 'prop-types';
import { Builders } from 'q3-ui-forms';
import { Alert } from '@material-ui/lab';
import { Box, Button, Tooltip } from '@material-ui/core';
import Dialog from 'q3-ui-dialog';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { useTranslation } from 'q3-ui-locale';

const FREQUENCY_OPTIONS = [
  {
    label: 'days',
    value: 'd',
  },
  {
    label: 'weeks',
    value: 'w',
  },
  {
    label: 'months',
    value: 'm',
  },
  {
    label: 'years',
    value: 'y',
  },
];

export const FrequencyDialogForm = ({
  initialValues,
  onSubmit,
  showManualFrequencyToggle,
  ...rest
}) => {
  const { t } = useTranslation();
  const { amount, unit = 'd' } = initialValues || {};
  const handleSubmit = (frequency) =>
    onSubmit({
      frequency,
    });

  return (
    <Box>
      <Box>
        <Box mb={2}>
          <Alert severity="info">
            {t('descriptions:manualFrequency')}
          </Alert>
        </Box>
        <Builders.Form
          {...rest}
          initialValues={{
            amount,
            unit,
          }}
          marshal={{
            amount: 'amount',
            unit: 'unit',
            manual: [() => false],
          }}
          onSubmit={handleSubmit}
        >
          <Builders.Field
            required
            under="frequency"
            name="amount"
            type="number"
            xl={12}
            lg={12}
          />
          <Builders.Field
            required
            under="frequency"
            name="unit"
            type="select"
            options={FREQUENCY_OPTIONS}
            xl={12}
            lg={12}
          />
        </Builders.Form>
      </Box>
    </Box>
  );
};

FrequencyDialogForm.defaultProps = {
  showManualFrequencyToggle: false,
  initialValues: {
    amount: undefined,
    unit: 'd',
  },
};

FrequencyDialogForm.propTypes = {
  initialValues: PropTypes.shape({
    amount: PropTypes.number,
    unit: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  showManualFrequencyToggle: PropTypes.bool,
};

const FrequencyDialog = ({ initialValues, ...rest }) => {
  const { t } = useTranslation();
  const { amount, unit = 'd' } = initialValues || {};

  const renderText = React.useCallback(
    () =>
      `${amount} ${t(
        `labels:${
          FREQUENCY_OPTIONS.find(
            ({ value }) => value === unit,
          )?.label
        }`,
      )}`,
    [initialValues],
  );

  return (
    <Dialog
      renderContent={() => (
        <FrequencyDialogForm
          {...rest}
          initialValues={initialValues}
        />
      )}
      renderTrigger={(onClick) => (
        <Tooltip arrow title={t('labels:frequency')}>
          <Button
            startIcon={<ScheduleIcon />}
            onClick={onClick}
          >
            {renderText()}
          </Button>
        </Tooltip>
      )}
      title="frequency"
      variant="drawer"
    />
  );
};

FrequencyDialog.defaultProps = {
  initialValues: {
    amount: undefined,
    manual: false,
    unit: 'd',
  },
};

FrequencyDialog.propTypes = {
  initialValues: PropTypes.shape({
    amount: PropTypes.number,
    manual: PropTypes.bool,
    unit: PropTypes.string,
  }),
};

export default FrequencyDialog;
