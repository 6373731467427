import useProfileForm from 'q3-admin/lib/hooks/useProfileForm';
import ProfileChangeRoles from '../ProfileChangeRoles';
import ProfileCancelSubscription from '../ProfileCancelSubscription';
import ProfileDelete from '../ProfileDelete';
import ProfileLocaleOverride from '../ProfileLocaleOverride';
import { MEMBER } from '../useUserRoleOptions/useUserRoleOptions';

const useProfilePages = () => {
  const {
    initialValues: { originalRole, subscriptionId },
  } = useProfileForm();

  const out = [
    {
      component: ProfileLocaleOverride,
      path: 'locale-2',
      to: '/app/account/locale-2',
      text: 'accountLocaleBilling',
    },
  ];

  if (subscriptionId)
    out.push({
      component: ProfileCancelSubscription,
      path: 'subscription',
      to: '/app/account/subscription',
      text: 'accountSubscription',
    });

  if (originalRole && originalRole !== MEMBER)
    out.push({
      component: ProfileChangeRoles,
      path: 'role',
      to: '/app/account/role',
      text: 'accountRoleType',
    });

  out.push({
    component: ProfileDelete,
    path: 'delete',
    to: '/app/account/delete',
    text: 'accountDelete',
  });

  return out;
};

export default useProfilePages;
