import React from 'react';
import PropTypes from 'prop-types';
import { get, map, size, sortBy, includes } from 'lodash';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ListSubheader from '@material-ui/core/ListSubheader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'q3-ui-locale';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItem from '@material-ui/core/ListItem';
import { connect } from 'q3-admin';
import { array } from 'q3-ui-helpers';
import useStyle from './styles';
import useStateWithCallback from '../useStateWithCallback';
import useAttributesRest from '../useAttributesRest';

export const JournalsAttributes = ({ data, onSubmit }) => {
  const [state, setState] = useStateWithCallback(
    array.is(data?.attributes),
    (incomingAttributes) =>
      onSubmit({
        attributes: incomingAttributes,
      }),
  );

  const { t } = useTranslation('descriptions');
  const cls = useStyle();

  const {
    attributes,
    fetching,
    fetchingError,
    findByName,
  } = useAttributesRest();

  if (fetching) return <CircularProgress />;

  if (fetchingError)
    return (
      <Alert severity="error">
        {t('failedToFetchAttributes')}
      </Alert>
    );

  if (!size(attributes))
    return (
      <Alert severity="info">
        {t('noAttributesToDisplay')}
      </Alert>
    );

  return map(
    attributes,
    ({ enableMultiple, id, name, options = [] }) => {
      const record = findByName(state, name);
      const currentValue = get(record, 'label', []);
      const previousValue = array.is(state);

      const handleClick = (item) => () =>
        setState(() =>
          record
            ? previousValue.map((attribute) => {
                if (attribute.name === name) {
                  const pushIntoLabels = () => {
                    const labels = array.is(
                      attribute.label,
                    );

                    if (labels.includes(item))
                      return array.filterValue(
                        labels,
                        item,
                      );

                    return enableMultiple
                      ? labels.concat(item)
                      : [item];
                  };

                  return {
                    name: attribute.name,
                    label: pushIntoLabels(),
                  };
                }

                return attribute;
              })
            : previousValue.concat({
                label: [item],
                name,
              }),
        );

      return (
        <Box key={id} mb={1}>
          <List
            subheader={
              <ListSubheader
                className={cls.header}
                component="li"
                disableSticky
              >
                <strong>{name}</strong>
                <small>
                  <i>
                    {enableMultiple
                      ? t('labels:pickMany')
                      : t('labels:pickOne')}
                  </i>
                </small>
              </ListSubheader>
            }
          >
            {map(
              sortBy(options, 'seq'),
              ({ emoji, label }) => (
                <ListItem
                  button
                  component="li"
                  dense
                  key={label}
                  onClick={handleClick(label)}
                  selected={includes(currentValue, label)}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <span className={cls.bg}>
                        {emoji}
                      </span>
                      <Box
                        component="span"
                        position="relative"
                      >
                        {emoji}
                      </Box>
                    </Avatar>
                  </ListItemAvatar>
                  {label}
                </ListItem>
              ),
            )}
          </List>
        </Box>
      );
    },
  );
};

JournalsAttributes.defaultProps = {
  data: { attributes: [] },
};

JournalsAttributes.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line
    attributes: PropTypes.arrayOf(PropTypes.object),
  }),
  onSubmit: PropTypes.func.isRequired,
};

export default connect(JournalsAttributes);
