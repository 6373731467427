import React from 'react';
import { FileManager } from 'q3-ui-filemanager';
import { Definitions } from 'q3-admin/lib/containers/state';
import { connect } from 'q3-admin';
import { AuthContext } from 'q3-ui-permissions';
import { isAdmin } from '../utils';

const Upload = connect((props) => {
  const user =
    React.useContext(AuthContext)?.state?.profile?.role;

  return (
    <div style={{ position: 'relative' }}>
      <FileManager
        {...props}
        {...(!props?.data?.enableFileManagement &&
        !isAdmin(user)
          ? {
              canEdit: false,
              canDelete: false,
              canCreate: false,
            }
          : {})}
        {...React.useContext(Definitions)}
        initialView="list"
      />
    </div>
  );
});

export default Upload;
