import React from 'react';
import DashboardCourseTable from '../DashboardCourseTable';

const DashboardTopCategories = () => (
  <DashboardCourseTable
    marshal={(data) =>
      Array.isArray(data)
        ? [...data].splice(0, 5).reduce(
            (acc, curr) =>
              Object.assign(acc, {
                [curr.name]: curr.count,
              }),
            {},
          )
        : null
    }
    report="topCategories"
    title="topCategories"
  />
);

export default DashboardTopCategories;
