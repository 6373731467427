import React from 'react';
import SystemPageSub from 'q3-admin/lib/components/SystemPageSub';
import { Builders } from 'q3-ui-forms';
import useDomainContext from 'q3-admin/lib/hooks/useDomainContext';
import { castToBoolean } from 'q3-ui-forms/lib/helpers';

const DomainRegistration = () => {
  const { domain = {}, update } = useDomainContext();

  return (
    <SystemPageSub title="domainRegistration">
      <Builders.Form
        keep={[
          'requirePhone',
          'requireBirthday',
          'requireOccupation',
          'requireCountryOfResidence',
        ]}
        initialValues={domain}
        onSubmit={update}
        marshal={{
          requirePhone: [castToBoolean],
          requireBirthday: [castToBoolean],
          requireOccupation: [castToBoolean],
          requireCountryOfResidence: [castToBoolean],
        }}
        showSuccessMessage
      >
        <Builders.Field
          name="requirePhone"
          type="checkbox"
          variant="switch"
          xl={12}
          lg={12}
        />
        <Builders.Field
          name="requireBirthday"
          type="checkbox"
          variant="switch"
          xl={12}
          lg={12}
        />
        <Builders.Field
          name="requireOccupation"
          type="checkbox"
          variant="switch"
          xl={12}
          lg={12}
        />
        <Builders.Field
          name="requireCountryOfResidence"
          type="checkbox"
          variant="switch"
          xl={12}
          lg={12}
        />
      </Builders.Form>
    </SystemPageSub>
  );
};

export default DomainRegistration;
