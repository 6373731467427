import React from 'react';
import { Builders } from 'q3-ui-forms';
import { connect } from 'q3-admin/lib/containers';
import { castToBoolean } from 'q3-ui-forms/lib/helpers';
import { get } from 'lodash';

export const INTERVALS = [
  'Days',
  'Weeks',
  'Months',
  'Years',
];

// eslint-disable-next-line
const ProductsSubscription = ({ data, ...props }) => (
  <Builders.Form
    {...props}
    keep={['subscription']}
    initialValues={data}
    marshalSelectively
    marshal={{
      'subscription.enable': [
        (v, values) =>
          castToBoolean(get(values, 'subscription.enable')),
      ],
    }}
  >
    <Builders.Field
      name="subscription.enable"
      type="checkbox"
      variant="switch"
      required
      xl={6}
      lg={6}
    />
    <Builders.Field
      conditional={['subscription.enable=true']}
      name="subscription.value"
      type="number"
      required
      xl={6}
      lg={6}
    />
    <Builders.Field
      conditional={['subscription.enable=true']}
      name="subscription.amount"
      type="number"
      xl={6}
      lg={6}
    />
    <Builders.Field
      conditional={['subscription.enable=true']}
      name="subscription.interval"
      type="number"
      required
      xl={6}
      lg={6}
    />
    <Builders.Field
      conditional={['subscription.enable=true']}
      name="subscription.intervalUnit"
      type="select"
      options={INTERVALS}
      required
      xl={6}
      lg={6}
    />
  </Builders.Form>
);

ProductsSubscription.propTypes = {};

export default connect(ProductsSubscription);
