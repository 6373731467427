import React from 'react';
import AbstractCollectionBuilder from 'q3-admin/lib/builders';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { string } from 'q3-ui-helpers';
import {
  useUserLoginActions,
  useRole,
} from 'q3-admin/lib/hooks';
import Filter from '../UsersFilter';
import Add from '../UsersAdd';
import General from '../UsersGeneral';
import Stats from '../UsersStats';
import UsersPermissionSettings from '../UsersPermissionSettings';
import useUserConvert from '../useUserConvert';
import useUserCancel from '../useUserCancel';
import JournalsLink from '../JournalsLink';
import {
  ADMINISTRATOR_ASSISTANT,
  ADMINISTRATOR,
  MASTER_ADMINISTRATOR,
  COACH,
  MEMBER,
} from '../useUserRoleOptions/useUserRoleOptions';

const resolvers = (xs) => ({
  ...xs,
  name: xs.name,
  description: undefined,
  createdAt: {
    base: xs.createdAt,
    toDate: true,
  },
  updatedAt: {
    base: xs.updatedAt,
    toDate: true,
  },
  lastLoggedIn: {
    base: xs.lastLoggedIn,
    toDate: true,
  },
  verified: {
    toCheck: true,
    base: xs.verified,
  },
  hasCancelled: {
    toCheck: true,
    base: xs.hasCancelled,
  },
  journals: (
    <JournalsLink
      param="createdBy"
      value={`id(${xs.id})`}
    />
  ),
});

const registerOptions = (data, defs, t) => {
  const out = [
    {
      title: 'role',
      description: data?.role,
      href: `/app/users?role=string(${data.role})`,
    },
    {
      title: 'numberOfJournals',
      description: `${data?.numberOfJournals || 0} ${t(
        'labels:posts',
      )}`,
      href: `/app/journals?createdBy=id(${data.id})`,
    },
    {
      title: 'numberOfComments',
      description: `${data?.numberOfComments || 0} ${t(
        'labels:posts',
      )}`,
    },
  ];

  if (data?.lastLoggedIn)
    out.push({
      title: 'lastLoggedIn',
      description: string.toSimpleDate(data?.lastLoggedIn),
    });

  return out;
};

export default new AbstractCollectionBuilder({
  collectionName: 'users',
  resourceName: 'users',
  resourceNameSingular: 'user',
  disableSegmentAll: true,
  resolvers,
  icon: PeopleAltIcon,
})
  .genCreate(Add)
  .genFilter(Filter)
  .genHeader({
    titleProp: 'name',
  })
  .genViews({
    Profile: General,
    Courses: Stats,
    Permissions: UsersPermissionSettings,
  })
  .genList({
    allColumns: [
      'role',
      'email',
      'verified',
      'journals',
      'numberOfJournals',
      'numberOfComments',
      'lastLoggedIn',
      'subscriptionId',
      'hasCancelled',
    ],
    defaultColumns: [
      'role',
      'email',
      'verified',
      'journals',
      'numberOfJournals',
      'numberOfComments',
      'lastLoggedIn',
    ],
    virtuals: ['journals'],
  })
  .genDetail({
    registerActions: (args, dispatchers) => {
      const r = useRole();
      const loginActions = useUserLoginActions(
        args,
        dispatchers,
      );

      return (
        r.isOneOf([
          MASTER_ADMINISTRATOR,
          ADMINISTRATOR,
          ADMINISTRATOR_ASSISTANT,
        ])
          ? loginActions
          : []
      )
        .concat(useUserCancel(args, dispatchers))
        .concat(useUserConvert(args, dispatchers))
        .filter(Boolean);
    },
    registerOptions,
    protectView: (name, props) => {
      const { role } = props;

      if (name === 'permissions')
        return role === ADMINISTRATOR_ASSISTANT;

      if (name === 'courses')
        return [COACH, MEMBER].includes(role);

      return true;
    },
  })
  .genDetailSettings({
    files: false,
    thread: false,
  })
  .genListSettings({
    defaultSortPreference: 'name',
  })
  .build();
