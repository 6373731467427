import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  button: {
    position: 'fixed',
    right: 330.5,
    top: 18.5,
    zIndex: 12,
  },
}));
