import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    '& th:nth-child(2)': {
      width: 220,
    },
    '& th:nth-child(3)': {
      width: 185,
    },
  },
}));
