import React from 'react';
import { Button, Grid } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { connect } from 'q3-admin/lib/containers';
import { useTranslation } from 'q3-ui-locale';
import Dialog from 'q3-ui-dialog';
import JournalsAutosaveMessage from '../JournalsAutosaveMessage';
import JournalActionsProvider from '../JournalActionsProvider';
import JournalActionsCourseSelect from '../JournalActionsCourseSelect';
import JournalActionsPrivacySelect from '../JournalActionsPrivacySelect';
import JournalActionsConfirmation from '../JournalActionsConfirmation';

const JournalActions = connect(
  ({ data: { publishedOn } }) => {
    const { t } = useTranslation('labels');

    const clickDraftButton = () => {
      document.getElementById('journal-submit').click();
    };

    return publishedOn ? null : (
      <Grid
        container
        spacing={1}
        alignItems="center"
        style={{ marginTop: '1rem' }}
      >
        <Grid item xs={12}>
          <JournalsAutosaveMessage />
        </Grid>
        <Grid item>
          <Button
            id="journal-draft"
            onClick={clickDraftButton}
            variant="contained"
          >
            {t('saveDraft')}
          </Button>
        </Grid>
        <Grid item>
          <Dialog
            renderContent={(close) => (
              <JournalActionsProvider
                onClose={close}
                steps={[
                  <JournalActionsCourseSelect />,
                  <JournalActionsPrivacySelect />,
                  <JournalActionsConfirmation />,
                ]}
              />
            )}
            renderTrigger={(onClick) => (
              <Button
                color="secondary"
                onClick={onClick}
                startIcon={<PublishIcon />}
                variant="contained"
              >
                {t('publish')}
              </Button>
            )}
            title="publish"
          />
        </Grid>
      </Grid>
    );
  },
);

export default JournalActions;
