import React from 'react';
import PropTypes from 'prop-types';
import { string } from 'q3-ui-helpers';
import SubDetail from 'q3-admin/lib/containers/subDetail';
import { Builders } from 'q3-ui-forms';
import {
  castToUTC,
  castToEnd,
  castToLocalDate,
  castToLocalDateTime,
} from 'q3-ui-forms/lib/helpers';
import { connect } from 'q3-admin/lib/containers';
import { useTranslation } from 'q3-ui-locale';
import { object } from 'q3-ui-helpers';
import useRest from 'q3-ui-rest';
import { Store } from 'q3-admin/lib/containers/state';
import CoursesCallsExport from '../CoursesCallsExport';
import { SINGLE, RECURRING, FREQUENCY } from './constants';
import {
  getTitle,
  isRecurring,
  isSingle,
  retrieve,
} from './helpers';
import useStyle from './styles';

const MultiForm = (props) => {
  const {
    data: { id },
  } = React.useContext(Store);

  const { post } = useRest({
    runOnInit: false,
    url: `/courses/${id}/uploads`,
    key: 'uploads',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return (
    <Builders.Form
      {...props}
      onSubmit={(values, attachments) =>
        // eslint-disable-next-line
        props
          // eslint-disable-next-line
          .onSubmit(values)
          .then((r) => {
            if (
              object.hasKeys(attachments) &&
              'callFilePath' in attachments
            ) {
              const { callFilePath } = attachments;

              const f = new FormData();
              f.append(
                `calls/${callFilePath.name}`,
                callFilePath,
                callFilePath.name,
              );

              return post(f).then(() => r);
            }

            return r;
          })
      }
    />
  );
};

const CourseCalls = ({
  data: {
    // enableCallFileManagement,
    end: courseEndDate,
    start: courseStartDate,
  },
}) => {
  const { t } = useTranslation('descriptions');
  const cls = useStyle();

  return (
    <div className={cls.root}>
      <SubDetail
        root="calls"
        th="description"
        name="calls"
        renderOther={() => <CoursesCallsExport />}
        groupBy={[
          { label: t('labels:recurring'), fn: isRecurring },
          { label: SINGLE, fn: isSingle },
        ]}
        sortOptions={[
          {
            label: t('labels:startAsc'),
            fn: retrieve('start'),
            direction: 'asc',
          },
          {
            label: t('labels:startDesc'),
            fn: retrieve('start'),
            direction: 'desc',
          },
        ]}
        cardProps={{
          title: getTitle,
          describe: retrieve('description'),
          attributes: ['start', 'end'],
          editable: {
            start: {
              renderer: ({ start }) =>
                string.toDate(start || courseStartDate),
            },
            end: {
              renderer: ({ end, type }) => {
                if (type === 'Single') return '--';
                return (
                  string.toSimpleDate(
                    end || courseEndDate,
                  ) || '--'
                );
              },
            },
          },
        }}
        initialValues={{
          type: SINGLE,
        }}
      >
        <MultiForm
          marshalSelectively
          marshal={{
            start: [castToUTC],
            end: [castToEnd],
          }}
          modify={{
            start: [castToLocalDateTime],
            end: [castToLocalDate],
          }}
        >
          <Builders.Field
            type="select"
            name="type"
            under="calls"
            options={[RECURRING, SINGLE]}
            required
            xl={12}
            lg={12}
            helperText={t('helpers:callType')}
          />
          <Builders.Field
            conditional={[`type=${SINGLE}`]}
            under="calls"
            name="name"
            required
            xl={12}
            lg={12}
          />
          <Builders.Field
            type="select"
            conditional={[`type=${RECURRING}`]}
            under="calls"
            name="frequency"
            options={FREQUENCY}
            suppressHelper
            required
            xl={12}
            lg={12}
          />
          <Builders.Field
            under="calls"
            name="start"
            type="time"
            xl={12}
            lg={12}
            required
          />
          <Builders.Field
            under="calls"
            helperText={t('helpers:inheritEndDateCall')}
            conditional={[`type=${RECURRING}`]}
            name="end"
            type="date"
            xl={12}
            lg={12}
          />
          <Builders.Field
            under="calls"
            name="description"
            type="text"
            multiline
            rows={3}
            xl={12}
            lg={12}
          />
          {/* {enableCallFileManagement && (
            <Builders.Field
              under="calls"
              name="callFilePath"
              type="file"
              maxSize={5000000}
              xl={12}
              lg={12}
            />
          )} */}
        </MultiForm>
      </SubDetail>
    </div>
  );
};

CourseCalls.propTypes = {
  data: PropTypes.shape({
    enableCallFileManagement: PropTypes.bool,
    end: PropTypes.string,
    start: PropTypes.string,
  }).isRequired,
};

export default connect(CourseCalls);
