import React from 'react';
import DashboardCourseTable from '../DashboardCourseTable';

const DashboardSegments = () => (
  <DashboardCourseTable
    report="courseSegmentCounter"
    title="segments"
  />
);

export default DashboardSegments;
