import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Store } from 'q3-admin/lib/containers/state';
import { map, size } from 'lodash';
import Hidden from '@material-ui/core/Hidden';
import Pagination from 'q3-admin/lib/containers/Pagination';
import withPageLoading from 'q3-admin/lib/helpers/withPageLoading';
import { useTranslation } from 'q3-ui-locale';
import { useCollectionSseRefresh } from 'q3-admin/lib/hooks';
import { SelectAll } from 'q3-ui-exports/lib/Select';
import JournalsCollectionCard from '../JournalsCollectionCard';
import useStyle from './styles';

export const JournalsCollection = () => {
  const d = React.useContext(Store);
  const { t } = useTranslation('descriptions');
  const cls = useStyle();

  useCollectionSseRefresh();

  return (
    <Container
      className={cls.root}
      disableGutters
      maxWidth="xl"
    >
      {size(d?.data) ? (
        <>
          <Box
            display="flex"
            alignItems="center"
            className={cls.selectAll}
          >
            <Box component="label" display="inline-block">
              <style>
                {'#collection-header {z-index: 1}'}
              </style>
              <SelectAll ids={map(d.data, 'id')} />
              <Hidden smDown>
                <Box component="span" ml={0.5}>
                  {t('labels:selectAll')}
                </Box>
              </Hidden>
            </Box>
          </Box>
          <Grid container spacing={1}>
            {map(d.data, (item) => (
              <Grid
                key={item.id}
                item
                xl={3}
                md={4}
                sm={6}
                xs={12}
              >
                <JournalsCollectionCard {...item} />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Typography>
          {t('descriptions:noJournals')}
        </Typography>
      )}
      <Box mt={2}>
        <Pagination />
      </Box>
    </Container>
  );
};

export default withPageLoading(JournalsCollection);
