import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  text: {
    '&:not(:focus)': {
      cursor: 'pointer',
      textDecoration: 'underline',
      textDecorationStyle: 'dotted',
    },
  },
  input: {
    fontSize: theme.typography.h3.fontSize,
  },
}));
