import React from 'react';
import { useTheme } from '@material-ui/core';

const DashboardIconMember = () => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 731.67 550.618"
    >
      <circle
        cx="317.682"
        cy="132.627"
        r="46.021"
        fill="#ff6884"
      />
      <path
        fill="#3f3d56"
        d="M0 334.134c0 .66.53 1.19 1.19 1.19h729.29c.66 0 1.19-.53 1.19-1.19 0-.66-.53-1.19-1.19-1.19H1.19c-.66 0-1.19.53-1.19 1.19z"
      />
      <path
        fill="#a0616a"
        d="M466.98463 81.60598L470.81118 130.55703 526.26809 107.39339 494.98463 57.60598 466.98463 81.60598z"
      />
      <circle
        cx="465.323"
        cy="55.181"
        r="41.339"
        fill="#a0616a"
      />
      <path
        fill="#a0616a"
        d="M387.98463 440.60598L394.98463 503.39339 345.98463 496.60598 361.98463 438.60598 387.98463 440.60598z"
      />
      <path
        fill="#a0616a"
        d="M578.98463 449.60598L585.98463 512.39339 536.98463 505.60598 552.98463 447.60598 578.98463 449.60598z"
      />
      <path
        fill="#2f2e41"
        d="M462.485 260.106c-.67 0-54.146 2.685-89.478 4.463-16.722.841-29.452 15.315-28.154 32.009l12.632 162.528 36 1 .878-131 71.122 4-3-73z"
      />
      <path
        fill="#2f2e41"
        d="M619.485 259.106s9 69 2 76-226.5-5.5-226.5-5.5 48.153-69.537 56.826-71.519c8.674-1.981 146.674-8.981 146.674-8.981l21 10z"
      />
      <path
        fill="#a0616a"
        d="M335.127 172.233c-8.36-11.69-9.103-25.48-1.662-30.798 7.44-5.319 20.247-.152 28.607 11.544 3.404 4.626 5.65 10 6.551 15.672l34.792 49.982-19.8 13.708-35.774-48.834a35.97 35.97 0 01-12.714-11.274z"
      />
      <path
        fill="#a0616a"
        d="M465.674 331.017c-12.747 6.637-26.505 5.44-30.728-2.673-4.222-8.113 2.688-20.068 15.44-26.706a35.967 35.967 0 0116.437-4.29l54.366-27.44 10.797 21.526-53.368 28.575a35.97 35.97 0 01-12.944 11.008z"
      />
      <path
        fill={theme.palette.background.muted}
        d="M464.985 112.606l51-21 96 148s-67 15-90 18-49-9-49-9l-8-136z"
      />
      <path
        fill="#3f3d56"
        d="M526.985 137.606l-18.5-57.709 24 18.209s68 45 68 64 21 77 21 77 23.5 19.5 15.5 37.5 10.5 15.5 12.5 28.5-28.5 30.5-28.5 30.5-7.5-73.5-31.5-73.5-62.5-124.5-62.5-124.5zM468.568 111.13l-25.083 9.976s4 70 8 76 18 38 18 38v10.43s-28 8.57-27 13.57 66 19 66 19-13-40-21-53-18.917-113.976-18.917-113.976z"
      />
      <path
        fill="#3f3d56"
        d="M527.485 97.106s56-3 68 27 22 128 22 128l-122 66.374-21-32.374 82-64-29-125zM452.485 121.106s-29-4-34 30-1.823 38.5-1.823 38.5l-8.177 19.5-27-30-26 17s47 76 66 74 47-57 47-57l-16-92z"
      />
      <path
        fill="#2f2e41"
        d="M597.323 270.145l-14.838 209.961-38.5-1.5s-8.5-198.5-8.5-201.5 4-20 29-21 32.838 14.039 32.838 14.039zM541.485 484.106s20-6 23-2 20 6 20 6l5 49s-14 10-16 12-55 4-56-8 14-27 14-27l10-30zM394.485 470.106s6-5 8 9 9 37-1 40-110 4-110-5v-9l9-7 18.004-2.869s34.996-32.131 38.996-32.131 17 13 17 13l20-6zM505.985 77.606s-20-24-28-22-3 5-3 5l-20-22s-16-6-31 13c0 0-9-16 0-25s12-8 14-13 16-9 16-9-.804-7.197 3.598-3.598 15.394 3.598 15.394 3.598.063-4 4.535 0 9.473 2 9.473 2 0 6.921 3.5 6.96c3.5.04 9.5-4.96 10.5-.96s8 6 9 18-4 47-4 47z"
      />
      <g>
        <rect
          width="197.887"
          height="35.069"
          x="104.731"
          y="107.214"
          fill="#3f3d56"
          rx="17.534"
          ry="17.534"
        />
        <path
          fill="#fff"
          d="M125.397 30.606H285.293V205.94899999999998H125.397z"
        />
        <path
          fill="#3f3d56"
          d="M286.293 206.949H124.397V29.606h161.896v177.343zm-159.896-2h157.896V31.606H126.397v173.343z"
        />
        <rect
          width="227.111"
          height="46.758"
          x="91.789"
          y="132.681"
          fill="#3f3d56"
          rx="23.379"
          ry="23.379"
        />
        <ellipse
          cx="332.26"
          cy="157.521"
          fill="#3f3d56"
          rx="4.175"
          ry="13.986"
        />
        <ellipse
          cx="73.42"
          cy="156.269"
          fill="#3f3d56"
          rx="5.01"
          ry="13.568"
        />
        <path
          fill="#3f3d56"
          d="M74.672 154.39H103.478V157.31199999999998H74.672z"
        />
        <path
          fill="#3f3d56"
          d="M306.376 155.642H329.755V159.817H306.376z"
        />
        <path
          fill="#3f3d56"
          d="M302.334 337.957H129.44c-13.67 0-24.79-11.122-24.79-24.79V189.326c0-13.67 11.12-24.79 24.79-24.79h172.895c13.67 0 24.79 11.12 24.79 24.79v123.84c0 13.67-11.12 24.79-24.79 24.79z"
        />
        <circle
          cx="123.518"
          cy="258.343"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="131.033"
          cy="275.878"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="136.46"
          cy="291.742"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="146.062"
          cy="308.024"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="161.926"
          cy="308.024"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="177.791"
          cy="308.024"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="193.655"
          cy="308.024"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="209.52"
          cy="308.024"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="225.384"
          cy="308.024"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="241.248"
          cy="308.024"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="257.113"
          cy="308.024"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="272.977"
          cy="308.024"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="288.841"
          cy="308.024"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="152.617"
          cy="291.742"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="168.773"
          cy="291.742"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="184.93"
          cy="291.742"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="201.086"
          cy="291.742"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="217.243"
          cy="291.742"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="233.4"
          cy="291.742"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="249.556"
          cy="291.742"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="265.713"
          cy="291.742"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="281.869"
          cy="291.742"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="298.026"
          cy="291.742"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="147.314"
          cy="275.878"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="163.596"
          cy="275.878"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="179.878"
          cy="275.878"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="196.16"
          cy="275.878"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="212.442"
          cy="275.878"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="228.724"
          cy="275.878"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="245.006"
          cy="275.878"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="261.287"
          cy="275.878"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="277.569"
          cy="275.878"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="293.851"
          cy="275.878"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="310.133"
          cy="275.878"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="139.8"
          cy="258.343"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="156.082"
          cy="258.343"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="172.363"
          cy="258.343"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="188.645"
          cy="258.343"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="204.927"
          cy="258.343"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="221.209"
          cy="258.343"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="237.491"
          cy="258.343"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="253.773"
          cy="258.343"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="270.055"
          cy="258.343"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="286.336"
          cy="258.343"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="302.618"
          cy="258.343"
          r="5.427"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="128.11"
          cy="187.789"
          r="21.292"
          fill={theme.palette.secondary.main}
        />
        <circle
          cx="300.948"
          cy="187.789"
          r="21.292"
          fill={theme.palette.secondary.main}
        />
        <path
          fill={theme.palette.secondary.main}
          d="M180.522 179.439h65.7a7.648 7.648 0 016.9 4.347l17.73 37.06c2.43 5.076-1.27 10.948-6.898 10.948h-96.139c-5.248 0-8.936-5.164-7.234-10.128l12.707-37.06a7.648 7.648 0 017.234-5.167z"
        />
      </g>
      <path
        fill="#3f3d56"
        d="M155.568 56.606H251.985V58.606H155.568z"
      />
      <path
        fill="#3f3d56"
        d="M155.568 74.606H251.985V76.606H155.568z"
      />
      <path
        fill="#3f3d56"
        d="M155.568 92.606H251.985V94.606H155.568z"
      />
    </svg>
  );
};

export default DashboardIconMember;
