import {
  useBrowserEffect,
  useLocalStorageStateProxy,
} from 'q3-admin/lib/hooks';

const useJournalFontScale = () => {
  const [state, setState] = useLocalStorageStateProxy(
    'journal-zoom',
    false,
  );

  useBrowserEffect(
    () => {
      try {
        document
          .getElementById('zoom')
          .setAttribute('data-zoomed', state);
      } catch (e) {
        // noop
      }
    },
    [state],
    {
      useLayout: true,
    },
  );

  return {
    label: 'changeFontSize',

    onClick() {
      const bool = String(state) === 'true';
      setState(!bool);
    },
  };
};

export default useJournalFontScale;
