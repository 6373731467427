import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import { useTranslation } from 'q3-ui-locale';
import useProfileForm from 'q3-admin/lib/hooks/useProfileForm';
import useReport from 'q3-admin/lib/hooks/useReport';
import { pick, first } from 'lodash';
import Edit from '@material-ui/icons/Edit';
import useStyle, { BorderLinearProgress } from './styles';

const ProfileCourseAllocation = () => {
  const { t } = useTranslation();
  const { loading, data } = useReport('usageAllocation');
  const { allocation = 0, numberOfCourses = 0 } = pick(
    first(data),
    ['numberOfCourses', 'allocation'],
  );

  const {
    initialValues: { productId },
  } = useProfileForm();
  const cls = useStyle();

  return !loading && allocation > 0 ? (
    <Box className={cls.box} p={2} mb={1}>
      <Box mb={2}>
        <Typography variant="overline" color="secondary">
          {t('labels:allocation')}
        </Typography>
        <Typography>
          {t('descriptions:accountCourseAllocation')}
        </Typography>
        <BorderLinearProgress
          variant="determinate"
          value={(numberOfCourses / allocation) * 100}
        />
        <Box mt={0.5}>
          <Typography
            align="right"
            component="small"
            display="block"
            variant="caption"
          >
            {numberOfCourses} / {allocation}{' '}
            {t('labels:coursesUsed')}
          </Typography>
        </Box>
      </Box>
      <Box>
        {productId && (
          <Button
            startIcon={<Edit />}
            component={Link}
            to={`/paid-registration?quantity=${allocation}&product=${productId}`}
          >
            {t('labels:upgradeDowngradePlan')}
          </Button>
        )}
      </Box>
    </Box>
  ) : null;
};

export default ProfileCourseAllocation;
