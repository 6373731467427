import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  table: {
    '& th, & td': {
      border: 'none !important',
      padding: 4,
    },

    '& tr:nth-child(odd)': {
      '& td,& th': {
        background: theme.palette.background.default,
      },
    },

    '& th': {
      minWidth: 180,
      whiteSpace: 'nowrap',
    },

    '& td': {
      fontWeight: 'bold',
    },
  },
  title: {
    textTransform: 'none !Important',
  },
}));
