import React from 'react';
import PropTypes from 'prop-types';
import { get, map, find } from 'lodash';
import Box from '@material-ui/core/Box';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'q3-admin';
import { array } from 'q3-ui-helpers';
import useAttributesRest from '../useAttributesRest';
import useStyle from '../JournalsAttributes/styles';

export const JournalsAttributesReadOnly = ({ data }) => {
  const cls = useStyle();
  const { attributes, fetching, findByName } =
    useAttributesRest();

  return fetching ? null : (
    <AvatarGroup>
      {array.is(data?.attributes).map((item) => {
        const match = findByName(attributes, item.name);
        const getEmoji = (label) =>
          get(
            find(
              match?.options,
              (option) => option.label === label,
            ),
            'emoji',
            '💭',
          );

        return map(item.label, (label) => {
          const emoji = getEmoji(label);

          return (
            <Tooltip arrow key={label} title={label}>
              <Avatar>
                <span className={cls.bg}>{emoji}</span>
                <Box component="span" position="relative">
                  {emoji}
                </Box>
              </Avatar>
            </Tooltip>
          );
        });
      })}
    </AvatarGroup>
  );
};

JournalsAttributesReadOnly.defaultProps = {
  data: { attributes: [] },
};

JournalsAttributesReadOnly.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line
    attributes: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default connect(JournalsAttributesReadOnly);
