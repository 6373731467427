import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  card: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.025)',
  },
  chip: {
    backgroundColor: theme.palette.secondary.light,
    color:
      theme.palette.type === 'dark'
        ? theme.palette.secondary.contrastText
        : theme.palette.secondary.dark,
    marginLeft: '0 !important',
    width: '100%',

    '& svg': {
      color: theme.palette.secondary.dark,
    },
  },
  dense: {
    alignItems: 'center',

    '& div:nth-child(2)': {
      alignSelf: 'inherit',
      marginTop: 0,
    },
  },
  small: {
    fontSize: 'small',
  },
  wrap: {
    flexWrap: 'wrap',

    '& > div > div': {
      padding: '.15rem',
    },
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  '& > a': {
    flex: 1,
  },
  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  underline: {
    textDecoration: 'underline',
  },
  badge: {
    '& > span:last-of-type': {
      left: '-.25rem',
      top: '.25rem',
      width: 4,
    },
  },
}));
