import React from 'react';
import { AdminRouter } from 'gatsby-theme-q3/src/components';
import { size } from 'lodash';
import AssessmentIcon from '@material-ui/icons/Assessment';
import {
  MASTER_ADMINISTRATOR,
  ADMINISTRATOR,
  ADMINISTRATOR_ASSISTANT,
} from '../components/useUserRoleOptions/useUserRoleOptions';
import {
  Articles,
  Attributes,
  Categories,
  Courses,
  Dashboard,
  Domains,
  DomainRegistration,
  Journals,
  Templates,
  Users,
  Products,
  useProfileChange,
  useProfilePages,
  Reports,
  Style,
  ForcedJournalButton,
} from '../components';

const useDomainPages = () => [
  {
    component: DomainRegistration,
    path: 'registration',
    to: '/app/system/registration',
    text: 'domainRegistration',
  },
];

const App = () => {
  const { change, currentRole, roles } = useProfileChange();

  const canSeeReporting = () =>
    [
      MASTER_ADMINISTRATOR,
      ADMINISTRATOR,
      ADMINISTRATOR_ASSISTANT,
    ].includes(currentRole);

  return (
    <>
      <Style />
      <ForcedJournalButton />
      <AdminRouter
        AdminProps={{
          AppProps: {
            pages: [
              {
                home: true,
                component: Dashboard,
              },
              Journals,
              Courses,
              Templates,
              Articles,
              Attributes,
              Categories,
              Domains,
              Products,
              canSeeReporting()
                ? {
                    component: Reports,
                    resourceName: 'reports',
                    index: true,
                    icon: AssessmentIcon,
                    parent: 'tools',
                  }
                : null,
              Users,
            ]
              .flat()
              .filter(Boolean),
            notificationChannels: ['native', 'email'],
            profilePages: useProfilePages(),
            domainPages: useDomainPages(),
          },
          NavProps: {
            reorder: (xs = {}) => {
              const items = { ...xs };
              const notifications = items.account[0];
              delete items.account[0];

              items.account = items.account.filter(Boolean);
              items.undefined.splice(0, 0, notifications);
              return items;
            },
          },
          NotificationProps: {
            messageTypes: ['courses', 'journals'],
          },
          ToolbarProps: {
            profileOptions:
              size(roles) > 1
                ? [
                    {
                      divider: true,
                    },
                    ...roles.map((role) => ({
                      checked: role === currentRole,
                      label: role,
                      onClick: () => change(role),
                    })),
                    {
                      divider: true,
                    },
                  ]
                : [],
          },
        }}
      />
    </>
  );
};

export default App;
