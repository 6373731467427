import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'q3-ui-locale';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import JournalActionsContext from '../JournalActionsContext';

const JournalActionsPagination = ({
  backState,
  disableNext,
  nextState,
  nextIcon,
  nextLabel,
}) => {
  const ctx = React.useContext(JournalActionsContext);
  const { t } = useTranslation('labels');

  const renderBack = () =>
    ctx.hasBackStep ? (
      <Button
        onClick={() => {
          ctx.updateState(backState);
          ctx.back();
        }}
        startIcon={<ArrowBackIosIcon />}
        variant="contained"
      >
        {t('back')}
      </Button>
    ) : null;

  const renderNext = () => {
    if (ctx.hasNextStep)
      return (
        <Button
          color="secondary"
          disabled={disableNext}
          endIcon={nextIcon || <ArrowForwardIosIcon />}
          onClick={() => {
            ctx.updateState(nextState);
            ctx.next();
          }}
          variant="contained"
        >
          {t(nextLabel)}
        </Button>
      );

    return null;
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item>{renderBack()}</Grid>
      <Grid item style={{ textAlign: 'right' }}>
        {renderNext()}
        {ctx.readyForPublishing && (
          <button
            aria-label="publish"
            disabled={disableNext}
            id="publish-button"
            onClick={ctx.publish}
            type="button"
          />
        )}
      </Grid>
    </Grid>
  );
};

JournalActionsPagination.defaultProps = {
  disableNext: false,
  nextLabel: 'next',
  nextIcon: null,
};

JournalActionsPagination.propTypes = {
  backState: PropTypes.shape({}).isRequired,
  disableNext: PropTypes.bool,
  nextState: PropTypes.shape({}).isRequired,
  nextLabel: PropTypes.string,
  nextIcon: PropTypes.node,
};

export default JournalActionsPagination;
