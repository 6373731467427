import React from 'react';
import { useTheme } from '@material-ui/core';

const DashboardIconCourse = () => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        maxWidth: '100%',
        width: 320,
      }}
      viewBox="0 0 692 499.877"
    >
      <path
        fill={theme.palette.background.muted}
        d="M691 496.043H1a1 1 0 010-2h690a1 1 0 010 2z"
      />
      <path
        fill={theme.palette.background.muted}
        d="M622.704 321.641h-406a6.5 6.5 0 100 13h11.5v155.5a6.5 6.5 0 0013 0l.1-155.5h355.9v155.5a6.5 6.5 0 0013 0V338.07l1.891-3.428h10.61a6.5 6.5 0 100-13z"
      />
      <path
        fill="#3f3d56"
        d="M441.794 325.043H318.812a8.992 8.992 0 01-8.982-8.982v-67.709a8.992 8.992 0 018.982-8.982h122.982a8.992 8.992 0 018.982 8.982v67.71a8.992 8.992 0 01-8.982 8.981z"
      />
      <path
        fill="#fff"
        d="M441.793 246.283h-122.98a2.074 2.074 0 00-2.07 2.07v67.71a2.074 2.074 0 002.07 2.07h122.98a2.074 2.074 0 002.07-2.07v-67.71a2.074 2.074 0 00-2.07-2.07z"
      />
      <path
        fill="#2f2e41"
        d="M390.792 294.221s-14.722-3.392-22.82 2.827 10.306 20.355 10.306 20.355z"
      />
      <circle
        cx="381.024"
        cy="273.924"
        r="13.887"
        fill="#a0616a"
      />
      <path
        fill="#2f2e41"
        d="M370.286 254.43a9.898 9.898 0 019.126-4.856 19.397 19.397 0 015.516 1.544l9.078 3.542c2.349.916 4.922 2.038 5.941 4.345.946 2.14.24 4.612-.474 6.84l-3.397 10.594a32.246 32.246 0 00-1.924-6.998 10.835 10.835 0 00-4.705-5.363c-2.358-1.21-5.137-1.185-7.784-1.058-3.75.18-7.826.658-10.49 3.302a10.895 10.895 0 00-2.764 5.4 32.5 32.5 0 00-.58 6.111 50.669 50.669 0 01-3.87-8.295 20.525 20.525 0 01-1.437-6.715 9.444 9.444 0 012.164-6.387 6.195 6.195 0 016.231-1.961z"
      />
      <path
        fill={theme.palette.secondary.main}
        d="M414.183 306.263a2.472 2.472 0 00-2.07-1.79.86.86 0 00-.1-.06l-20.731-11.618a2.637 2.637 0 00-3.529.908l-5.67 9.18-8.54-8.54-.135-.136a2.636 2.636 0 00-3.377-.295l-17.038 11.931c-.03 0-.06.01-.09.01a3.633 3.633 0 00-3.03 3.39l-.44 8.89h68.04z"
      />
      <path
        fill="#3f3d56"
        d="M452.158 316.752H439.03a1.382 1.382 0 00-1.381-1.382h-4.146a1.382 1.382 0 00-1.382 1.382h-4.145a1.382 1.382 0 00-1.382-1.382h-4.145a1.382 1.382 0 00-1.382 1.382h-4.146a1.382 1.382 0 00-1.381-1.382h-4.146a1.382 1.382 0 00-1.382 1.382h-4.145a1.382 1.382 0 00-1.382-1.382h-4.145a1.382 1.382 0 00-1.382 1.382h-4.146a1.382 1.382 0 00-1.382-1.382h-4.145a1.382 1.382 0 00-1.382 1.382h-4.145a1.382 1.382 0 00-1.382-1.382h-4.146a1.382 1.382 0 00-1.381 1.382h-4.146a1.382 1.382 0 00-1.382-1.382h-4.145a1.382 1.382 0 00-1.382 1.382h-4.145a1.382 1.382 0 00-1.382-1.382h-4.146a1.382 1.382 0 00-1.381 1.382h-4.146a1.382 1.382 0 00-1.382-1.382h-4.145a1.382 1.382 0 00-1.382 1.382h-4.145a1.382 1.382 0 00-1.382-1.382h-4.146a1.382 1.382 0 00-1.382 1.382h-4.145a1.382 1.382 0 00-1.382-1.382h-4.145a1.382 1.382 0 00-1.382 1.382h-13.127a4.145 4.145 0 000 8.291h143.709a4.145 4.145 0 000-8.29z"
      />
      <path
        fill="#f2f2f2"
        d="M287.08 292.678v27.526h-68.963a13.771 13.771 0 110-27.526z"
      />
      <path
        fill={theme.palette.secondary.main}
        d="M288.773 319.992a1.06 1.06 0 01-1.059 1.059H216.89a14.504 14.504 0 110-29.008h70.825a1.059 1.059 0 010 2.117H216.89a12.387 12.387 0 000 24.774h70.825a1.06 1.06 0 011.059 1.058z"
      />
      <path
        fill={theme.palette.background.muted}
        d="M287.08 299.03H212.97a.212.212 0 110-.423h74.108a.212.212 0 010 .423zM287.08 304.112H212.97a.212.212 0 110-.423h74.108a.212.212 0 010 .423zM287.08 309.194H212.97a.212.212 0 110-.424h74.108a.212.212 0 010 .424zM287.08 314.275H212.97a.212.212 0 110-.423h74.108a.212.212 0 010 .423z"
      />
      <path
        fill="#f2f2f2"
        d="M274.08 263.678v27.526h-68.963a13.771 13.771 0 110-27.526z"
      />
      <path
        fill="#3f3d56"
        d="M275.773 290.992a1.06 1.06 0 01-1.059 1.059H203.89a14.504 14.504 0 110-29.008h70.825a1.059 1.059 0 010 2.117H203.89a12.387 12.387 0 000 24.774h70.825a1.06 1.06 0 011.059 1.058z"
      />
      <path
        fill={theme.palette.background.muted}
        d="M274.08 270.03H199.97a.212.212 0 110-.423h74.108a.212.212 0 010 .423zM274.08 275.112H199.97a.212.212 0 110-.423h74.108a.212.212 0 010 .423zM274.08 280.194H199.97a.212.212 0 110-.424h74.108a.212.212 0 010 .424zM274.08 285.275H199.97a.212.212 0 110-.423h74.108a.212.212 0 010 .423zM354.303 418.043v-24a4 4 0 00-4-4h-91a4 4 0 00-4 4v24z"
      />
      <path
        fill={theme.palette.background.muted}
        d="M255.303 427.043H354.303V458.043H255.303z"
      />
      <path
        fill={theme.palette.background.muted}
        d="M255.303 467.043v24a4 4 0 004 4h91a4 4 0 004-4v-24z"
      />
      <circle cx="304.303" cy="405.043" r="3" fill="#fff" />
      <circle cx="304.303" cy="445.043" r="3" fill="#fff" />
      <circle cx="304.464" cy="484.196" r="3" fill="#fff" />
      <path
        fill="#f2f2f2"
        d="M343.08 362.678v27.526h-68.963a13.771 13.771 0 110-27.526z"
      />
      <path
        fill="#3f3d56"
        d="M344.773 389.992a1.06 1.06 0 01-1.059 1.059H272.89a14.504 14.504 0 110-29.008h70.825a1.059 1.059 0 010 2.117H272.89a12.387 12.387 0 000 24.774h70.825a1.06 1.06 0 011.059 1.058z"
      />
      <path
        fill={theme.palette.background.muted}
        d="M343.08 369.03H268.97a.212.212 0 110-.423h74.108a.212.212 0 010 .423zM343.08 374.112H268.97a.212.212 0 110-.423h74.108a.212.212 0 010 .423zM343.08 379.194H268.97a.212.212 0 110-.424h74.108a.212.212 0 010 .424zM343.08 384.275H268.97a.212.212 0 110-.423h74.108a.212.212 0 010 .423z"
      />
      <path
        fill={theme.palette.background.default}
        d="M250.11 183.021V7.521A7.53 7.53 0 01257.632 0H396.36a7.53 7.53 0 017.522 7.521v175.5a7.53 7.53 0 01-7.522 7.522H257.632a7.53 7.53 0 01-7.522-7.522zm7.522-181.35a5.857 5.857 0 00-5.85 5.85v175.5a5.857 5.857 0 005.85 5.85H396.36a5.857 5.857 0 005.85-5.85V7.521a5.857 5.857 0 00-5.85-5.85z"
      />
      <path
        fill={theme.palette.secondary.main}
        d="M334.775 80.598V44.562a3.765 3.765 0 013.761-3.76h30.537a3.765 3.765 0 013.76 3.76v36.036a3.765 3.765 0 01-3.76 3.76h-30.537a3.765 3.765 0 01-3.76-3.76z"
      />
      <path
        fill={theme.palette.background.muted}
        d="M275.44 97.312V61.276a3.765 3.765 0 013.76-3.76h30.537a3.765 3.765 0 013.761 3.76v36.036a3.765 3.765 0 01-3.76 3.76H279.2a3.765 3.765 0 01-3.76-3.76z"
      />
      <path
        fill="#ff6584"
        d="M323.075 148.29v-36.035a3.765 3.765 0 013.761-3.76h30.537a3.765 3.765 0 013.76 3.76v36.036a3.765 3.765 0 01-3.76 3.76h-30.537a3.765 3.765 0 01-3.76-3.76z"
      />
      <path
        fill="#3f3d56"
        d="M505.563 313.043h-31.42a1.941 1.941 0 00-.86.2l-7.93 3.97a1.445 1.445 0 000 2.66l7.93 3.97a1.941 1.941 0 00.86.2h31.42a1.65 1.65 0 001.74-1.53v-7.94a1.65 1.65 0 00-1.74-1.53z"
      />
      <path
        fill={theme.palette.secondary.main}
        d="M507.303 314.573v7.94a1.65 1.65 0 01-1.74 1.53h-6.26v-11h6.26a1.65 1.65 0 011.74 1.53z"
      />
      <path
        fill="#ffb8b8"
        d="M505.327 253.643a11.003 11.003 0 0114.89 6.914 10.588 10.588 0 01.32 1.435l43.938 21.789 10.168-6.739 12.652 14.565-15.526 12.61a11.149 11.149 0 01-13.3.513l-44.58-30.828a10.51 10.51 0 01-1.015.37 11.003 11.003 0 01-14.01-8.56 10.708 10.708 0 01-.163-1.34 11.036 11.036 0 016.625-10.73z"
      />
      <path
        fill="#3f3d56"
        d="M596.841 387.792h-48.01c0 1.38-49.48 2.5-49.48 2.5a12.86 12.86 0 00-2.11 2 12.41 12.41 0 00-2.9 8v2a12.505 12.505 0 0012.5 12.5h90a12.51 12.51 0 0012.5-12.5v-2a12.517 12.517 0 00-12.5-12.5z"
      />
      <path
        fill="#3f3d56"
        d="M798.617 614.357H811.617V698.357H798.617z"
        transform="rotate(-180 678.117 556.326)"
      />
      <path
        fill="#3f3d56"
        d="M507.617 497.838c0 1.405 19.7.544 44 .544s44 .861 44-.544-19.699-12.543-44-12.543-44 11.139-44 12.543z"
      />
      <path
        fill="#ffb8b8"
        d="M519.353 487.993L507.094 487.992 501.261 456.195 519.355 456.196 519.353 487.993z"
      />
      <path
        fill="#2f2e41"
        d="M522.48 499.877l-39.53-.002v-.5a15.386 15.386 0 0115.386-15.386l24.145.001zM501.017 468.275l-24.441-54.808a20.086 20.086 0 018.818-25.784l53.014-28.428 24.19-2.057 22.316-2.028.16.272c.206.348 5.004 8.635 1.761 17.722-2.46 6.892-8.859 12.276-19.019 16.002-23.714 8.695-51.109 16.127-61.786 18.918a3.45 3.45 0 00-2.211 1.76 3.498 3.498 0 00-.197 2.798l22.707 51.015a4.5 4.5 0 01-3.81 6.03l-16.966 1.234q-.216.021-.43.021a4.497 4.497 0 01-4.106-2.667z"
      />
      <path
        fill="#ffb8b8"
        d="M525.353 487.993L513.094 487.992 507.261 456.195 525.355 456.196 525.353 487.993z"
      />
      <path
        fill="#2f2e41"
        d="M528.48 499.877l-39.53-.002v-.5a15.386 15.386 0 0115.386-15.386l24.145.001zM508.017 471.275l-24.441-54.808a20.086 20.086 0 018.818-25.784l53.014-28.428 24.19-2.057 22.316-2.028.16.272c.206.348 5.004 8.635 1.761 17.722-2.46 6.892-8.859 12.276-19.019 16.002-23.714 8.695-51.109 16.127-61.786 18.918a3.45 3.45 0 00-2.211 1.76 3.498 3.498 0 00-.197 2.798l22.707 51.015a4.5 4.5 0 01-3.81 6.03l-16.966 1.234q-.216.021-.43.021a4.497 4.497 0 01-4.106-2.667z"
      />
      <path
        fill={theme.palette.background.muted}
        d="M495.835 303.844a167.506 167.506 0 01-24.076-44.931 4.303 4.303 0 012.032-5.18l35.145-18.831a4.247 4.247 0 013.726-.152 4.32 4.32 0 012.461 2.884 102.328 102.328 0 0022.446 41.891 4.318 4.318 0 011.038 3.647 4.247 4.247 0 01-2.19 3.018l-35.145 18.831a4.303 4.303 0 01-5.437-1.177z"
      />
      <path
        fill="#fff"
        d="M498.233 302.042a1.299 1.299 0 001.622.335L535 283.545a1.246 1.246 0 00.653-.897 1.318 1.318 0 00-.325-1.128 105.34 105.34 0 01-23.107-43.125 1.319 1.319 0 00-.758-.895 1.247 1.247 0 00-1.11.046l-35.145 18.832a1.298 1.298 0 00-.62 1.537 164.507 164.507 0 0023.645 44.127z"
      />
      <path
        fill={theme.palette.secondary.main}
        d="M612.593 298.82l-27.525 10.46-6.72-18.666a11.168 11.168 0 015.002-13.5 11.168 11.168 0 0114.262 2.785z"
      />
      <path
        fill={theme.palette.secondary.main}
        d="M542.583 372.78a4.999 4.999 0 01-2.084-3.548c-3.525-32.91-1.07-58.664 7.506-78.735a24.992 24.992 0 0116.567-14.18l22.25-1.123.115.056a26.005 26.005 0 0114.219 27.589c-7.156 18.863-13.148 38.502-8.579 54.346a5.122 5.122 0 01-.484 3.983 4.884 4.884 0 01-3.115 2.31l-42.379 10.08a4.964 4.964 0 01-4.016-.777z"
      />
      <circle
        cx="579.396"
        cy="242.323"
        r="24.561"
        fill="#ffb8b8"
      />
      <path
        fill={theme.palette.background.muted}
        d="M511.243 261.776l-19.141 8.45a3.003 3.003 0 01-3.956-1.533l-.474-1.073a3.003 3.003 0 011.533-3.956l19.142-8.45a3.003 3.003 0 013.956 1.534l.473 1.072a3.003 3.003 0 01-1.533 3.956zM518.243 274.776l-19.141 8.45a3.003 3.003 0 01-3.956-1.533l-.474-1.073a3.003 3.003 0 011.533-3.956l19.142-8.45a3.003 3.003 0 013.956 1.534l.473 1.072a3.003 3.003 0 01-1.533 3.956z"
      />
      <path
        fill="#ffb8b8"
        d="M533.005 325.553l-42.674-33.666a10.517 10.517 0 01-1.036.306 11.027 11.027 0 01-8.818-1.691 10.913 10.913 0 01-4.627-7.734 11.023 11.023 0 017.21-11.638 11.004 11.004 0 0114.426 7.837 10.571 10.571 0 01.229 1.453l42.481 24.507 10.572-6.087 11.71 15.332-16.413 11.7a10.942 10.942 0 01-13.06-.319z"
      />
      <path
        fill={theme.palette.secondary.main}
        d="M567.686 318.947l-24.349-16.557 11.443-16.207a11.168 11.168 0 0113.929-3.64 11.168 11.168 0 015.894 13.283z"
      />
      <path
        fill="#2f2e41"
        d="M570.391 238.665c-6.312 4.32-14.601 8.762-21.049 4.16-4.233-3.02-5.567-8.615-3.85-13.524 3.087-8.829 11.57-12.715 19.68-15.695 10.545-3.873 22.026-6.954 32.911-4.18s20.506 13.313 18.541 24.373c-1.58 8.894-9.97 16.295-8.779 25.25 1.199 9.011 11.334 13.755 20.281 15.366s18.96 2.128 25.391 8.555c8.203 8.199 6.155 22.853-1.564 31.51s-19.367 12.58-30.654 15.247c-14.954 3.534-31.129 5.392-45.332-.474s-25.255-21.654-21.141-36.46c1.738-6.253 5.832-11.538 9.788-16.684s7.964-10.526 9.473-16.839c1.258-5.26.327-11.368-3.162-15.315a4.437 4.437 0 01-.594-5.186z"
      />
      <path
        fill={theme.palette.background.default}
        d="M109.072 357.998a39.063 39.063 0 01-37.72-6.644c-13.213-11.09-17.355-29.359-20.726-46.275l-9.969-50.036 20.872 14.372c15.01 10.335 30.357 21 40.75 35.97s14.929 35.406 6.579 51.604"
      />
      <path
        fill="#f2f2f2"
        d="M107.288 418.577c2.113-15.387 4.285-30.973 2.803-46.525-1.316-13.812-5.53-27.303-14.109-38.364a63.82 63.82 0 00-16.374-14.838c-1.637-1.033-3.144 1.562-1.514 2.59a60.672 60.672 0 0123.999 28.957c5.225 13.29 6.064 27.776 5.164 41.894-.545 8.537-1.699 17.018-2.862 25.488a1.554 1.554 0 001.048 1.846 1.51 1.51 0 001.845-1.048z"
      />
      <path
        fill={theme.palette.background.default}
        d="M86.941 391.348a28.755 28.755 0 01-25.055 12.932c-12.683-.602-23.257-9.454-32.775-17.858L.958 361.564l18.633-.891c13.4-.641 27.145-1.24 39.907 2.893s24.533 14.076 26.867 27.287"
      />
      <path
        fill="#f2f2f2"
        d="M113.258 427.422c-10.168-17.99-21.961-37.986-43.035-44.377a48.022 48.022 0 00-18.1-1.869c-1.922.166-1.443 3.129.476 2.963a44.614 44.614 0 0128.886 7.642c8.145 5.544 14.487 13.252 19.854 21.426 3.288 5.006 6.233 10.225 9.179 15.437.941 1.666 3.692.464 2.74-1.222z"
      />
      <path
        fill="#f2f2f2"
        d="M129.55 446.36a21.774 21.774 0 00-13.343-18.237v-10.401H98.762v10.728a21.763 21.763 0 00-12.616 17.91l-4.102 47.683h51.608z"
      />
    </svg>
  );
};

export default DashboardIconCourse;
