import React from 'react';
import AbstractCollectionBuilder from 'q3-admin/lib/builders';
import DnsIcon from '@material-ui/icons/Dns';
import axios from 'axios';
import { browser } from 'q3-ui-helpers';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import StorageIcon from '@material-ui/icons/Storage';
import Add from '../DomainsAdd';
import General from '../DomainsGeneral';
import Announcement from '../DomainsAnnouncement';
import DomainsRestore from '../DomainsRestore';

const DEFAULT_TENANT = 'JournalEnginePortal ';

const resolvers = (xs) => ({
  ...xs,
  name: xs.subdomain || DEFAULT_TENANT,
  description: null,
});

export const replaceSubdomain = (a, b) => {
  const hasSubdomain = a.match(/\.\w*\b/g)?.length > 1;

  if (!hasSubdomain && !b) {
    return a;
  }

  if (b && hasSubdomain) {
    return a.replace(/^[^.]*/, b);
  }
  if (b) {
    return [b, a].join('.');
  }

  const toShift = a.split('.');
  toShift.shift();
  return toShift.join('.');
};

export const changeTenant = (tenant) => () =>
  browser.isBrowserReady() &&
  // eslint-disable-next-line
  confirm(
    "Changing your tenancy will redirect you to the appropriate subdomain. As a member of this tenancy, you'll need to access the portal via this subdomain moving forward. We recommend switching back to your preferred tenant during your current session so that you don't forget where to login next time.",
  )
    ? axios
        .post('/tenant', {
          tenant,
        })
        .then(() => {
          window.location.href = `https://${replaceSubdomain(
            process.env.GATSBY_PRIMARY_DOMAIN,
            tenant,
          )}`;
        })
        .catch(() => {
          // noop
        })
    : null;

export default new AbstractCollectionBuilder({
  collectionName: 'domains',
  resourceName: 'domains',
  resourceNameSingular: 'domain',
  icon: DnsIcon,
  resolvers,
  parent: 'tools',
})
  .genCreate(Add)
  .genHeader({
    titleRenderer: ({ subdomain = DEFAULT_TENANT }) =>
      subdomain,
  })
  .genViews({
    General,
    Announcement,
  })
  .genList({
    defaultColumns: [],
    disableAvatar: true,

    registerActions: () => [
      {
        icon: StorageIcon,
        label: 'restore',
        renderContent: () =>
          React.createElement(DomainsRestore),
        requireCheckedState: false,
      },
    ],
  })
  .genDetail({
    autoOpenSummary: false,
    registerActions: ({ subdomain }) => [
      {
        label: 'switchToTenant',
        onClick: changeTenant(subdomain),
        icon: EventSeatIcon,
      },
    ],
  })
  .genListSettings({
    defaultSortPreference: 'subdomain',
  })
  .build();
