import React from 'react';
import CoursesUsers from '../CoursesUsers';

const CoursesCoaches = () => (
  <CoursesUsers
    plural="coaches"
    // eslint-disable-next-line
    role="Coach"
    singular="coach"
  />
);

export default CoursesCoaches;
