import React from 'react';
import SystemPageSub from 'q3-admin/lib/components/SystemPageSub';
import axios from 'axios';
import Confirm from 'q3-ui-confirm';
import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'q3-ui-locale';
import { destroySession } from 'q3-ui-permissions';

const ProfileDelete = () => {
  const { t } = useTranslation('descriptions');

  return (
    <SystemPageSub title="accountDelete">
      <Box mb={2}>
        <Alert severity="warning">
          {t('accountDeleteWarning')}
        </Alert>
      </Box>
      <Confirm
        service={() =>
          axios.delete('/account').then(() => {
            destroySession();
          })
        }
        label="continue"
      />
    </SystemPageSub>
  );
};

export default ProfileDelete;
