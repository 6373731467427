import React from 'react';
import axios from 'axios';
import { IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { useAuth } from 'q3-ui-permissions';

// eslint-disable-next-line
const TemplatesCourseHomeworkSave = ({ row }) => {
  const [loading, setLoading] = React.useState(false);
  const { Hide } = useAuth('templates');

  const handleSave = () => {
    setLoading(true);

    return axios
      .post('/templates', {
        __t: 'homework',
        ...row,
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Hide op="Create">
      <IconButton
        color="inherit"
        disabled={loading}
        label="save"
        onClick={handleSave}
      >
        <SaveIcon />
      </IconButton>
    </Hide>
  );
};

export default TemplatesCourseHomeworkSave;
