import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { connect } from 'q3-admin/lib/containers';
import { useTranslation } from 'q3-ui-locale';
import { string } from 'q3-ui-helpers';
import { find, map } from 'lodash';
import useRest from 'q3-ui-rest';
import Repeater from 'q3-ui-repeater';
import { IconButton, Tooltip } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DomainLoading from 'q3-admin/lib/components/DomainLoading';
import { getSegmentName } from '../Courses/Courses';
import * as SEGMENTS from '../Courses/constants';
import { COACH } from '../useUserRoleOptions/useUserRoleOptions';

const printCourseStartDate = (xs, defaultValue) =>
  string.toDate(xs?.createdAt || xs?.start || defaultValue);

const makeCourseLink = (path) =>
  // eslint-disable-next-line
  React.memo(({ row: { id } }) => {
    const { t } = useTranslation('labels');

    return (
      <Tooltip title={t('view')}>
        <IconButton
          color="inherit"
          component={Link}
          to={`/app/courses/${id}/${path}`}
        >
          <ExitToAppIcon />
        </IconButton>
      </Tooltip>
    );
  });

const CourseVariableReader = (role) =>
  role === COACH
    ? {
        genCourseLink() {
          return makeCourseLink('coaches');
        },

        genSearchString(id) {
          return `?coaches.coach=string(${id})&sort=-start&limit=500`;
        },

        genStartDate(userId, course = {}) {
          return printCourseStartDate(
            find(
              course.coaches,
              (item) => item?.coach?.id === userId,
            ),
            course.createdAt,
          );
        },
      }
    : {
        genCourseLink() {
          return makeCourseLink('participants');
        },

        genSearchString(id) {
          return `?participants.participant=string(${id})&sort=-start&limit=500`;
        },

        genStartDate(userId, course = {}) {
          return printCourseStartDate(
            find(
              course.participants,
              (item) => item?.participant?.id === userId,
            ),
            course.createdAt,
          );
        },
      };

const UsersGeneral = ({ data }) => {
  const { id, role } = data;
  const courseVars = CourseVariableReader(role);
  const { t } = useTranslation('labels');

  const { courses = [], fetching = true } = useRest({
    key: 'course',
    location: { search: courseVars.genSearchString(id) },
    pluralized: 'courses',
    runOnInit: true,
    url: '/courses',
  });

  return fetching ? (
    <DomainLoading />
  ) : (
    <Repeater
      id={id}
      disableAdd
      disableEditor
      disableMultiselect
      collectionName="courses"
      edit={() => null}
      data={map(courses, (course) => ({
        date: courseVars.genStartDate(id, course),
        segment: getSegmentName(course),
        ...course,
      }))}
      name="courses"
      root="courses"
      th="title"
      cardProps={{
        actions: [
          {
            label: 'course',
            disableDialog: true,
            component: courseVars.genCourseLink(role),
          },
        ],
        describe: 'description',
        attributes: ['date'],
        title: 'title',
      }}
      groupBy={Object.values(SEGMENTS)
        .map((v) => ({
          label: v,
          fn({ segment }) {
            return segment === v;
          },
        }))
        .concat({
          label: t('labels:uncategorized'),
          fn({ segment }) {
            return !segment;
          },
        })}
    >
      <div />
    </Repeater>
  );
};

UsersGeneral.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

export default connect(UsersGeneral);
