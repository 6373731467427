import React from 'react';
import axios from 'axios';
import { first, size } from 'lodash';
import {
  ALL_MEMBERS,
  COACHES_ONLY,
  PRIVATE,
} from '../Journals/constants';
import JournalActionsContext from '../JournalActionsContext';

const useJournalPublishOptions = () => {
  const [selected, setSelected] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const { state } = React.useContext(JournalActionsContext);
  const [options, setOptions] = React.useState([]);

  const createPrivacyOptions = (v) => {
    const output = [];
    const isForAll = v === ALL_MEMBERS;

    output.push({
      label: 'publishForMeOnly',
      value: PRIVATE,
    });

    if (v === COACHES_ONLY || isForAll)
      output.push({
        label: 'publishForCoach',
        value: COACHES_ONLY,
      });

    if (isForAll)
      output.push({
        label: 'publishForAllMembers',
        value: ALL_MEMBERS,
      });

    return output.reverse();
  };

  React.useEffect(() => {
    if (state.course)
      axios
        .get(`/courses/${state.course}`)
        .then((res) => {
          setOptions(
            createPrivacyOptions(
              res?.data?.course?.privacy,
            ),
          );
        })
        .catch(() => {
          setOptions(createPrivacyOptions());
        })
        .finally(() => {
          setLoading(false);
        });
    // without a course you can only show privately
    else {
      setOptions(createPrivacyOptions());
      setLoading(false);
    }
  }, [state.course]);

  React.useEffect(() => {
    if (
      state?.publishedState &&
      state?.publishedState !== 'null'
    )
      setSelected(state.publishedState);
  }, [state?.publishedState]);

  React.useEffect(() => {
    if (!selected && size(options))
      setSelected(first(options)?.value);
  }, [selected, options]);

  return {
    loading,
    options,
    selected,
    setSelected,
  };
};

export default useJournalPublishOptions;
