import React from 'react';
import { AuthContext } from 'q3-ui-permissions';
import axios from 'axios';
import { map } from 'lodash';
import useUserRoleOptions from '../useUserRoleOptions';

const useProfileChange = () => {
  const p = React.useContext(AuthContext)?.state?.profile;
  const getRoles = useUserRoleOptions('originalRole');

  return {
    change: (role) =>
      axios
        .post('/account', {
          role,
        })
        .then(() => {
          window.location.reload();
        }),

    currentRole: p?.role,
    roles: map(getRoles(), 'label'),
  };
};

export default useProfileChange;
