import { object } from 'q3-ui-helpers';
import axios from 'axios';
import { useTranslation } from 'q3-ui-locale';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';

const useUserConver = ({ id, role }, { poll }) => {
  const { t } = useTranslation('labels');
  const COACH = 'Coach';
  const MEMBER = 'Member';
  const newRole = role === COACH ? MEMBER : COACH;

  return [COACH, MEMBER].includes(role)
    ? {
        label: t('labels:convertToRole', {
          newRole,
        }),
        icon: SwapCallsIcon,
        onClick: () =>
          object.noop(
            axios
              .post(`/users/${id}/convert`, {
                role: newRole,
              })
              .then(() => poll()),
          ),
      }
    : null;
};

export default useUserConver;
