import React from 'react';
import { Builders } from 'q3-ui-forms';
import JournalsCourseSelect from '../JournalsCourseSelect';

const JournalsAdd = (props) => (
  <Builders.Form
    {...props}
    submitLabel="startWriting"
    marshalSelectively
    marshal={{
      'course.ref': 'course',
      'title': [() => 'Untitled'],
    }}
    onSubmit={(values) => {
      const { prompt, promptId, ...rest } = values;
      if (promptId) {
        rest.prompt = prompt;
        rest.promptId = promptId;
      }

      // eslint-disable-next-line
      return props.onSubmit(rest);
    }}
  >
    <JournalsCourseSelect />
  </Builders.Form>
);

JournalsAdd.propTypes = {};

export default JournalsAdd;
