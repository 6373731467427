import React from 'react';
import { Builders } from 'q3-ui-forms';
import {
  getSafelyForAutoCompleteWithProjection,
  getSafelyForAutoComplete,
} from 'q3-ui-rest';
import { connect } from 'q3-admin/lib/containers';

// eslint-disable-next-line
const ProductsGeneral = ({ data, ...props }) => (
  <Builders.Form
    {...props}
    keep={[
      'title',
      'currency',
      'course',
      'setupFee',
      'description',
      'template',
      'type',
    ]}
    translate={{
      'course.label': 'course.title',
      'course.value': 'course.id',
      'template.label': 'template.name',
      'template.value': 'template.id',
    }}
    initialValues={data}
    marshalSelectively
    marshal={{
      course: 'course.value',
      template: 'template.value',
    }}
  >
    <Builders.Field
      name="title"
      type="text"
      required
      xl={6}
      lg={6}
    />
    <Builders.Field
      name="currency"
      type="select"
      options={['CAD', 'EUR', 'USD']}
      required
      xl={6}
      lg={6}
    />
    <Builders.Field
      name="setupFee"
      type="number"
      xl={6}
      lg={6}
    />
    <Builders.Field
      name="type"
      type="text"
      disabled
      xl={6}
      lg={6}
    />
    <Builders.Field
      name="course"
      type="autocomplete"
      conditional={['type=Course']}
      loadOptions={getSafelyForAutoComplete(
        '/courses',
        'courses',
        'title',
      )}
      xl={12}
      lg={12}
    />
    <Builders.Field
      name="template"
      type="autocomplete"
      conditional={['type=Course Template']}
      loadOptions={getSafelyForAutoCompleteWithProjection(
        '/templates?__t=course',
        'templates',
        'name',
      )}
      xl={12}
      lg={12}
    />
    <Builders.Field
      name="description"
      type="text"
      multiline
      rows={5}
      xl={12}
      lg={12}
    />
  </Builders.Form>
);

ProductsGeneral.propTypes = {};

export default connect(ProductsGeneral);
