import React from 'react';
import { Box } from '@material-ui/core';
// eslint-disable-next-line
import Charts from 'q3-ui-charts';
import useStyle from './styles';
import useReport from '../useReport';

const DashboardJournalTrends = () => {
  const { data } = useReport('journalTrends');
  const cls = useStyle();

  return (
    <Box className={cls.chartWrapper} mb={1}>
      <Charts
        data={data}
        enableDownload={false}
        variant="Line"
        value="Count"
        name="Date"
      />
    </Box>
  );
};

export default DashboardJournalTrends;
