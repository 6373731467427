export const RECURRING = 'Recurring';
export const SINGLE = 'Single';

export const DAYS = [
  'Everyday',
  'Weekday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const FREQUENCY = [
  'Daily',
  'Daily (no weekends)',
  'Weekly',
  'Biweekly',
  'Monthly (1st week)',
  'Monthly (2nd week)',
  'Monthly (3rd week)',
  'Monthly (4th week)',
  'Monthly (5th week)',
  'Annually',
];

export const HOURS = [
  {
    label: '12am',
    value: 0,
  },
  {
    label: '1am',
    value: 1,
  },
  {
    label: '2am',
    value: 2,
  },
  {
    label: '3am',
    value: 3,
  },
  {
    label: '4am',
    value: 4,
  },
  {
    label: '5am',
    value: 5,
  },
  {
    label: '6am',
    value: 6,
  },
  {
    label: '7am',
    value: 7,
  },
  {
    label: '8am',
    value: 8,
  },
  {
    label: '9am',
    value: 9,
  },
  {
    label: '10am',
    value: 10,
  },
  {
    label: '11am',
    value: 11,
  },
  {
    label: '12pm',
    value: 12,
  },
  {
    label: '1pm',
    value: 13,
  },
  {
    label: '2pm',
    value: 14,
  },
  {
    label: '3pm',
    value: 15,
  },
  {
    label: '4pm',
    value: 16,
  },
  {
    label: '5pm',
    value: 17,
  },
  {
    label: '6pm',
    value: 18,
  },
  {
    label: '7pm',
    value: 19,
  },
  {
    label: '8pm',
    value: 20,
  },
  {
    label: '9pm',
    value: 21,
  },
  {
    label: '10pm',
    value: 22,
  },
  {
    label: '12pm',
    value: 23,
  },
];

export const MINUTES = [
  {
    label: '00',
    value: 0,
  },
  {
    label: '05',
    value: 5,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '15',
    value: 15,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '30',
    value: 30,
  },
  {
    label: '35',
    value: 35,
  },
  {
    label: '40',
    value: 40,
  },
  {
    label: '45',
    value: 45,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '55',
    value: 55,
  },
];
