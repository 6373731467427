import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Builders } from 'q3-ui-forms';
import { useTranslation } from 'q3-ui-locale';
import JournalActionsContext from '../JournalActionsContext';
import JournalsCourseSelect from '../JournalsCourseSelect';
import JournalActionsPagination from '../JournalActionsPagination';

const JournalActionsCourseSelect = () => {
  const { t } = useTranslation('descriptions');
  const { state } = React.useContext(JournalActionsContext);

  return (
    <Builders.Form
      enableSubmit={false}
      initialValues={state}
    >
      {(values) => (
        <>
          <Grid item xs={12}>
            <Typography style={{ marginBottom: '0.5rem' }}>
              {t('confirmCourseAndPrompt')}
            </Typography>
          </Grid>
          <JournalsCourseSelect
            showPreview={false}
            selectDefaultPrompt={false}
          />
          <JournalActionsPagination
            backState={{
              course: null,
              prompt: null,
              promptId: null,
            }}
            nextState={values}
          />
        </>
      )}
    </Builders.Form>
  );
};

export default JournalActionsCourseSelect;
