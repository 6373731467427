import React from 'react';
import { useLocation } from '@reach/router';
import { browser } from 'q3-ui-helpers';

const useCommentHash = () => {
  const hash = String(useLocation().hash);
  const id = String(hash).split('-')[1];

  React.useLayoutEffect(() => {
    if (
      browser.isBrowserReady() &&
      hash.startsWith('#comment')
    ) {
      let attempt = 0;
      let stop;
      let timer;

      const endInterval = () => {
        if (timer) {
          clearInterval(timer);
          attempt = 0;
          stop = true;
        }
      };

      timer = setInterval(() => {
        // gives us 10 seconds to find it
        if (stop || attempt > 40) {
          endInterval();
          return;
        }

        try {
          document
            .getElementById(`comment-${id}`)
            .scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            });

          stop = true;
        } catch (e) {
          attempt += 1;
        }
      }, 250);

      return endInterval;
    }

    return undefined;
  }, [id]);

  return id;
};

export default useCommentHash;
