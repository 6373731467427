import React from 'react';
import {
  Definitions,
  Store,
} from 'q3-admin/lib/containers/state';
import { AuthContext } from 'q3-ui-permissions';
import { Adapters } from 'q3-ui-rte';
import TemplatesCourseHomeworkDateModal from '../TemplatesCourseHomeworkDateModal';
import { isAdmin } from '../utils';

const useHomeworkRepeaterSettings = () => {
  const auth = React.useContext(AuthContext);
  const { data } = React.useContext(Store);
  const { collectionName } = React.useContext(Definitions);
  const role = auth?.state?.profile?.role;

  const isCoach = role === 'Coach';
  const isOpen = data.type === 'Open';
  const isScheduled = data.type === 'Scheduled';

  const disabled = !isAdmin(role) && !data.enableFrequency;

  const output = {};

  if (isScheduled && (isAdmin(role) || isCoach))
    output.editable = {
      date: {
        renderer: (props, update) =>
          React.createElement(
            TemplatesCourseHomeworkDateModal,
            {
              ...props,
              disabled,
              showDateField:
                collectionName === 'courses' && isScheduled,
              update,
            },
          ),
      },
    };
  else if (!isOpen)
    output.editable = {
      date: {
        renderer: ({ distanceFromStartDate }) =>
          `Day ${distanceFromStartDate}`,
      },
    };

  if (isAdmin(role) || data.enableHomeworkFileManagement)
    output.upload = Adapters.toQ3Api(
      `/${collectionName}/${data.id}`,
    );

  if (isAdmin(role) || data.enableHomework) {
    output.showEditor = true;

    // shouldn't be able to edit frequency if we can't edit homework
    if (!isOpen && (isAdmin(role) || data.enableFrequency))
      output.showFrequency = true;
    else output.showFrequency = false;
  } else {
    output.disableAdd = true;
    output.disableRemove = true;
  }

  if (!isOpen) output.attributes = ['date'];
  if (isScheduled) output.canScheduleManually = true;

  return output;
};

export default useHomeworkRepeaterSettings;
