import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'q3-admin/lib/containers';
import { Context } from 'q3-ui-forms';
import { object } from 'q3-ui-helpers';
import { pick } from 'lodash';
import { getLatestContentFromEditor } from '../useJournalAutosave/useJournalAutosave';
import JournalActionsContext from '../JournalActionsContext';

export const JournalActionsProvider = ({
  data,
  steps,
  onClose,
  onSubmit,
}) => {
  const [step, setStep] = React.useState(1);
  const [state, setState] = React.useState({
    course: data?.course?.ref,
    ...pick(data, ['prompt', 'promptId', 'publishedState']),
  });

  const activeStep = steps[step - 1] || <div />;
  const activeForm = React.useContext(Context.BuilderState);
  const numberOfSteps = steps.length;

  const publish = () =>
    object.noop(
      onSubmit({
        body: getLatestContentFromEditor(
          activeForm?.values?.body,
        ),
        publishedOn: new Date(),
        publishedState: state.publishedState,
        prompt: state.prompt,
        promptId: state.promptId,
        ...(state.course
          ? {
              course: {
                ref: state.course,
              },
            }
          : {
              course: null,
            }),
      }).then(onClose),
    );

  const back = () =>
    setStep((previousStep) =>
      Math.max(1, previousStep - 1),
    );

  const next = () =>
    setStep((previousStep) =>
      Math.min(numberOfSteps, previousStep + 1),
    );

  const updateState = (newState = {}) =>
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));

  const contextValue = React.useMemo(
    () => ({
      hasBackStep: step !== 1,
      hasNextStep: step < numberOfSteps,
      readyForPublishing: step === numberOfSteps,
      step,
      publish,
      updateState,
      next,
      back,
      state,
    }),
    [activeForm, state, step],
  );

  return (
    <JournalActionsContext.Provider value={contextValue}>
      {activeStep}
    </JournalActionsContext.Provider>
  );
};

JournalActionsProvider.propTypes = {
  data: PropTypes.shape({
    course: PropTypes.shape({
      ref: PropTypes.string,
    }),
    prompt: PropTypes.string,
    promptId: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  ).isRequired,
};

export default connect(JournalActionsProvider);
