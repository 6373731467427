import React from 'react';
import {
  Box,
  List,
  ListItemSecondaryAction,
  ListItem,
  IconButton,
  ListSubheader,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Link } from 'gatsby';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { map, size } from 'lodash';
import RestoreIcon from '@material-ui/icons/Restore';
import { object, string } from 'q3-ui-helpers';
import axios from 'axios';
import { useTranslation } from 'q3-ui-locale';

const DomainsRestore = () => {
  const [restored, setRestored] = React.useState([]);
  const [archive, setArchive] = React.useState([]);
  const { t } = useTranslation('labels');

  const handleClick =
    ({ id, tenant }) =>
    () =>
      object.noop(
        axios
          .post('/domains-archive', {
            tenant,
          })
          .then(() => {
            setArchive((prevState) =>
              prevState.filter(
                (domain) => domain.tenant !== tenant,
              ),
            );

            setRestored((prevState) =>
              prevState.concat({
                tenant,
                id,
              }),
            );
          }),
      );

  const renderList = (title, data, callback) =>
    size(data) ? (
      <Box mt={2}>
        <List
          subheader={
            <ListSubheader
              disableSticky
              component="li"
              id={title}
              style={{
                lineHeight: 'initial',
              }}
            >
              <Typography
                color="secondary"
                variant="overline"
                style={{
                  textTransform: 'none',
                }}
              >
                {t(title)}
              </Typography>
            </ListSubheader>
          }
        >
          {map(data, callback)}
        </List>
      </Box>
    ) : null;

  React.useEffect(() => {
    object.noop(
      axios
        .get('/domains-archive')
        .then(({ data }) => setArchive(data.domains)),
    );
  }, []);

  return (
    <>
      {renderList(
        'restored',
        restored,
        ({ id, tenant }) => (
          <ListItem dense key={id}>
            {tenant}
            <ListItemSecondaryAction>
              <IconButton
                aria-label="view"
                color="inherit"
                component={Link}
                to={`/app/domains/${id}`}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ),
      )}
      {renderList('archive', archive, (domain) => {
        const { tenant, updatedAt } = domain;
        return (
          <ListItem dense key={tenant}>
            <ListItemText
              primary={tenant}
              secondary={string.toDate(updatedAt)}
            />
            <ListItemSecondaryAction>
              <IconButton
                aria-label="restore"
                color="inherit"
                onClick={handleClick(domain)}
              >
                <RestoreIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </>
  );
};

export default DomainsRestore;
