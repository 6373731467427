import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'q3-admin';
import { Builders } from 'q3-ui-forms';
import { castToBoolean } from 'q3-ui-forms/lib/helpers';

export const AttributesGeneral = ({ data, ...props }) => (
  <Builders.Form
    {...props}
    initialValues={data}
    keep={['enableMultiple', 'name', 'sort']}
    marshal={{
      enableMultiple: [castToBoolean],
      name: 'name',
      sort: 'sort',
    }}
  >
    <Builders.Field
      name="name"
      type="text"
      required
      xl={12}
      lg={12}
    />
    <Builders.Field
      name="sort"
      type="number"
      xl={12}
      lg={12}
    />{' '}
    <Builders.Field
      name="enableMultiple"
      type="checkbox"
      variant="switch"
      xl={12}
      lg={12}
    />
  </Builders.Form>
);

AttributesGeneral.defaultProps = {
  data: {},
};

AttributesGeneral.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    sort: PropTypes.number,
  }),
};

export default connect(AttributesGeneral);
