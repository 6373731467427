import { useLocation } from '@reach/router';
import useRest from 'q3-ui-rest';

export default (template) =>
  useRest({
    key: 'data',
    pluralized: 'data',
    location: useLocation(),
    runOnInit: true,
    url: `/reports?template=${template}`,
  });
