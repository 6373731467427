import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReachLink } from '@reach/router';
import { Button } from '@material-ui/core';
import { useTranslation } from 'q3-ui-locale';
import CallMadeIcon from '@material-ui/icons/CallMade';

const JournalLinks = ({ param, value }) => {
  const { t } = useTranslation('labels');

  return (
    <Button
      color="inherit"
      component={ReachLink}
      endIcon={<CallMadeIcon />}
      size="small"
      to={`/app/journals?${param}=${encodeURIComponent(
        value,
      )}`}
    >
      {t('viewAllJournals')}
    </Button>
  );
};

JournalLinks.propTypes = {
  param: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default JournalLinks;
