import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'q3-admin';
import { Typography, Box } from '@material-ui/core';
import { Builders } from 'q3-ui-forms';
import { castToBoolean } from 'q3-ui-forms/lib/helpers';
import { useTranslation } from 'q3-ui-locale';

const DomainsGeneral = ({ data, onSubmit }) => {
  const { t } = useTranslation('labels');

  return (
    <Box>
      <Typography>
        {t('descriptions:domainSettings')}
      </Typography>
      <Builders.Form
        submitLabel="save"
        onSubmit={onSubmit}
        initialValues={data}
        keep={['courseTemplates', 'publicRegistration']}
        marshal={{
          courseTemplates: 'courseTemplates',
          publicRegistration: [castToBoolean],
        }}
      >
        <Builders.Field
          name="courseTemplates"
          type="multiselect"
          options={['Open', 'Scheduled', 'Self-Guided']}
          xl={12}
          lg={12}
        />
        <Builders.Field
          name="publicRegistration"
          type="checkbox"
          variant="switch"
        />
      </Builders.Form>
    </Box>
  );
};

DomainsGeneral.defaultProps = {
  data: {},
};

DomainsGeneral.propTypes = {
  data: PropTypes.shape({
    tenant: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
};

export default connect(DomainsGeneral);
