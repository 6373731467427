import React from 'react';
import { useTheme } from '@material-ui/core';

const DashboardIconAdmin = () => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 846.831 588.649"
    >
      <path
        fill="#f2f2f2"
        d="M586.517 550.08l29 16.84-28.59-6.9c.22 2.36.52 4.68.92 6.95.11.63.23 1.25.36 1.87a51.878 51.878 0 005.25 14.92c.48.86.96 1.7 1.46 2.51.48.82.98 1.61 1.49 2.38h76.87a33.9 33.9 0 001.81-2.38 35.976 35.976 0 006.18-26.62 44.968 44.968 0 00-1.2-5.63 51.568 51.568 0 00-1.83-5.57 73.44 73.44 0 00-4.41-9.35c-3.52-6.34-9.01-12.06-15.48-17.14a108.07 108.07 0 00-9.78-6.8q-.96-.616-1.95-1.2c-1.01-.6-2.02-1.19-3.04-1.76-2.03-1.16-4.08-2.26-6.13-3.3-.54-.28-1.07-.55-1.6-.81-4.45-2.22-8.88-4.21-13.1-5.96-.53-.22-1.07-.44-1.6-.65a233.308 233.308 0 00-25.72-8.81s-.03.08-.08.25c-.13.46-.46 1.57-.91 3.21-1.25 4.55-3.45 13.23-5.22 23.71-.1.57-.19 1.15-.29 1.73a181.69 181.69 0 00-2.45 26.81c-.01.56-.01 1.11-.01 1.67z"
      />
      <path
        fill="#fff"
        d="M593.307 491.65c.01.03.11.52.34 1.42.19.75.45 1.78.79 3.06a365.03 365.03 0 006.21 20.98c.17.52.35 1.06.53 1.6 2.06 6.17 4.51 12.99 7.34 20 .71 1.76 1.42 3.49 2.15 5.18.22.53.44 1.05.67 1.56q4.65 10.77 9.57 19.71l.83 1.49a134.524 134.524 0 0013.19 19.62c.67.82 1.35 1.61 2.04 2.38h2.32c-.7-.77-1.4-1.57-2.08-2.38a132.146 132.146 0 01-13.87-20.4c-.28-.49-.56-.98-.83-1.48-3.53-6.41-6.74-13.11-9.61-19.75-.23-.51-.45-1.03-.67-1.56-3.75-8.78-6.9-17.41-9.44-25.01-.18-.54-.36-1.08-.53-1.61-3.7-11.22-6.02-20.02-6.91-23.54-.12-.52-.22-.93-.29-1.21-.07-.26-.1-.41-.11-.44l-.81.19h-.01z"
      />
      <path
        fill="#fff"
        d="M587.418 521.69c.5-.03 1.01-.07 1.51-.12a46.89 46.89 0 0012.25-2.86c.54-.2 1.08-.42 1.61-.64a48.307 48.307 0 004.57-2.24 46.766 46.766 0 0015.39-13.7c.13-.17.25-.34.37-.51q-.81-.33-1.59-.66c-.12.17-.25.35-.38.52a45.276 45.276 0 01-14.61 12.87 45.86 45.86 0 01-4.28 2.11c-.54.23-1.08.44-1.61.65a44.996 44.996 0 01-11.43 2.73c-.51.05-1.02.1-1.53.13-.09.56-.18 1.13-.27 1.72zM585.157 548.24c0 .57.01 1.13.01 1.7.45.05.9.1 1.35.14a47.05 47.05 0 0024.82-4.63q.78-.39 1.56-.81c.21-.1.42-.22.62-.33a47.226 47.226 0 0023.93-35.41c.03-.17.05-.33.07-.5-.53-.28-1.07-.55-1.61-.83l-.06.52a45.58 45.58 0 01-23.15 34.74 4.232 4.232 0 01-.47.25q-.765.435-1.56.81a45.37 45.37 0 01-24.19 4.49c-.44-.04-.88-.09-1.32-.14z"
      />
      <path
        fill="#fff"
        d="M586.697 566.58c.12.64.25 1.28.4 1.92.37.12.74.24 1.11.34a47.175 47.175 0 0033.53-2.19c.53-.25 1.07-.51 1.6-.78.56-.28 1.12-.58 1.68-.89a47.253 47.253 0 0024.28-40.03 46.77 46.77 0 00-.73-9.79c-.02-.15-.05-.29-.08-.44-.65-.42-1.31-.83-1.97-1.24.04.16.07.32.1.48a43.763 43.763 0 01.96 7.16 45.675 45.675 0 01-23.39 42.39c-.55.3-1.11.6-1.68.88-.53.27-1.06.53-1.6.77a44.468 44.468 0 01-5.31 2.02 45.548 45.548 0 01-27.75-.21c-.39-.12-.77-.25-1.15-.39z"
      />
      <path
        fill="#ffb8b8"
        d="M826.368 387.494H882.368V443.494H826.368z"
        transform="rotate(-180 766.075 337.656)"
      />
      <path
        fill="#ffb8b8"
        d="M740.707 566.513L729.673 566.512 724.425 523.955 740.709 523.956 740.707 566.513z"
      />
      <path
        fill="#2f2e41"
        d="M708.403 582.799a4.68 4.68 0 004.67 4.66h20.76l.41-.85 3.17-6.54 1.24 6.54.16.85h7.83l-.07-.85-2.13-25.54-3.74-.22-11.82-.71-1.88-.11v7.47l-16.58 11.45a4.67 4.67 0 00-2.02 3.85z"
      />
      <path
        fill="#ffb8b8"
        d="M676.18 559.507L665.576 562.557 648.768 523.109 664.418 518.608 676.18 559.507z"
      />
      <path
        fill="#2f2e41"
        d="M649.633 584.089a4.69 4.69 0 005.78 3.19l2.33-.67 12.89-3.71 4.74-1.36 1.39-8.09 3.39 6.72 7.52-2.17-9.41-24.75-3.65.82-1.31.29-10.25 2.3-1.84.41 2.07 7.18-12.77 15.59a4.682 4.682 0 00-.88 4.25zM611.794 456.757a16.874 16.874 0 011.57-15.087l25.419-131.851 10-50h60s37.5 41.5 29.5 94.5l2.24 74.64a27.448 27.448 0 011.503 16.186l-.891 4.222.343.636a21.334 21.334 0 01.269 19.772l-.18 59.638-18-1-23.784-73.594-17.5-88.5-29.5 68.5-1 16 .485 14.103 14.003 56.767-19.5 7.5z"
      />
      <path
        fill="#ccc"
        d="M845.64 588.65H555.865a1.19 1.19 0 010-2.382H845.64a1.19 1.19 0 010 2.381z"
      />
      <circle
        cx="540.494"
        cy="71.435"
        r="29.898"
        fill={theme.palette.secondary.main}
      />
      <path
        fill="#fff"
        d="M555.842 58.975a2.418 2.418 0 00-3.338 0 99.03 99.03 0 00-16.966 17.329l-3.626-7.017c-1.396-2.7-5.47-.315-4.076 2.383l5.304 10.263c.877 1.698 3.121 1.351 4.076 0a95.592 95.592 0 0118.626-19.62 2.375 2.375 0 000-3.338z"
      />
      <path
        fill="#ffb8b8"
        d="M568.277 92.703a10.024 10.024 0 01-.971 1.513l50.752 37.268 57.715 18.729a15.08 15.08 0 01-7.263 29.196 118.971 118.971 0 01-34.9-11.933l-36.123-19.042-43.701-52.046a9.997 9.997 0 1114.491-3.685z"
      />
      <path
        fill="#2f2e41"
        d="M610.574 155.584l11.102-24.396 55.388 11.255a23.716 23.716 0 11-21.764 42.144z"
      />
      <path
        fill="#e4e4e4"
        d="M667.475 29.306H3.718A3.722 3.722 0 010 25.588V3.718A3.722 3.722 0 013.718 0h663.757a3.722 3.722 0 013.718 3.718v21.87a3.722 3.722 0 01-3.718 3.718z"
      />
      <circle
        cx="21.577"
        cy="13.586"
        r="5.279"
        fill="#fff"
        data-name="Ellipse 90"
      />
      <circle
        cx="41.612"
        cy="13.586"
        r="5.279"
        fill="#fff"
        data-name="Ellipse 91"
      />
      <circle
        cx="61.649"
        cy="13.586"
        r="5.279"
        fill="#fff"
        data-name="Ellipse 92"
      />
      <path
        fill="#fff"
        d="M647.844 9.953h-23.071a1.709 1.709 0 110-3.418h23.071a1.709 1.709 0 110 3.418zM647.844 16.362h-23.071a1.709 1.709 0 010-3.418h23.071a1.709 1.709 0 110 3.418zM647.844 22.77h-23.071a1.709 1.709 0 010-3.417h23.071a1.709 1.709 0 110 3.418z"
      />
      <path
        fill="#f2f2f2"
        d="M477.17 303.109H194.023a33.04 33.04 0 01-33.003-33.004V115.89a33.039 33.039 0 0133.003-33.002H477.17a33.039 33.039 0 0133.002 33.002v154.215a33.04 33.04 0 01-33.002 33.004z"
        style={{ isolation: 'isolate' }}
      />
      <path
        fill="#e6e7e8"
        d="M404.316 161.305c2.28-.994-2.342-1.572-7.586-1.763s-11.586-.18-14.937-.72c-3.24-.523-3.002-1.454-3.274-2.296s-1.519-1.707-6.575-1.895c-6.302-.233-16.693.665-21.45.126-4.439-.503-1.8-2.083-8.273-2.143-3.696-.035-8.458.486-12.57.612a45.826 45.826 0 01-8.723-.455c-2.005-.329-3.4-.745-5.657-1.035-5.923-.762-17.512-.506-27.855.47s-19.21 2.576-24.21 4.16-6.381 3.145-5.216 4.468c.743.845 2.647 1.635 7.263 1.974 8.636.635 24.01-.458 32.933.12 4.974.322 7.18 1.12 11.543 1.557 4.688.469 11.55.494 18.293.483 14.04-.024 28.437-.168 43.118-.432 6.133-.11 12.558-.254 19.083-.781s13.18-1.542 14.557-2.495"
      />
      <path
        fill="#ccc"
        d="M453.598 143.72a15.967 15.967 0 00-.35-.826c.199.078.397.163.594.255z"
      />
      <path
        fill="#e6e7e8"
        d="M347.117 177.408c-1.812-2.998-3.96-6.199-7.324-7.178-3.896-1.134-7.897 1.118-11.292 3.34a329.043 329.043 0 00-29.282 21.576l.01.116 21.85-1.507c5.263-.363 10.707-.776 15.357-3.265 1.765-.945 3.485-2.207 5.485-2.294 2.484-.11 4.65 1.625 6.353 3.438 10.07 10.724 12.985 27.123 24.283 36.544a358.546 358.546 0 00-25.44-50.77z"
      />
      <path
        fill="#e4e4e4"
        d="M409.019 268.364c-1.114-1.406-1.569-1.736-2.667-3.155q-13.417-17.352-25.221-35.877-8.018-12.58-15.241-25.645-3.439-6.211-6.689-12.52-2.522-4.89-4.931-9.84c-.46-.943-.906-1.89-1.35-2.84-1.047-2.231-2.074-4.474-3.176-6.675-1.257-2.509-2.787-5.14-5.094-6.825a6.95 6.95 0 00-3.621-1.393 10.099 10.099 0 00-5.241 1.501 168.72 168.72 0 00-33.177 22.879 172.723 172.723 0 00-26.954 29.92c-.239.34-.802.015-.562-.327q1.42-2.009 2.897-3.974a173.677 173.677 0 0144.34-41.358q4.313-2.795 8.788-5.328a126.651 126.651 0 014.55-2.481 11.086 11.086 0 014.826-1.495c5.854-.091 8.886 6.539 10.968 11q.981 2.106 1.987 4.2 3.8 7.946 7.891 15.747 2.525 4.817 5.161 9.577 8.203 14.824 17.438 29.047c8.156 12.56 16.246 23.657 25.537 35.402.256.325-.201.789-.46.46zM302.032 184.7c-.34-.38-.678-.759-1.02-1.138-2.7-2.986-5.597-5.937-9.304-7.648a13.498 13.498 0 00-5.656-1.311 16.139 16.139 0 00-5.871 1.28c-.884.353-1.749.754-2.6 1.181a83.086 83.086 0 00-2.88 1.539q-2.678 1.482-5.287 3.094-5.19 3.207-10.056 6.905-2.522 1.918-4.94 3.96-2.249 1.899-4.407 3.899c-.308.284-.767-.176-.46-.46.38-.353.763-.704 1.147-1.05q1.625-1.47 3.302-2.882 3.058-2.58 6.275-4.962 5.002-3.705 10.348-6.908 2.672-1.599 5.417-3.063a46.954 46.954 0 011.677-.856 26.734 26.734 0 013.987-1.623 14.183 14.183 0 016.021-.592 15.677 15.677 0 015.556 1.922c3.64 2.035 6.463 5.18 9.21 8.251.278.312-.18.774-.459.462z"
      />
      <path
        fill="#e4e4e4"
        d="M376.616 215.64l8.723-3.19 4.33-1.584a44.29 44.29 0 014.276-1.473 6.745 6.745 0 013.87.05 8.92 8.92 0 013.018 1.87 24.495 24.495 0 012.476 2.645c.943 1.133 1.872 2.278 2.805 3.419q5.777 7.065 11.478 14.191t11.326 14.313q5.639 7.204 11.2 14.468l1.361 1.78c.25.329.816.006.562-.327q-5.603-7.338-11.283-14.615-5.694-7.295-11.467-14.528-5.773-7.232-11.623-14.402-1.458-1.787-2.92-3.57c-.823-1.001-1.636-2.015-2.538-2.947-1.656-1.713-3.724-3.226-6.2-3.267a11.968 11.968 0 00-4.218.927c-1.458.527-2.913 1.065-4.37 1.598l-8.783 3.212-2.196.803c-.39.143-.221.771.173.627z"
      />
      <path
        fill="#e6e7e8"
        d="M290.56 179.67a14.082 14.082 0 00-14.183 1.015 202.285 202.285 0 0123.014 5.371c-3.14-1.84-5.595-4.722-8.831-6.385z"
      />
      <path
        fill="#f2f2f2"
        d="M276.334 180.677l-.854.694c.289-.243.59-.47.897-.686z"
      />
      <path
        fill="#e6e7e8"
        d="M400.505 215.672a7.26 7.26 0 00-3.071-2.564l-1.2.047a136.006 136.006 0 0036.226 41.414l-31.955-38.897z"
      />
      <circle
        cx="242.511"
        cy="126.808"
        r="12.054"
        fill="#ccc"
      />
      <path
        fill="#f0f0f0"
        d="M279.417 126.18c-3.19-3.347-6.895-6.4-11.28-8.013a18.132 18.132 0 00-3.78-.95h-.03a.555.555 0 00-.063-.012 14.545 14.545 0 00-5.4.326 14.917 14.917 0 00-2.513.876 21.984 21.984 0 00-2.358 1.336 15.341 15.341 0 01-5.213 1.945 17.535 17.535 0 01-2.799.274 6.762 6.762 0 00-2.355.34 5.653 5.653 0 00-3.442 3.727.367.367 0 00.162.4c.21.15.413.32.632.453a1.971 1.971 0 00.617.168q.524.11 1.048.214 1.932.383 3.88.678 3.944.597 7.929.841a88.418 88.418 0 0024.809-2.01.358.358 0 00.156-.593zM243.083 134.24a18.132 18.132 0 00-3.779-.95c-.01-.002-.02 0-.03 0a.555.555 0 00-.064-.013 14.545 14.545 0 00-5.399.326 14.916 14.916 0 00-2.513.876 21.982 21.982 0 00-2.359 1.336 15.341 15.341 0 01-5.212 1.945 17.534 17.534 0 01-2.8.275 6.762 6.762 0 00-2.354.339 5.653 5.653 0 00-3.443 3.727.367.367 0 00.163.4c.209.15.412.32.632.453a1.97 1.97 0 00.617.168q.523.11 1.048.214 1.932.383 3.88.678 3.943.597 7.928.841a88.418 88.418 0 0024.81-2.01.358.358 0 00.156-.593c-3.19-3.347-6.895-6.4-11.281-8.013z"
      />
      <path
        fill="#ccc"
        d="M447.688 253.763L454.108 248.889 457.238 252.018 446.778 259.176 447.688 253.763z"
      />
      <path
        fill="#ccc"
        d="M441.7 267.185h-21.963l.77-2.495c4.774-1.729 2.092-5.444 6.634-7.714 11.108-5.554 15.408-22.128 13.781-28.68l7.28-.307s10.052 28.548-6.503 39.196z"
      />
      <path
        fill="#ccc"
        d="M460.45 255.453a3.692 3.692 0 01-4.77.09l-.015-.012v-.019a3.537 3.537 0 00-.142-.902 2.527 2.527 0 01-.092.624l-.019.069-.049-.051a3.744 3.744 0 01-.122-.133 3.723 3.723 0 01-.567-.858 10.852 10.852 0 00-3.012-3.753 5.268 5.268 0 01-.631-.608 5.208 5.208 0 016.54-7.934l.032.019-.017.033a2.713 2.713 0 01-.484.698 3.538 3.538 0 00.746-.538l.023-.022.027.018a5.23 5.23 0 01.952.848l.03.035a2.325 2.325 0 001.722.794 3.69 3.69 0 013.574 4.324l-.004.024-.024.007a3.167 3.167 0 01-1.29.161 3.598 3.598 0 001.149.288l.051.004-.016.049a3.688 3.688 0 01-1.064 1.604l-.014.013-.015.013a3.624 3.624 0 00-1.252 2.515 3.719 3.719 0 01-1.248 2.6z"
      />
      <path
        d="M461.121 254.267a3.68 3.68 0 01-.041.397 3.73 3.73 0 00.6-1.849c.005-.1.015-.201.03-.3a3.501 3.501 0 00-.589 1.752zM462.283 249.78a3.593 3.593 0 00.918.26 3.694 3.694 0 00.336-.41 3.13 3.13 0 01-1.254.15zM463.636 248.569a3.713 3.713 0 01-.027.957 3.67 3.67 0 00.416-.856l.016-.05-.051-.003a3.567 3.567 0 01-.354-.048zM463.294 245.066a3.7 3.7 0 00-2.709-1.253 2.325 2.325 0 01-1.721-.794l-.03-.035a5.23 5.23 0 00-.953-.848l-.027-.018-.024.022a3.538 3.538 0 01-.745.538 2.713 2.713 0 00.484-.698l.017-.033-.033-.02a5.204 5.204 0 00-7.71 3.37 5.205 5.205 0 017.152-1.918l.033.02-.017.033a2.713 2.713 0 01-.484.698 3.538 3.538 0 00.745-.539l.024-.022.027.019a5.23 5.23 0 01.952.847l.03.035a2.325 2.325 0 001.722.795 3.691 3.691 0 013.57 3.033 4.475 4.475 0 00.534-.13l.024-.007.004-.024a3.676 3.676 0 00-.865-3.071z"
        opacity="0.15"
      />
      <path
        fill="#ccc"
        d="M471.065 229.868a11.887 11.887 0 01-9.929 11.723l-.058.01-.046-.038a11.389 11.389 0 00-2.484-1.577 8.135 8.135 0 011.316 1.55l.126.191-.228.011c-.197.01-.392.014-.581.014a11.987 11.987 0 01-3.282-.455 34.944 34.944 0 00-15.478-.701 16.965 16.965 0 01-2.813.235 16.769 16.769 0 01-5.275-32.684l.115-.039.046.113a8.735 8.735 0 01.658 2.655 11.391 11.391 0 00.284-2.948l-.004-.104.102-.026a16.84 16.84 0 014.074-.5l.15.001a7.486 7.486 0 005.58-2.473 11.883 11.883 0 0118.054.555l.05.062-.033.072a10.197 10.197 0 01-2.354 3.46 11.585 11.585 0 003.139-2.165l.118-.115.084.143a11.875 11.875 0 011.616 5.986v.124a11.669 11.669 0 003.42 8.376 11.974 11.974 0 013.633 8.544z"
      />
      <g opacity="0.15">
        <path d="M456.536 201.784a8.395 8.395 0 01-1.497 1.837 11.555 11.555 0 002.34-1.462q-.414-.204-.843-.375zM463.415 215.969a11.932 11.932 0 011.709 2.082 12.147 12.147 0 01-1.112-5.103v-.124a11.875 11.875 0 00-1.616-5.986l-.084-.143-.118.115a11.611 11.611 0 01-2.168 1.65 11.575 11.575 0 003.389 7.509zM459.996 207.47v.123c0 .116.002.233.005.349a12.376 12.376 0 001.408-2.427l.033-.072-.05-.062a11.98 11.98 0 00-2.15-2.08 11.902 11.902 0 01.754 4.168zM467.431 221.324a10.745 10.745 0 01-1.514-1.843 11.872 11.872 0 01-8.797 16.755l-.059.01-.045-.038a11.39 11.39 0 00-2.484-1.577 8.136 8.136 0 011.315 1.55l.127.19-.228.012c-.197.01-.393.014-.581.014a11.987 11.987 0 01-3.282-.455 34.944 34.944 0 00-15.478-.701 16.965 16.965 0 01-2.813.235 16.692 16.692 0 01-11.137-4.247 16.784 16.784 0 0015.153 9.602 16.965 16.965 0 002.813-.235 34.944 34.944 0 0115.478.701 11.987 11.987 0 003.282.455c.189 0 .384-.004.581-.014l.228-.011-.126-.19a8.135 8.135 0 00-1.316-1.55 11.39 11.39 0 012.484 1.576l.046.038.058-.01a11.878 11.878 0 006.295-20.267z" />
      </g>
      <path
        fill="#ccc"
        d="M231.808 247.342L223.107 240.736 218.866 244.977 233.04 254.677 231.808 247.342z"
      />
      <path
        fill="#ccc"
        d="M239.923 265.531h29.763l-1.044-3.382c-6.47-2.342-2.836-7.377-8.99-10.454-15.053-7.526-20.88-29.986-18.676-38.864l-9.865-.418s-13.623 38.688 8.812 53.118z"
      />
      <path
        fill="#ccc"
        d="M212.823 246.108a4.911 4.911 0 00-1.696-3.408l-.02-.018-.02-.017a4.998 4.998 0 01-1.442-2.174l-.022-.066.07-.006a4.876 4.876 0 001.556-.39 4.291 4.291 0 01-1.748-.218l-.032-.01-.005-.033a5.001 5.001 0 014.842-5.859 3.15 3.15 0 002.333-1.076l.042-.048a7.088 7.088 0 011.29-1.148l.036-.025.032.03a4.794 4.794 0 001.01.73 3.676 3.676 0 01-.655-.947l-.023-.045.044-.026a7.057 7.057 0 018.862 10.752 7.14 7.14 0 01-.856.824 14.707 14.707 0 00-4.08 5.086 5.045 5.045 0 01-.769 1.164 5.06 5.06 0 01-.166.18l-.067.068-.024-.093a3.424 3.424 0 01-.125-.846 4.793 4.793 0 00-.192 1.223v.025l-.02.016a4.999 4.999 0 01-8.155-3.644z"
      />
      <path
        d="M213.603 248.024a4.988 4.988 0 00.056.538 5.056 5.056 0 01-.813-2.505 4.44 4.44 0 00-.04-.408 4.745 4.745 0 01.797 2.375zM212.029 241.945a4.868 4.868 0 01-1.244.351 5.008 5.008 0 01-.456-.556 4.242 4.242 0 001.7.205zM210.196 240.302a5.032 5.032 0 00.035 1.298 4.974 4.974 0 01-.563-1.161l-.022-.066.07-.006a4.833 4.833 0 00.48-.065zM210.658 235.555a5.013 5.013 0 013.672-1.698 3.15 3.15 0 002.333-1.076l.04-.047a7.088 7.088 0 011.291-1.15l.037-.024.032.03a4.794 4.794 0 001.01.73 3.676 3.676 0 01-.656-.946l-.023-.046.044-.026a7.052 7.052 0 0110.449 4.566 7.054 7.054 0 00-9.692-2.599l-.044.026.022.046a3.676 3.676 0 00.656.946 4.795 4.795 0 01-1.01-.73l-.032-.03-.036.025a7.088 7.088 0 00-1.29 1.149l-.042.047a3.15 3.15 0 01-2.333 1.077 5.002 5.002 0 00-4.837 4.11 6.062 6.062 0 01-.724-.176l-.032-.01-.006-.032a4.982 4.982 0 011.171-4.162z"
        opacity="0.15"
      />
      <path
        fill="#ccc"
        d="M205.052 203.381a15.813 15.813 0 004.634-11.351V191.863a16.092 16.092 0 012.19-8.113l.113-.193.16.156a15.699 15.699 0 004.254 2.934 13.819 13.819 0 01-3.19-4.69l-.044-.098.068-.084a16.103 16.103 0 0124.464-.751 10.145 10.145 0 007.564 3.351h.202a22.82 22.82 0 015.522.676l.138.035-.005.142a15.437 15.437 0 00.383 3.994 11.837 11.837 0 01.893-3.598l.062-.152.156.052a22.724 22.724 0 01-7.15 44.292 22.99 22.99 0 01-3.811-.318 47.355 47.355 0 00-20.975.95 16.244 16.244 0 01-4.447.617c-.256 0-.521-.007-.788-.02l-.309-.014.172-.258a11.026 11.026 0 011.782-2.101 15.434 15.434 0 00-3.366 2.136l-.062.052-.08-.013a16.096 16.096 0 01-8.53-27.466z"
      />
      <g opacity="0.15">
        <path d="M219.817 176.901a11.377 11.377 0 002.029 2.49 15.66 15.66 0 01-3.17-1.982q.56-.276 1.14-.508zM210.495 196.124a16.17 16.17 0 00-2.316 2.821 16.461 16.461 0 001.507-6.915V191.863a16.093 16.093 0 012.19-8.113l.113-.193.16.156a15.735 15.735 0 002.938 2.236 15.686 15.686 0 01-4.592 10.175zM215.128 184.606V184.773c0 .158-.002.316-.007.473a16.772 16.772 0 01-1.907-3.288l-.045-.099.068-.084a16.235 16.235 0 012.913-2.817 16.129 16.129 0 00-1.022 5.648zM205.052 203.381a14.56 14.56 0 002.053-2.497 16.088 16.088 0 0011.92 22.706l.08.013.062-.051a15.434 15.434 0 013.366-2.137 11.025 11.025 0 00-1.783 2.1l-.171.259.309.015c.266.013.532.019.787.019a16.244 16.244 0 004.448-.617 47.355 47.355 0 0120.974-.95 22.99 22.99 0 003.812.318 22.62 22.62 0 0015.093-5.755 22.745 22.745 0 01-20.535 13.012 22.99 22.99 0 01-3.812-.318 47.355 47.355 0 00-20.975.95 16.244 16.244 0 01-4.447.617c-.256 0-.521-.007-.788-.02l-.309-.014.171-.258a11.025 11.025 0 011.783-2.101 15.435 15.435 0 00-3.366 2.136l-.062.052-.08-.013a16.096 16.096 0 01-8.53-27.466z" />
      </g>
      <circle
        cx="308.926"
        cy="158.479"
        r="1.182"
        fill="#fff"
      />
      <circle
        cx="397.322"
        cy="186.842"
        r="0.709"
        fill="#fff"
      />
      <circle
        cx="411.031"
        cy="154.934"
        r="0.709"
        fill="#fff"
      />
      <circle
        cx="284.109"
        cy="133.662"
        r="0.709"
        fill="#ccc"
      />
      <circle
        cx="277.018"
        cy="162.261"
        r="0.709"
        fill="#fff"
      />
      <circle
        cx="242.511"
        cy="154.934"
        r="0.709"
        fill="#ccc"
      />
      <circle
        cx="364.706"
        cy="160.843"
        r="0.709"
        fill="#fff"
      />
      <circle
        cx="437.266"
        cy="137.917"
        r="0.709"
        fill="#ccc"
      />
      <path
        fill="#e6e7e8"
        d="M409.093 247.836a5.188 5.188 0 01.08-5.376 8.707 8.707 0 003.468 4.868c.655.447 1.43.874 1.654 1.635a1.946 1.946 0 01-.203 1.428 5.78 5.78 0 01-.897 1.171l-.032.12a10.555 10.555 0 01-4.07-3.846zM333.696 209.31a5.188 5.188 0 01.08-5.376 8.707 8.707 0 003.468 4.869c.655.446 1.43.874 1.654 1.634a1.946 1.946 0 01-.203 1.429 5.78 5.78 0 01-.897 1.17l-.032.12a10.555 10.555 0 01-4.07-3.845zM261.844 236.728a5.188 5.188 0 01.08-5.377 8.707 8.707 0 003.468 4.869c.655.446 1.43.874 1.655 1.634a1.946 1.946 0 01-.203 1.429 5.78 5.78 0 01-.897 1.17l-.032.12a10.555 10.555 0 01-4.07-3.845z"
      />
      <path
        fill="#ccc"
        d="M399.45 130.826H402.05V270.038H399.45z"
      />
      <rect
        width="17.017"
        height="17.017"
        x="392.359"
        y="119.481"
        fill="#ccc"
        rx="8.509"
      />
      <path
        fill="#ccc"
        d="M366.958 253.59l-6.67-2.943v-2.53l2.378-.013a1.765 1.765 0 001.665-1.207l1.67-5.009a1.765 1.765 0 00-1.675-2.324h-4.037v-2.353h5.067a1.765 1.765 0 001.72-1.37l1.15-5.002a1.765 1.765 0 00-1.72-2.161h-6.217v-.589a1.177 1.177 0 10-2.354 0v.589h-41.683v-.589a1.177 1.177 0 00-2.353 0v.589h-4.943a1.765 1.765 0 00-1.72 2.16l1.15 5.003a1.765 1.765 0 001.72 1.37h3.793v2.353h-2.762a1.765 1.765 0 00-1.68 2.307l1.704 5.29a1.765 1.765 0 001.69 1.223l1.048-.006v2.27l-5.395 2.941a1.765 1.765 0 00-1.765 1.766 1.765 1.765 0 001.765 1.765h5.395v12.946a1.177 1.177 0 102.353 0V257.12h41.683v12.946a1.177 1.177 0 102.354 0V257.12h6.67a1.765 1.765 0 001.765-1.765 1.765 1.765 0 00-1.766-1.766zm-50.706-16.38h41.683v2.354h-41.683zm0 13.437v-2.282l41.683-.234v2.516z"
      />
      <path
        fill={theme.palette.background.muted}
        d="M462.92 346.634H208.273a6.053 6.053 0 110-12.105H462.92a6.053 6.053 0 110 12.105zM377.476 373.958h-83.76a6.053 6.053 0 010-12.106h83.76a6.053 6.053 0 110 12.106zM377.476 429.382h-83.76a6.053 6.053 0 010-12.107h83.76a6.053 6.053 0 110 12.107zM377.476 457.769h-83.76a6.053 6.053 0 110-12.106h83.76a6.053 6.053 0 110 12.106zM424.451 401.28h-177.71a6.053 6.053 0 110-12.105h177.71a6.053 6.053 0 110 12.106z"
      />
      <circle
        cx="851.339"
        cy="258.612"
        r="29.729"
        fill="#ffb8b8"
        transform="rotate(-28.663 458.38 526.362)"
      />
      <path
        fill={theme.palette.secondary.main}
        d="M671.783 140.819l11.272-14.489 16.078-.697 1.605 13.627 7.967 3.673a213.669 213.669 0 013.6 92.57 8.242 8.242 0 00-2.345 8.007l.516 2.059-.048.075a12.062 12.062 0 00-1.6 9.005l14.564 25.88c-30.93 12.606-53.604-10.902-74.609-41.71l-13-45z"
      />
      <path
        fill="#2f2e41"
        d="M669.783 66.819h19.204c12.59 0 22.796 11.482 22.796 25.645 9.5 9.54 6.649 21.49-.889 34.355a149.226 149.226 0 00-39.54 10.575l-1.793-7.654-3.536 10.08q-4.717 2.286-9.353 4.999c3.608-16.951 5.092-33.863 2.615-47h-6.504V90.7l-2.208 7.118h-11.792a31 31 0 0131-31z"
      />
      <path
        fill="#2f2e41"
        d="M637.897 224.129L634.861 193.769 675.162 134.111 676.819 135.23 636.923 194.29 639.887 223.93 637.897 224.129z"
      />
      <circle
        cx="638.892"
        cy="224.029"
        r="3"
        fill="#2f2e41"
      />
      <circle
        cx="695.392"
        cy="64.529"
        r="14"
        fill="#2f2e41"
      />
      <path
        fill="#ffb8b8"
        d="M665.062 296.923a10.024 10.024 0 011.79.17l9.825-62.195-9.843-59.874a15.08 15.08 0 0129.274-6.944 118.97 118.97 0 015.412 36.485l-.343 40.833-26.191 62.71a9.997 9.997 0 11-9.924-11.185z"
      />
      <path
        fill="#2f2e41"
        d="M701.524 230.489l-26.77 1.33-15.415-54.378a23.716 23.716 0 1147.432 0z"
      />
    </svg>
  );
};

export default DashboardIconAdmin;
