import React from 'react';
import { Builders } from 'q3-ui-forms';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { connect } from 'q3-admin/lib/containers';
import SubDetail from 'q3-admin/lib/containers/subDetail';
import { useTranslation } from 'q3-ui-locale';
import { retrieve } from '../CoursesCalls/helpers';

const PriceIncentives = () => {
  const { t } = useTranslation('labels');

  return (
    <SubDetail
      root="priceIncentives"
      th="quantityValue"
      name="priceIncentives"
      cardProps={{
        title: 'value',
        attributes: ['discount'],
      }}
      initialValues={{}}
      sortOptions={[
        {
          label: t('valueAsc'),
          fn: retrieve('value'),
          direction: 'asc',
        },
        {
          label: t('valueDesc'),
          fn: retrieve('value'),
          direction: 'desc',
        },
        {
          label: t('discountAsc'),
          fn: retrieve('discount'),
          direction: 'asc',
        },
        {
          label: t('discountDesc'),
          fn: retrieve('discount'),
          direction: 'desc',
        },
      ]}
    >
      <Builders.Form>
        <Grid item xs={12}>
          <Alert severity="info">
            {t('descriptions:priceIncentives')}
          </Alert>
        </Grid>
        <Builders.Field
          name="value"
          type="number"
          label={t('quantityValue')}
          required
          xl={12}
          lg={12}
        />
        <Builders.Field
          name="discount"
          type="number"
          xl={12}
          lg={12}
        />
      </Builders.Form>
    </SubDetail>
  );
};

PriceIncentives.propTypes = {};

export default connect(PriceIncentives);
