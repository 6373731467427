import React from 'react';
import PropTypes from 'prop-types';
import { Builders } from 'q3-ui-forms';
import AddNewForm from 'q3-admin/lib/components/AddNewForm';
import useUserRoleOptions from '../useUserRoleOptions';

const UsersAdd = ({ onSubmit, ...props }) => {
  const getOriginalRoleOptions = useUserRoleOptions();

  return (
    <AddNewForm
      {...props}
      marshal={(values) => ({
        ...values,
        lang: 'en',
        timezone: 'America/Toronto',
        countryOfResidence: 'CA',
        enableServerToServer: false,
      })}
    >
      <Builders.Field
        name="firstName"
        type="text"
        required
        xl={12}
        lg={12}
      />
      <Builders.Field
        name="lastName"
        type="text"
        required
        xl={12}
        lg={12}
      />
      <Builders.Field
        name="email"
        type="email"
        required
        xl={12}
        lg={12}
      />
      <Builders.Field
        name="role"
        type="select"
        options={getOriginalRoleOptions()}
        required
        xl={12}
        lg={12}
      />
    </AddNewForm>
  );
};
UsersAdd.propTypes = {
  onSubmit: PropTypes.string.isRequired,
};

export default UsersAdd;
