import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'q3-admin';
import { get } from 'lodash';
import { Builders } from 'q3-ui-forms';
import {
  castToBeginning,
  castToEnd,
  castToLocalDate,
} from 'q3-ui-forms/lib/helpers';
import { AuthContext } from 'q3-ui-permissions';

const CoursesGeneral = ({ data, ...props }) => {
  const auth = React.useContext(AuthContext);
  const disabled =
    get(auth, 'state.profile.role') === 'Coach' &&
    !data.enableChanges;

  return (
    <Builders.Form
      disabled={disabled}
      {...props}
      initialValues={{ ...data }}
      keep={[
        'callInformation',
        'description',
        'end',
        'start',
        'title',
        'type',
        'category',
        'type',
      ]}
      modify={{
        start: [castToLocalDate],
        end: [castToLocalDate],
      }}
      marshalSelectively
      marshal={{
        start: [castToBeginning],
        end: [castToEnd],
      }}
    >
      <Builders.Field
        name="title"
        type="text"
        required
        xl={12}
        lg={12}
      />
      <Builders.Field
        required
        conditional={['type=Scheduled,Open']}
        name="start"
        type="date"
        xl={6}
        lg={6}
      />
      <Builders.Field
        conditional={['type=Scheduled,Open']}
        required={data?.type === 'Open'}
        name="end"
        type="date"
        xl={6}
        lg={6}
      />
      <Builders.Field
        name="description"
        type="text"
        multiline
        rows={5}
        xl={6}
        lg={6}
      />
      <Builders.Field
        name="callInformation"
        type="text"
        multiline
        rows={5}
        xl={6}
        lg={6}
      />
    </Builders.Form>
  );
};

CoursesGeneral.defaultProps = {
  data: {},
};

CoursesGeneral.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    type: PropTypes.string,
    suspended: PropTypes.bool,
    published: PropTypes.bool,
    enableChanges: PropTypes.bool,
  }),
};

export default connect(CoursesGeneral);
