import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {
  alpha,
  makeStyles,
  useTheme,
} from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    paddingBottom: theme.spacing(4),
    width: 'auto',
  },
  gutter: {
    padding: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  hero: {
    boxShadow: theme.shadows[0],
    height: 180,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: alpha(
      theme.palette.primary.light,
      0.7,
    ),
    backgroundBlendMode: 'overlay',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderBottomRightRadius: 24,

    '& > div': {
      padding: `0 ${theme.spacing(2)}`,
    },
  },

  wrapper: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

const ReadOnlyTemplate = ({
  children,
  photo,
  noGutters,
}) => {
  const cls = useStyle();
  const theme = useTheme();

  return (
    <Box className={cls.wrapper}>
      {photo && (
        <Box
          className={cls.hero}
          style={{
            backgroundImage: `url(${photo})`,
          }}
        >
          <style>
            {
              '#detail-article section header { color: #fff; z-index: 1; }'
            }
          </style>
        </Box>
      )}
      <style>
        {`#detail-article {background-color: ${theme?.palette?.background?.default} }`}
      </style>
      <Container maxWidth="lg" className={cls.root}>
        {noGutters ? (
          children
        ) : (
          <Box
            className={cls.gutter}
            bgcolor="background.paper"
            position="relative"
          >
            {children}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default ReadOnlyTemplate;
