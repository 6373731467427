import React from 'react';
import { AuthContext } from 'q3-ui-permissions';
import AbstractCollectionBuilder from 'q3-admin/lib/builders';
import { compact, get, some, isNil } from 'lodash';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EjectIcon from '@material-ui/icons/Eject';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
// eslint-disable-next-line
import Thread from 'q3-ui-thread';
import DescriptionIcon from '@material-ui/icons/Description';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import FormatSizeIcon from '@material-ui/icons/FormatSize';
import { State } from 'q3-ui-exports';
import { object } from 'q3-ui-helpers';
import { useNotificationsAnalyticsActions } from 'q3-admin/lib/hooks';
import JournalsCollection from '../JournalsCollection';
import JournalsAdd from '../JournalsAdd';
import JournalsGeneral from '../JournalsGeneral';
import Filter from '../JournalsFilter';
import JournalsAttributes from '../JournalsAttributes';
import useJournalFontScale from '../useJournalFontScale';
import EditableTitle from '../EditableTitle';
import useCoachUnreadRead from '../useCoachUnreadRead';

export const registerActions = (data, { patch }) => {
  const { createdBy, publishedOn, trashedOn } = data;
  const output = [];
  const auth = React.useContext(AuthContext);
  const profile = auth?.state?.profile;
  const authorId = createdBy?.id;
  const { label, onClick } = useJournalFontScale();
  const analyticsActions = useNotificationsAnalyticsActions(
    data,
    {
      initialValue: true,
    },
  );

  const canSeeAnalyticsActions = useCoachUnreadRead();

  if (authorId === profile?.id) {
    output.push({
      label: 'attributes',
      icon: EmojiEmotionsIcon,
      autoOpen: true,
      renderContent: () => <JournalsAttributes />,
    });

    if (publishedOn)
      output.push({
        label: 'unpublish',
        icon: EjectIcon,
        onClick: () =>
          patch()({
            publishedOn: null,
            publishedState: '',
          }),
      });

    if (trashedOn)
      output.push({
        label: 'untrash',
        icon: SettingsBackupRestoreIcon,
        onClick: () =>
          patch()({
            trashedOn: null,
          }),
      });
  } else if (profile?.role !== 'Member')
    output.push({
      icon: DescriptionIcon,
      label: 'thread',
      renderContent: () => (
        // eslint-disable-next-line
        <Thread collectionName="users" id={authorId} />
      ),
    });

  if (canSeeAnalyticsActions)
    output.push(...analyticsActions);

  output.push({
    label,
    icon: FormatSizeIcon,
    onClick,
  });

  return output;
};

const resolvers = (xs) => ({
  ...xs,
  name: xs.title,
  publishedState: xs.hasBeenTrashed
    ? 'Trashed'
    : xs.publishedState || 'Draft',
  description: undefined,
  author: compact([
    get(xs, 'createdBy.firstName'),
    get(xs, 'createdBy.lastName'),
  ]).join(' '),
  publishedOn: {
    base: xs.publishedOn,
    toDate: true,
  },
  trashedOn: {
    base: xs.hasBeenTrashed ? xs.trashedOn : undefined,
    toDate: true,
  },
  updatedAt: {
    base: xs.updatedAt,
    toDate: true,
  },
});

export const registerAlerts = (data, user, t) => {
  const out = [];

  if (data.trashedOn)
    out.push({
      id: 'trashed',
      severity: 'warning',
      title: t('titles:hasBeenTrashed'),
      description: t('descriptions:hasBeenTrashed'),
    });

  return out;
};

export const renderContent = (_, { Appbar, Views }) => (
  <>
    {Appbar}
    {Views}
  </>
);

export default new AbstractCollectionBuilder({
  collectionName: 'journals',
  resourceName: 'journals',
  resourceNameSingular: 'journal',
  resolvers,
  icon: AssignmentIcon,
})
  .genFilter(Filter)
  .genNew(JournalsAdd)
  .genHeader({
    editable: ({ isMine, publishedOn }) =>
      isMine && !publishedOn,
    titleProp: 'title',
    editableComponent: (_, __, context) => (
      <EditableTitle className={context.className} />
    ),
  })
  .genViews({
    General: JournalsGeneral,
  })
  .genList({
    defaultColumns: [
      'author',
      'course.title',
      'prompt',
      'publishedState',
      'publishedOn',
      'trashedOn',
      'updatedAt',
    ],
    disableAvatar: true,
    blacklistColumns: (a) =>
      !some(a, (item) => item.trashedOn)
        ? ['trashedOn']
        : [],
    io: {
      exports: ['onExportJournals'],
    },
    uis: [
      {
        ui: {
          label: 'cards',
          icon: ViewAgendaIcon,
          component: JournalsCollection,
        },
        defaultLimitPreference: 24,
      },
      {
        ui: 'table',
        defaultLimitPreference: 25,
      },
    ],
    aliasForName: 'title',
    registerActions: (data, { patchBulk }) => {
      const { checked } = React.useContext(State);
      const out = [];

      if (some(data, (item) => !isNil(item.trashedOn)))
        out.push({
          icon: SettingsBackupRestoreIcon,
          label: 'untrash',
          requireCheckedState: true,
          onClick: () =>
            object.noop(
              patchBulk(checked)({
                trashedOn: null,
              }),
            ),
        });

      return out;
    },
  })
  .genDetail({
    registerActions,
    registerAlerts,
    renderContent,
  })
  .genListSettings({
    defaultSortPreference: '-publishedOn',
  })
  .build();
