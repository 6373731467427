import React from 'react';
import { Box, Link } from '@material-ui/core';
import { connect } from 'q3-admin/lib/containers';
import Alert from '@material-ui/lab/Alert';
import { browser } from 'q3-ui-helpers';
import { get } from 'lodash';
import { useTranslation } from 'q3-ui-locale';

const getUrl = ({ product }) =>
  browser.isBrowserReady()
    ? `${window.location.origin}/paid-registration?product=${product}`
    : '';

const ProductsCharges = (props) => {
  const { t } = useTranslation('labels');

  return (
    <Box mb={1}>
      <Alert
        severity="info"
        action={
          <Link
            color="inherit"
            href={getUrl({
              product: get(props, 'data.id'),
            })}
            target="_blank"
          >
            {t('registrationLink')}
          </Link>
        }
      >
        {t('descriptions:taxAutomated')}
      </Alert>
    </Box>
  );
};

ProductsCharges.propTypes = {};

export default connect(ProductsCharges);
