import { makeStyles } from '@material-ui/core';

const makeNestedZoomRule = (tags = []) =>
  tags
    .map((tag) => `&[data-zoomed="true"] ${tag}`)
    .join(', ');

export default makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),

    '& .ql-editor': {
      maxHeight: '45vh',
      minHeight: '25vh',
      overflow: 'auto',
      paddingBottom: '0 !important',
      marginBottom: '1.5rem !important',

      [theme.breakpoints.down('sm')]: {
        maxHeight: '33vh',
        minHeight: '20vh',
      },
    },
  },
  grid: {
    flexDirection: 'row-reverse',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  cleanup: {
    '& img,& video,& audio, & frame': {
      display: 'block',
      maxWidth: 550,

      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
    '& iframe': {
      margin: '1rem 0',
    },
    '& :empty:not(img):not(video):not(audio):not(iframe)': {
      display: 'none',
    },
  },
  wrapper: {
    margin: '-1.125rem',

    [makeNestedZoomRule(['small', 'button'])]: {
      fontSize: '1rem',
      lineHeight: 1.1,
    },

    [makeNestedZoomRule([
      'p',
      'li',
      'input',
      'blockquote',
    ])]: {
      fontSize: '130%',
      lineHeight: 1.2,
    },

    [makeNestedZoomRule(['h2'])]: {
      fontSize: '180%',
      lineHeight: 1.4,
    },

    [makeNestedZoomRule(['h3'])]: {
      fontSize: '160%',
      lineHeight: 1.3,
    },
  },
  comments: {
    '& > ul li > div:last-of-type': {
      maxWidth: '100%',

      [theme.breakpoints.down('md')]: {
        maxWidth: `calc(100% - ${theme.spacing(3)})`,
      },
    },

    [theme.breakpoints.down('md')]: {
      margin: `0 -${theme.spacing(1.5)}`,
    },
  },
}));
