import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Widget from 'q3-admin/lib/components/Widget';

const ReadOnlyTemplateAccordion = ({ children, title }) => (
  <Box position="relative" mb={1}>
    <Widget title={title}> {children}</Widget>
  </Box>
);

ReadOnlyTemplateAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default ReadOnlyTemplateAccordion;
